@import "~hint.css/src/hint";
@import "~aos/dist/aos.css";
@import "~bootstrap/scss/bootstrap";
@import '~@fortawesome/fontawesome-pro/scss/fontawesome';
@import '~@fortawesome/fontawesome-pro/scss/brands';
@import '~@fortawesome/fontawesome-pro/scss/regular';
@import '~@fortawesome/fontawesome-pro/scss/solid';
@import "helper";
@import "~magnific-popup/dist/magnific-popup.css";
@import "~swiper/swiper-bundle.css";
@import "~pe7-icon/dist/dist/pe-icon-7-stroke.css";
@import "~tippy.js/dist/tippy.css";
@import "~tippy.js/themes/light.css";
@import "styles";

[v-cloak] {
  display: none;
}
