@import "@fortawesome/fontawesome-pro/scss/variables";

html {
  font-size: 1rem
}

body {
  font-family: Poppins, sans-serif;
  font-weight: 300;
  color: #41494f;
  background-color: #fff;
  overflow-x: hidden;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important
}

a {
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in
}

a, a:active, a:focus, a:hover {
  outline: 0 !important;
  text-decoration: none
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #edf4f8;
  margin: 1em 0;
  padding: 0
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0
}

textarea {
  resize: vertical
}

.browserupgrade {
  margin: .2em 0;
  background: #ccc;
  color: #000;
  padding: .2em 0
}

*, ::after, ::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.prettyprint .linenums {
  padding-left: 0;
  list-style-type: none !important;
  color: #ccc
}

.prettyprint .linenums > li {
  counter-increment: step-counter
}

.prettyprint .linenums > li:before {
  content: counter(step-counter);
  color: #d5b5ff;
  background-color: #9f55ff;
  min-width: 3rem;
  display: inline-block;
  padding: 0 10px;
  text-align: right;
  margin-right: 10px
}

.prettyprint .linenums > li:first-child:before {
  border-radius: 5px 0 0
}

.prettyprint .linenums > li:last-child:before {
  border-radius: 0 0 0 5px
}

.prettyprint .linenums > li.L4, .prettyprint .linenums > li.L9 {
  color: #000
}

pre.prettyprint {
  border: none;
  -webkit-box-shadow: 0 0 1px 1px #e1ecf4, 0 0 5px 2px rgba(225, 236, 244, .5);
  box-shadow: 0 0 1px 1px #e1ecf4, 0 0 5px 2px rgba(225, 236, 244, .5);
  background-color: #fff;
  margin: 0 5px;
  border-radius: 5px
}

li.L1, li.L3, li.L5, li.L7, li.L9 {
  background: #f5f5f5
}

.icon {
  margin-left: 5px;
  margin-right: 15px;
  padding: 0;
  display: inline-block;
  text-align: center;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out
}

.icon.logo {
  max-width: 80px
}

.icon i {
  margin: 0
}

img.icon {
  max-width: 54px
}

.icon-sm {
  width: .85rem;
  height: .85rem
}

.icon-md {
  width: 2rem;
  height: 2rem
}

.icon-lg {
  width: 3.25rem;
  height: 3.25rem
}

.icon-xl {
  width: 4.5rem;
  height: 4.5rem
}

.icon-xxl {
  width: 6.5rem;
  height: 6.5rem
}

.icon-l {
  width: 1.25rem;
  height: 1.25rem
}

.icon-2l {
  width: 2.5rem;
  height: 2.5rem
}

.icon-2xl {
  width: 9rem;
  height: 9rem
}

.icon-2xxl {
  width: 13rem;
  height: 13rem
}

.icon-1 {
  color: #41494f;
  background-color: #fff;
  border-color: #f2f2f2
}

.icon-1:hover {
  color: #41494f;
  background-color: #e6e6e6;
  border-color: #d4d4d4
}

.icon-2 {
  color: #fff;
  background-color: #646f79;
  border-color: #58626b
}

.icon-2:hover {
  color: #fff;
  background-color: #4d555d;
  border-color: #3d434a
}

.icon-3, .icon-accent {
  color: #fff;
  background-color: #9f55ff;
  border-color: #913cff
}

.icon-3:hover, .icon-accent:hover {
  color: #fff;
  background-color: #8222ff;
  border-color: #6e00fd
}

.icon-4 {
  color: #fff;
  background-color: #028fff;
  border-color: #0081e8
}

.icon-4:hover {
  color: #fff;
  background-color: #0073ce;
  border-color: #005faa
}

.icon-5 {
  color: #fff;
  background-color: #101480;
  border-color: #0d1069
}

.icon-5:hover {
  color: #fff;
  background-color: #0a0d53;
  border-color: #060833
}

.anim {
  position: absolute
}

.floating {
  -webkit-animation: floating 4s ease-in-out infinite;
  animation: floating 4s ease-in-out infinite;
  -webkit-filter: none;
  filter: none
}

@-webkit-keyframes floating {
  from {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  65% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px)
  }
  to {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

@keyframes floating {
  from {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
  65% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px)
  }
  to {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

.mfp-zoom-in .mfp-with-anim {
  opacity: 0;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  -webkit-transform: scale(.8);
  transform: scale(.8)
}

.mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out
}

.mfp-zoom-in.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1)
}

.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: .8
}

.mfp-zoom-in.mfp-removing .mfp-with-anim {
  -webkit-transform: scale(.8);
  transform: scale(.8);
  opacity: 0
}

.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0
}

.mfp-newspaper .mfp-with-anim {
  opacity: 0;
  -webkit-transition: all .2s ease-in-out;
  -webkit-transition: all .5s;
  transition: all .5s;
  -webkit-transform: scale(0) rotate(500deg);
  transform: scale(0) rotate(500deg)
}

.mfp-newspaper.mfp-bg {
  opacity: 0;
  -webkit-transition: all .5s;
  transition: all .5s
}

.mfp-newspaper.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: scale(1) rotate(0);
  transform: scale(1) rotate(0)
}

.mfp-newspaper.mfp-ready.mfp-bg {
  opacity: .8
}

.mfp-newspaper.mfp-removing .mfp-with-anim {
  -webkit-transform: scale(0) rotate(500deg);
  transform: scale(0) rotate(500deg);
  opacity: 0
}

.mfp-newspaper.mfp-removing.mfp-bg {
  opacity: 0
}

.mfp-move-horizontal .mfp-with-anim {
  opacity: 0;
  -webkit-transition: all .3s;
  transition: all .3s;
  -webkit-transform: translateX(-50px);
  transform: translateX(-50px)
}

.mfp-move-horizontal.mfp-bg {
  opacity: 0;
  -webkit-transition: all .3s;
  transition: all .3s
}

.mfp-move-horizontal.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0)
}

.mfp-move-horizontal.mfp-ready.mfp-bg {
  opacity: .8
}

.mfp-move-horizontal.mfp-removing .mfp-with-anim {
  -webkit-transform: translateX(50px);
  transform: translateX(50px);
  opacity: 0
}

.mfp-move-horizontal.mfp-removing.mfp-bg {
  opacity: 0
}

.mfp-move-from-top .mfp-content {
  vertical-align: top
}

.mfp-move-from-top .mfp-with-anim {
  opacity: 0;
  -webkit-transition: all .2s;
  transition: all .2s;
  -webkit-transform: translateY(-100px);
  transform: translateY(-100px)
}

.mfp-move-from-top.mfp-bg {
  opacity: 0;
  -webkit-transition: all .2s;
  transition: all .2s
}

.mfp-move-from-top.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0)
}

.mfp-move-from-top.mfp-ready.mfp-bg {
  opacity: .8
}

.mfp-move-from-top.mfp-removing .mfp-with-anim {
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
  opacity: 0
}

.mfp-move-from-top.mfp-removing.mfp-bg {
  opacity: 0
}

.mfp-3d-unfold .mfp-content {
  -webkit-perspective: 2000px;
  perspective: 2000px
}

.mfp-3d-unfold .mfp-with-anim {
  opacity: 0;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateY(-60deg);
  transform: rotateY(-60deg)
}

.mfp-3d-unfold.mfp-bg {
  opacity: 0;
  -webkit-transition: all .5s;
  transition: all .5s
}

.mfp-3d-unfold.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: rotateY(0);
  transform: rotateY(0)
}

.mfp-3d-unfold.mfp-ready.mfp-bg {
  opacity: .8
}

.mfp-3d-unfold.mfp-removing .mfp-with-anim {
  -webkit-transform: rotateY(60deg);
  transform: rotateY(60deg);
  opacity: 0
}

.mfp-3d-unfold.mfp-removing.mfp-bg {
  opacity: 0
}

.mfp-zoom-out .mfp-with-anim {
  opacity: 0;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  -webkit-transform: scale(1.3);
  transform: scale(1.3)
}

.mfp-zoom-out.mfp-bg {
  opacity: 0;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out
}

.mfp-zoom-out.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1)
}

.mfp-zoom-out.mfp-ready.mfp-bg {
  opacity: .8
}

.mfp-zoom-out.mfp-removing .mfp-with-anim {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  opacity: 0
}

.mfp-zoom-out.mfp-removing.mfp-bg {
  opacity: 0
}

.mfp-with-zoom .mfp-container, .mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1
}

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: .8
}

.mfp-with-zoom.mfp-removing .mfp-container, .mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0
}

.mfp-no-margins img.mfp-img {
  padding: 0
}

.mfp-no-margins .mfp-figure:after {
  top: 0;
  bottom: 0
}

.mfp-no-margins .mfp-container {
  padding: 0
}

.form[data-response-message-animation], .form[data-response-message-animation] + .response-message, form[data-response-message-animation], form[data-response-message-animation] + .response-message {
  -webkit-transition: opacity .3s ease-out, -webkit-transform .3s ease-in;
  transition: opacity .3s ease-out, -webkit-transform .3s ease-in;
  transition: transform .3s ease-in, opacity .3s ease-out;
  transition: transform .3s ease-in, opacity .3s ease-out, -webkit-transform .3s ease-in
}

.form[data-response-message-animation].submitted + .response-message, form[data-response-message-animation].submitted + .response-message {
  z-index: 1;
  opacity: 1;
  position: relative
}

.form[data-response-message-animation] + .response-message, .form[data-response-message-animation].submitted, form[data-response-message-animation] + .response-message, form[data-response-message-animation].submitted {
  opacity: 0;
  position: absolute;
  top: 0
}

.form[data-response-message-animation] + .response-message, form[data-response-message-animation] + .response-message {
  z-index: -1
}

.form[data-response-message-animation=slide-in-up].submitted + .response-message, form[data-response-message-animation=slide-in-up].submitted + .response-message {
  -webkit-transform: translateY(0);
  transform: translateY(0)
}

.form[data-response-message-animation=slide-in-up] + .response-message, .form[data-response-message-animation=slide-in-up].submitted, form[data-response-message-animation=slide-in-up] + .response-message, form[data-response-message-animation=slide-in-up].submitted {
  -webkit-transform: translateY(100%);
  transform: translateY(100%)
}

.form[data-response-message-animation=slide-in-left].submitted + .response-message, form[data-response-message-animation=slide-in-left].submitted + .response-message {
  -webkit-transform: translateX(0);
  transform: translateX(0)
}

.form[data-response-message-animation=slide-in-left] + .response-message, .form[data-response-message-animation=slide-in-left].submitted, form[data-response-message-animation=slide-in-left] + .response-message, form[data-response-message-animation=slide-in-left].submitted {
  -webkit-transform: translateX(100%);
  transform: translateX(100%)
}

.form.cozy .form-group, form.cozy .form-group {
  margin-bottom: 30px
}

.form .form-group label.error, form .form-group label.error {
  color: #dc3545;
  font-size: .85rem
}

.form .form-group.has-icon, form .form-group.has-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative
}

.form .form-group.has-icon .form-control, form .form-group.has-icon .form-control {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  padding-right: 2.5rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%
}

.form .form-group.has-icon .form-control.is-valid + .icon, form .form-group.has-icon .form-control.is-valid + .icon {
  color: #28a745
}

.form .form-group.has-icon .form-control.is-invalid + .icon, form .form-group.has-icon .form-control.is-invalid + .icon {
  color: #dc3545
}

.form .form-group.has-icon .icon, form .form-group.has-icon .icon {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  margin-right: 15px;
  margin-left: 12px;
  position: absolute;
  right: 0
}

.form .form-group.has-icon.icon-left .form-control, form .form-group.has-icon.icon-left .form-control {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  padding-left: 2.5rem;
  padding-right: 0
}

.form .form-group.has-icon.icon-left .icon, form .form-group.has-icon.icon-left .icon {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  left: 5px;
  right: auto
}

.form .error, form .error {
  font-size: .85rem;
  color: #dc3545
}

button:focus {
  outline: 0
}

.btn {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  text-decoration: none;
  letter-spacing: 1.45px;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  padding: .5rem 1.25rem;
  font-size: .75rem;
  line-height: 1.5;
  border-radius: .25rem;
  white-space: normal;
  text-align: left
}

.btn .icon {
  margin-right: 8px
}

.btn-link {
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer
}

.btn-xs {
  padding: .125rem .25rem;
  font-size: .25rem;
  line-height: 1.5;
  border-radius: .25rem
}

.btn-sm {
  padding: .25rem .5rem;
  font-size: .5rem;
  line-height: 1.5;
  border-radius: .25rem
}

.btn-md {
  padding: .375rem .75rem;
  font-size: .75rem;
  line-height: 1.5;
  border-radius: .25rem
}

.btn-lg {
  padding: .5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem
}

.btn-xl {
  padding: .625rem 1.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .25rem
}

.btn-rounded {
  border-radius: 10rem
}

.btn-circle {
  width: 3rem;
  height: 3rem;
  padding: 0;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 1rem
}

.btn-circle.btn-xs {
  width: 1rem;
  height: 1rem;
  font-size: .25rem
}

.btn-circle.btn-sm {
  width: 2rem;
  height: 2rem;
  font-size: .625rem
}

.btn-circle.btn-md {
  width: 3rem;
  height: 3rem;
  font-size: 1rem
}

.btn-circle.btn-lg {
  width: 4rem;
  height: 4rem;
  font-size: 1.375rem
}

.btn-circle.btn-xl {
  width: 5rem;
  height: 5rem;
  font-size: 1.75rem
}

.btn-download {
  min-width: 200px;
  text-align: left;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.btn-download p {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.45
}

.btn-download .small {
  margin-top: -2px;
  display: block
}

.input-group .btn:not(:first-child) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.btn-primary:hover {
  -webkit-box-shadow: 0 1px 10px rgba(0, 123, 255, .4) !important;
  box-shadow: 0 1px 10px rgba(0, 123, 255, .4) !important
}

.btn-secondary:hover {
  -webkit-box-shadow: 0 1px 10px rgba(108, 117, 125, .4) !important;
  box-shadow: 0 1px 10px rgba(108, 117, 125, .4) !important
}

.btn-success:hover {
  -webkit-box-shadow: 0 1px 10px rgba(40, 167, 69, .4) !important;
  box-shadow: 0 1px 10px rgba(40, 167, 69, .4) !important
}

.btn-info:hover {
  -webkit-box-shadow: 0 1px 10px rgba(23, 162, 184, .4) !important;
  box-shadow: 0 1px 10px rgba(23, 162, 184, .4) !important
}

.btn-warning:hover {
  -webkit-box-shadow: 0 1px 10px rgba(255, 193, 7, .4) !important;
  box-shadow: 0 1px 10px rgba(255, 193, 7, .4) !important
}

.btn-danger:hover {
  -webkit-box-shadow: 0 1px 10px rgba(220, 53, 69, .4) !important;
  box-shadow: 0 1px 10px rgba(220, 53, 69, .4) !important
}

.btn-light:hover {
  -webkit-box-shadow: 0 1px 10px rgba(248, 249, 250, .4) !important;
  box-shadow: 0 1px 10px rgba(248, 249, 250, .4) !important
}

.btn-dark:hover {
  -webkit-box-shadow: 0 1px 10px rgba(52, 58, 64, .4) !important;
  box-shadow: 0 1px 10px rgba(52, 58, 64, .4) !important
}

.btn-1 {
  color: #212529;
  background-color: #fff;
  border-color: #fff
}

.btn-1:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6
}

.btn-1.focus, .btn-1:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(222, 222, 223, .5);
  box-shadow: 0 0 0 .2rem rgba(222, 222, 223, .5)
}

.btn-1.disabled, .btn-1:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #fff
}

.btn-1:not(:disabled):not(.disabled).active, .btn-1:not(:disabled):not(.disabled):active, .show > .btn-1.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf
}

.btn-1:not(:disabled):not(.disabled).active:focus, .btn-1:not(:disabled):not(.disabled):active:focus, .show > .btn-1.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(222, 222, 223, .5);
  box-shadow: 0 0 0 .2rem rgba(222, 222, 223, .5)
}

.btn-1:hover {
  -webkit-box-shadow: 0 1px 10px rgba(255, 255, 255, .4) !important;
  box-shadow: 0 1px 10px rgba(255, 255, 255, .4) !important
}

.btn-outline-1 {
  color: #fff;
  border-color: #fff
}

.btn-outline-1:hover {
  color: #212529;
  background-color: #fff;
  border-color: #fff
}

.btn-outline-1.focus, .btn-outline-1:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
  box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5)
}

.btn-outline-1.disabled, .btn-outline-1:disabled {
  color: #fff;
  background-color: transparent
}

.btn-outline-1:not(:disabled):not(.disabled).active, .btn-outline-1:not(:disabled):not(.disabled):active, .show > .btn-outline-1.dropdown-toggle {
  color: #212529;
  background-color: #fff;
  border-color: #fff
}

.btn-outline-1:not(:disabled):not(.disabled).active:focus, .btn-outline-1:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-1.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
  box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5)
}

.btn-outline-1:hover {
  -webkit-box-shadow: 0 1px 10px rgba(255, 255, 255, .4) !important;
  box-shadow: 0 1px 10px rgba(255, 255, 255, .4) !important
}

.btn-2 {
  color: #fff;
  background-color: #646f79;
  border-color: #646f79
}

.btn-2:hover {
  color: #fff;
  background-color: #535c64;
  border-color: #4d555d
}

.btn-2.focus, .btn-2:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(123, 133, 141, .5);
  box-shadow: 0 0 0 .2rem rgba(123, 133, 141, .5)
}

.btn-2.disabled, .btn-2:disabled {
  color: #fff;
  background-color: #646f79;
  border-color: #646f79
}

.btn-2:not(:disabled):not(.disabled).active, .btn-2:not(:disabled):not(.disabled):active, .show > .btn-2.dropdown-toggle {
  color: #fff;
  background-color: #4d555d;
  border-color: #474f56
}

.btn-2:not(:disabled):not(.disabled).active:focus, .btn-2:not(:disabled):not(.disabled):active:focus, .show > .btn-2.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(123, 133, 141, .5);
  box-shadow: 0 0 0 .2rem rgba(123, 133, 141, .5)
}

.btn-2:hover {
  -webkit-box-shadow: 0 1px 10px rgba(100, 111, 121, .4) !important;
  box-shadow: 0 1px 10px rgba(100, 111, 121, .4) !important
}

.btn-outline-2 {
  color: #646f79;
  border-color: #646f79
}

.btn-outline-2:hover {
  color: #fff;
  background-color: #646f79;
  border-color: #646f79
}

.btn-outline-2.focus, .btn-outline-2:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(100, 111, 121, .5);
  box-shadow: 0 0 0 .2rem rgba(100, 111, 121, .5)
}

.btn-outline-2.disabled, .btn-outline-2:disabled {
  color: #646f79;
  background-color: transparent
}

.btn-outline-2:not(:disabled):not(.disabled).active, .btn-outline-2:not(:disabled):not(.disabled):active, .show > .btn-outline-2.dropdown-toggle {
  color: #fff;
  background-color: #646f79;
  border-color: #646f79
}

.btn-outline-2:not(:disabled):not(.disabled).active:focus, .btn-outline-2:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-2.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(100, 111, 121, .5);
  box-shadow: 0 0 0 .2rem rgba(100, 111, 121, .5)
}

.btn-outline-2:hover {
  -webkit-box-shadow: 0 1px 10px rgba(100, 111, 121, .4) !important;
  box-shadow: 0 1px 10px rgba(100, 111, 121, .4) !important
}

.btn-3 {
  color: #fff;
  background-color: #9f55ff;
  border-color: #9f55ff
}

.btn-3:hover {
  color: #fff;
  background-color: #892fff;
  border-color: #8222ff
}

.btn-3.focus, .btn-3:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(173, 111, 255, .5);
  box-shadow: 0 0 0 .2rem rgba(173, 111, 255, .5)
}

.btn-3.disabled, .btn-3:disabled {
  color: #fff;
  background-color: #9f55ff;
  border-color: #9f55ff
}

.btn-3:not(:disabled):not(.disabled).active, .btn-3:not(:disabled):not(.disabled):active, .show > .btn-3.dropdown-toggle {
  color: #fff;
  background-color: #8222ff;
  border-color: #7b15ff
}

.btn-3:not(:disabled):not(.disabled).active:focus, .btn-3:not(:disabled):not(.disabled):active:focus, .show > .btn-3.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(173, 111, 255, .5);
  box-shadow: 0 0 0 .2rem rgba(173, 111, 255, .5)
}

.btn-3:hover {
  -webkit-box-shadow: 0 1px 10px rgba(159, 85, 255, .4) !important;
  box-shadow: 0 1px 10px rgba(159, 85, 255, .4) !important
}

.btn-outline-3 {
  color: #9f55ff;
  border-color: #9f55ff
}

.btn-outline-3:hover {
  color: #fff;
  background-color: #9f55ff;
  border-color: #9f55ff
}

.btn-outline-3.focus, .btn-outline-3:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(159, 85, 255, .5);
  box-shadow: 0 0 0 .2rem rgba(159, 85, 255, .5)
}

.btn-outline-3.disabled, .btn-outline-3:disabled {
  color: #9f55ff;
  background-color: transparent
}

.btn-outline-3:not(:disabled):not(.disabled).active, .btn-outline-3:not(:disabled):not(.disabled):active, .show > .btn-outline-3.dropdown-toggle {
  color: #fff;
  background-color: #9f55ff;
  border-color: #9f55ff
}

.btn-outline-3:not(:disabled):not(.disabled).active:focus, .btn-outline-3:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-3.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(159, 85, 255, .5);
  box-shadow: 0 0 0 .2rem rgba(159, 85, 255, .5)
}

.btn-outline-3:hover {
  -webkit-box-shadow: 0 1px 10px rgba(159, 85, 255, .4) !important;
  box-shadow: 0 1px 10px rgba(159, 85, 255, .4) !important
}

.btn-4 {
  color: #fff;
  background-color: #028fff;
  border-color: #028fff
}

.btn-4:hover {
  color: #fff;
  background-color: #007adb;
  border-color: #0073ce
}

.btn-4.focus, .btn-4:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(40, 160, 255, .5);
  box-shadow: 0 0 0 .2rem rgba(40, 160, 255, .5)
}

.btn-4.disabled, .btn-4:disabled {
  color: #fff;
  background-color: #028fff;
  border-color: #028fff
}

.btn-4:not(:disabled):not(.disabled).active, .btn-4:not(:disabled):not(.disabled):active, .show > .btn-4.dropdown-toggle {
  color: #fff;
  background-color: #0073ce;
  border-color: #006cc1
}

.btn-4:not(:disabled):not(.disabled).active:focus, .btn-4:not(:disabled):not(.disabled):active:focus, .show > .btn-4.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(40, 160, 255, .5);
  box-shadow: 0 0 0 .2rem rgba(40, 160, 255, .5)
}

.btn-4:hover {
  -webkit-box-shadow: 0 1px 10px rgba(2, 143, 255, .4) !important;
  box-shadow: 0 1px 10px rgba(2, 143, 255, .4) !important
}

.btn-outline-4 {
  color: #028fff;
  border-color: #028fff
}

.btn-outline-4:hover {
  color: #fff;
  background-color: #028fff;
  border-color: #028fff
}

.btn-outline-4.focus, .btn-outline-4:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(2, 143, 255, .5);
  box-shadow: 0 0 0 .2rem rgba(2, 143, 255, .5)
}

.btn-outline-4.disabled, .btn-outline-4:disabled {
  color: #028fff;
  background-color: transparent
}

.btn-outline-4:not(:disabled):not(.disabled).active, .btn-outline-4:not(:disabled):not(.disabled):active, .show > .btn-outline-4.dropdown-toggle {
  color: #fff;
  background-color: #028fff;
  border-color: #028fff
}

.btn-outline-4:not(:disabled):not(.disabled).active:focus, .btn-outline-4:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-4.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(2, 143, 255, .5);
  box-shadow: 0 0 0 .2rem rgba(2, 143, 255, .5)
}

.btn-outline-4:hover {
  -webkit-box-shadow: 0 1px 10px rgba(2, 143, 255, .4) !important;
  box-shadow: 0 1px 10px rgba(2, 143, 255, .4) !important
}

.btn-5 {
  color: #fff;
  background-color: #101480;
  border-color: #101480
}

.btn-5:hover {
  color: #fff;
  background-color: #0c0f5e;
  border-color: #0a0d53
}

.btn-5.focus, .btn-5:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(52, 55, 147, .5);
  box-shadow: 0 0 0 .2rem rgba(52, 55, 147, .5)
}

.btn-5.disabled, .btn-5:disabled {
  color: #fff;
  background-color: #101480;
  border-color: #101480
}

.btn-5:not(:disabled):not(.disabled).active, .btn-5:not(:disabled):not(.disabled):active, .show > .btn-5.dropdown-toggle {
  color: #fff;
  background-color: #0a0d53;
  border-color: #090b47
}

.btn-5:not(:disabled):not(.disabled).active:focus, .btn-5:not(:disabled):not(.disabled):active:focus, .show > .btn-5.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(52, 55, 147, .5);
  box-shadow: 0 0 0 .2rem rgba(52, 55, 147, .5)
}

.btn-5:hover {
  -webkit-box-shadow: 0 1px 10px rgba(16, 20, 128, .4) !important;
  box-shadow: 0 1px 10px rgba(16, 20, 128, .4) !important
}

.btn-outline-5 {
  color: #101480;
  border-color: #101480
}

.btn-outline-5:hover {
  color: #fff;
  background-color: #101480;
  border-color: #101480
}

.btn-outline-5.focus, .btn-outline-5:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(16, 20, 128, .5);
  box-shadow: 0 0 0 .2rem rgba(16, 20, 128, .5)
}

.btn-outline-5.disabled, .btn-outline-5:disabled {
  color: #101480;
  background-color: transparent
}

.btn-outline-5:not(:disabled):not(.disabled).active, .btn-outline-5:not(:disabled):not(.disabled):active, .show > .btn-outline-5.dropdown-toggle {
  color: #fff;
  background-color: #101480;
  border-color: #101480
}

.btn-outline-5:not(:disabled):not(.disabled).active:focus, .btn-outline-5:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-5.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(16, 20, 128, .5);
  box-shadow: 0 0 0 .2rem rgba(16, 20, 128, .5)
}

.btn-outline-5:hover {
  -webkit-box-shadow: 0 1px 10px rgba(16, 20, 128, .4) !important;
  box-shadow: 0 1px 10px rgba(16, 20, 128, .4) !important
}

.btn-6 {
  color: #212529;
  background-color: #f4f8fb;
  border-color: #f4f8fb
}

.btn-6:hover {
  color: #212529;
  background-color: #d8e6f1;
  border-color: #cfe0ed
}

.btn-6.focus, .btn-6:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(212, 216, 220, .5);
  box-shadow: 0 0 0 .2rem rgba(212, 216, 220, .5)
}

.btn-6.disabled, .btn-6:disabled {
  color: #212529;
  background-color: #f4f8fb;
  border-color: #f4f8fb
}

.btn-6:not(:disabled):not(.disabled).active, .btn-6:not(:disabled):not(.disabled):active, .show > .btn-6.dropdown-toggle {
  color: #212529;
  background-color: #cfe0ed;
  border-color: #c5daea
}

.btn-6:not(:disabled):not(.disabled).active:focus, .btn-6:not(:disabled):not(.disabled):active:focus, .show > .btn-6.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(212, 216, 220, .5);
  box-shadow: 0 0 0 .2rem rgba(212, 216, 220, .5)
}

.btn-6:hover {
  -webkit-box-shadow: 0 1px 10px rgba(244, 248, 251, .4) !important;
  box-shadow: 0 1px 10px rgba(244, 248, 251, .4) !important
}

.btn-outline-6 {
  color: #f4f8fb;
  border-color: #f4f8fb
}

.btn-outline-6:hover {
  color: #212529;
  background-color: #f4f8fb;
  border-color: #f4f8fb
}

.btn-outline-6.focus, .btn-outline-6:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(244, 248, 251, .5);
  box-shadow: 0 0 0 .2rem rgba(244, 248, 251, .5)
}

.btn-outline-6.disabled, .btn-outline-6:disabled {
  color: #f4f8fb;
  background-color: transparent
}

.btn-outline-6:not(:disabled):not(.disabled).active, .btn-outline-6:not(:disabled):not(.disabled):active, .show > .btn-outline-6.dropdown-toggle {
  color: #212529;
  background-color: #f4f8fb;
  border-color: #f4f8fb
}

.btn-outline-6:not(:disabled):not(.disabled).active:focus, .btn-outline-6:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-6.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(244, 248, 251, .5);
  box-shadow: 0 0 0 .2rem rgba(244, 248, 251, .5)
}

.btn-outline-6:hover {
  -webkit-box-shadow: 0 1px 10px rgba(244, 248, 251, .4) !important;
  box-shadow: 0 1px 10px rgba(244, 248, 251, .4) !important
}

.btn-accent {
  color: #fff;
  background-color: #9f55ff;
  border-color: #9f55ff
}

.btn-accent:hover {
  color: #fff;
  background-color: #892fff;
  border-color: #8222ff
}

.btn-accent.focus, .btn-accent:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(173, 111, 255, .5);
  box-shadow: 0 0 0 .2rem rgba(173, 111, 255, .5)
}

.btn-accent.disabled, .btn-accent:disabled {
  color: #fff;
  background-color: #9f55ff;
  border-color: #9f55ff
}

.btn-accent:not(:disabled):not(.disabled).active, .btn-accent:not(:disabled):not(.disabled):active, .show > .btn-accent.dropdown-toggle {
  color: #fff;
  background-color: #8222ff;
  border-color: #7b15ff
}

.btn-accent:not(:disabled):not(.disabled).active:focus, .btn-accent:not(:disabled):not(.disabled):active:focus, .show > .btn-accent.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(173, 111, 255, .5);
  box-shadow: 0 0 0 .2rem rgba(173, 111, 255, .5)
}

.btn-accent:hover {
  -webkit-box-shadow: 0 1px 10px rgba(159, 85, 255, .4) !important;
  box-shadow: 0 1px 10px rgba(159, 85, 255, .4) !important
}

.btn-outline-accent {
  color: #9f55ff;
  border-color: #9f55ff
}

.btn-outline-accent:hover {
  color: #fff;
  background-color: #9f55ff;
  border-color: #9f55ff
}

.btn-outline-accent.focus, .btn-outline-accent:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(159, 85, 255, .5);
  box-shadow: 0 0 0 .2rem rgba(159, 85, 255, .5)
}

.btn-outline-accent.disabled, .btn-outline-accent:disabled {
  color: #9f55ff;
  background-color: transparent
}

.btn-outline-accent:not(:disabled):not(.disabled).active, .btn-outline-accent:not(:disabled):not(.disabled):active, .show > .btn-outline-accent.dropdown-toggle {
  color: #fff;
  background-color: #9f55ff;
  border-color: #9f55ff
}

.btn-outline-accent:not(:disabled):not(.disabled).active:focus, .btn-outline-accent:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-accent.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(159, 85, 255, .5);
  box-shadow: 0 0 0 .2rem rgba(159, 85, 255, .5)
}

.btn-outline-accent:hover {
  -webkit-box-shadow: 0 1px 10px rgba(159, 85, 255, .4) !important;
  box-shadow: 0 1px 10px rgba(159, 85, 255, .4) !important
}

.btn-outline-1, .btn-outline-1:hover {
  color: #101480
}

.ajax-button {
  position: relative;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.ajax-button .btn-status {
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease;
  position: absolute;
  -webkit-transform: scaleX(0);
  transform: scaleX(0)
}

.ajax-button .loading {
  font-size: 0;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  padding: 0;
  border-bottom-color: transparent;
  border-left-color: transparent;
  background-color: transparent !important;
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0
}

.ajax-button .loading.btn-1 {
  border-top: 1px solid #fff;
  border-right: 1px solid #fff
}

.ajax-button .loading.btn-2 {
  border-top: 1px solid #646f79;
  border-right: 1px solid #646f79
}

.ajax-button .loading.btn-3 {
  border-top: 1px solid #9f55ff;
  border-right: 1px solid #9f55ff
}

.ajax-button .loading.btn-4 {
  border-top: 1px solid #028fff;
  border-right: 1px solid #028fff
}

.ajax-button .loading.btn-5 {
  border-top: 1px solid #101480;
  border-right: 1px solid #101480
}

.ajax-button .loading.btn-6 {
  border-top: 1px solid #f4f8fb;
  border-right: 1px solid #f4f8fb
}

.ajax-button .loading.loading-end {
  opacity: 0;
  -webkit-transform: rotate(0) scale(0) !important;
  transform: rotate(0) scale(0) !important
}

.ajax-button .done {
  -webkit-transform: scaleX(1);
  transform: scaleX(1)
}

.form-control {
  color: #41494f;
  border-color: #e7f0f6
}

.form-control.form-control-rounded {
  border-radius: 10rem
}

.form-control::-webkit-input-placeholder {
  opacity: .5;
  font-size: .875rem
}

.form-control:-ms-input-placeholder {
  opacity: .5;
  font-size: .875rem
}

.form-control::-ms-input-placeholder {
  opacity: .5;
  font-size: .875rem
}

.form-control::placeholder {
  opacity: .5;
  font-size: .875rem
}

select[multiple].form-control.form-control-rounded, textarea.form-control.form-control-rounded {
  padding-left: 1rem;
  border-radius: 1rem
}

.control-label {
  color: #6c757d
}

.control {
  position: relative;
  z-index: 1;
  vertical-align: top
}

.control .form-control {
  position: relative;
  display: block
}

.control .form-control:focus {
  outline: 0
}

.control .control-label {
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  font-weight: 400
}

.control select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

.control .toggler {
  position: absolute;
  top: 50%;
  margin-top: -4px;
  right: 10px;
  line-height: 1;
  z-index: 1
}

.label-content {
  position: relative;
  display: block;
  padding: 1.6em 0;
  width: 100%
}

.checkbox label, .radio label {
  cursor: pointer;
  padding-left: 0;
  position: relative;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.checkbox label:after, .checkbox label:before, .radio label:after, .radio label:before {
  content: ''
}

.checkbox label:before, .radio label:before {
  padding: .5rem;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  vertical-align: middle;
  height: 18px;
  width: 18px;
  margin: 0 .5em 0 0;
  border: 1px solid #e7f0f6;
  display: inline-block
}

.checkbox label:after, .radio label:after {
  font-size: .7rem;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: $fa-style-family;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 900;
  left: 3.75px
}

.checkbox label.error:after, .checkbox label.error:before, .radio label.error:after, .radio label.error:before {
  content: none
}

.checkbox.bw-2 label:before, .radio.bw-2 label:before {
  border-width: 2px
}

.checkbox input, .radio input {
  display: none
}

.checkbox input:checked + label, .radio input:checked + label {
  font-weight: 400
}

.checkbox input:checked + label:before, .radio input:checked + label:before {
  text-align: center;
  border-color: #9f55ff
}

.checkbox-inline + .checkbox-inline, .radio-inline + .radio-inline {
  margin-left: 0
}

.radio label:before {
  border-radius: 50%
}

.radio label:after {
  font-size: .5rem;
  left: 5.15px
}

.radio.bw-2 label:after {
  left: 6.2px
}

.radio input:checked + label:after {
  content: "\f111";
  color: #9f55ff
}

.radio.radio-1 input:checked + label:before {
  border-color: #fff
}

.radio.radio-1 input:checked + label:after {
  color: #fff
}

.radio.radio-1.radio-outlined label:before {
  border-color: #fff
}

.radio.radio-2 input:checked + label:before {
  border-color: #646f79
}

.radio.radio-2 input:checked + label:after {
  color: #646f79
}

.radio.radio-2.radio-outlined label:before {
  border-color: #646f79
}

.radio.radio-3 input:checked + label:before {
  border-color: #9f55ff
}

.radio.radio-3 input:checked + label:after {
  color: #9f55ff
}

.radio.radio-3.radio-outlined label:before {
  border-color: #9f55ff
}

.radio.radio-4 input:checked + label:before {
  border-color: #028fff
}

.radio.radio-4 input:checked + label:after {
  color: #028fff
}

.radio.radio-4.radio-outlined label:before {
  border-color: #028fff
}

.radio.radio-5 input:checked + label:before {
  border-color: #101480
}

.radio.radio-5 input:checked + label:after {
  color: #101480
}

.radio.radio-5.radio-outlined label:before {
  border-color: #101480
}

.radio.radio-6 input:checked + label:before {
  border-color: #f4f8fb
}

.radio.radio-6 input:checked + label:after {
  color: #f4f8fb
}

.radio.radio-6.radio-outlined label:before {
  border-color: #f4f8fb
}

.radio.radio-primary input:checked + label:before {
  border-color: #007bff
}

.radio.radio-primary input:checked + label:after {
  color: #007bff
}

.radio.radio-primary.radio-outlined label:before {
  border-color: #007bff
}

.radio.radio-secondary input:checked + label:before {
  border-color: #6c757d
}

.radio.radio-secondary input:checked + label:after {
  color: #6c757d
}

.radio.radio-secondary.radio-outlined label:before {
  border-color: #6c757d
}

.radio.radio-success input:checked + label:before {
  border-color: #28a745
}

.radio.radio-success input:checked + label:after {
  color: #28a745
}

.radio.radio-success.radio-outlined label:before {
  border-color: #28a745
}

.radio.radio-info input:checked + label:before {
  border-color: #17a2b8
}

.radio.radio-info input:checked + label:after {
  color: #17a2b8
}

.radio.radio-info.radio-outlined label:before {
  border-color: #17a2b8
}

.radio.radio-warning input:checked + label:before {
  border-color: #ffc107
}

.radio.radio-warning input:checked + label:after {
  color: #ffc107
}

.radio.radio-warning.radio-outlined label:before {
  border-color: #ffc107
}

.radio.radio-danger input:checked + label:before {
  border-color: #dc3545
}

.radio.radio-danger input:checked + label:after {
  color: #dc3545
}

.radio.radio-danger.radio-outlined label:before {
  border-color: #dc3545
}

.radio.radio-light input:checked + label:before {
  border-color: #f8f9fa
}

.radio.radio-light input:checked + label:after {
  color: #f8f9fa
}

.radio.radio-light.radio-outlined label:before {
  border-color: #f8f9fa
}

.radio.radio-dark input:checked + label:before {
  border-color: #343a40
}

.radio.radio-dark input:checked + label:after {
  color: #343a40
}

.radio.radio-dark.radio-outlined label:before {
  border-color: #343a40
}

.checkbox label:before {
  border-radius: .15rem
}

.checkbox input:checked + label:before {
  background: #9f55ff
}

.checkbox input:checked + label:after {
  content: "\f00c";
  color: #fff
}

.checkbox.inverted input:checked + label:before {
  background: #fff !important
}

.checkbox.inverted input:checked + label:after {
  color: #9f55ff
}

.checkbox.circle label:before {
  border-radius: 50%
}

.checkbox.checkbox-1 input:checked + label:before {
  border-color: #fff;
  background: #fff
}

.checkbox.checkbox-1.checkbox-outlined label:before {
  border-color: #fff
}

.checkbox.checkbox-1.checkbox-solid label:before {
  border-color: #fff;
  background: #fff
}

.checkbox.checkbox-1.inverted input:checked + label:after {
  color: #fff
}

.checkbox.checkbox-2 input:checked + label:before {
  border-color: #646f79;
  background: #646f79
}

.checkbox.checkbox-2.checkbox-outlined label:before {
  border-color: #646f79
}

.checkbox.checkbox-2.checkbox-solid label:before {
  border-color: #646f79;
  background: #646f79
}

.checkbox.checkbox-2.inverted input:checked + label:after {
  color: #646f79
}

.checkbox.checkbox-3 input:checked + label:before {
  border-color: #9f55ff;
  background: #9f55ff
}

.checkbox.checkbox-3.checkbox-outlined label:before {
  border-color: #9f55ff
}

.checkbox.checkbox-3.checkbox-solid label:before {
  border-color: #9f55ff;
  background: #9f55ff
}

.checkbox.checkbox-3.inverted input:checked + label:after {
  color: #9f55ff
}

.checkbox.checkbox-4 input:checked + label:before {
  border-color: #028fff;
  background: #028fff
}

.checkbox.checkbox-4.checkbox-outlined label:before {
  border-color: #028fff
}

.checkbox.checkbox-4.checkbox-solid label:before {
  border-color: #028fff;
  background: #028fff
}

.checkbox.checkbox-4.inverted input:checked + label:after {
  color: #028fff
}

.checkbox.checkbox-5 input:checked + label:before {
  border-color: #101480;
  background: #101480
}

.checkbox.checkbox-5.checkbox-outlined label:before {
  border-color: #101480
}

.checkbox.checkbox-5.checkbox-solid label:before {
  border-color: #101480;
  background: #101480
}

.checkbox.checkbox-5.inverted input:checked + label:after {
  color: #101480
}

.checkbox.checkbox-6 input:checked + label:before {
  border-color: #f4f8fb;
  background: #f4f8fb
}

.checkbox.checkbox-6.checkbox-outlined label:before {
  border-color: #f4f8fb
}

.checkbox.checkbox-6.checkbox-solid label:before {
  border-color: #f4f8fb;
  background: #f4f8fb
}

.checkbox.checkbox-6.inverted input:checked + label:after {
  color: #f4f8fb
}

.checkbox.checkbox-primary input:checked + label:before {
  border-color: #007bff;
  background: #007bff
}

.checkbox.checkbox-primary.checkbox-outlined label:before {
  border-color: #007bff
}

.checkbox.checkbox-primary.checkbox-solid label:before {
  border-color: #007bff;
  background: #007bff
}

.checkbox.checkbox-primary.inverted input:checked + label:after {
  color: #007bff
}

.checkbox.checkbox-secondary input:checked + label:before {
  border-color: #6c757d;
  background: #6c757d
}

.checkbox.checkbox-secondary.checkbox-outlined label:before {
  border-color: #6c757d
}

.checkbox.checkbox-secondary.checkbox-solid label:before {
  border-color: #6c757d;
  background: #6c757d
}

.checkbox.checkbox-secondary.inverted input:checked + label:after {
  color: #6c757d
}

.checkbox.checkbox-success input:checked + label:before {
  border-color: #28a745;
  background: #28a745
}

.checkbox.checkbox-success.checkbox-outlined label:before {
  border-color: #28a745
}

.checkbox.checkbox-success.checkbox-solid label:before {
  border-color: #28a745;
  background: #28a745
}

.checkbox.checkbox-success.inverted input:checked + label:after {
  color: #28a745
}

.checkbox.checkbox-info input:checked + label:before {
  border-color: #17a2b8;
  background: #17a2b8
}

.checkbox.checkbox-info.checkbox-outlined label:before {
  border-color: #17a2b8
}

.checkbox.checkbox-info.checkbox-solid label:before {
  border-color: #17a2b8;
  background: #17a2b8
}

.checkbox.checkbox-info.inverted input:checked + label:after {
  color: #17a2b8
}

.checkbox.checkbox-warning input:checked + label:before {
  border-color: #ffc107;
  background: #ffc107
}

.checkbox.checkbox-warning.checkbox-outlined label:before {
  border-color: #ffc107
}

.checkbox.checkbox-warning.checkbox-solid label:before {
  border-color: #ffc107;
  background: #ffc107
}

.checkbox.checkbox-warning.inverted input:checked + label:after {
  color: #ffc107
}

.checkbox.checkbox-danger input:checked + label:before {
  border-color: #dc3545;
  background: #dc3545
}

.checkbox.checkbox-danger.checkbox-outlined label:before {
  border-color: #dc3545
}

.checkbox.checkbox-danger.checkbox-solid label:before {
  border-color: #dc3545;
  background: #dc3545
}

.checkbox.checkbox-danger.inverted input:checked + label:after {
  color: #dc3545
}

.checkbox.checkbox-light input:checked + label:before {
  border-color: #f8f9fa;
  background: #f8f9fa
}

.checkbox.checkbox-light.checkbox-outlined label:before {
  border-color: #f8f9fa
}

.checkbox.checkbox-light.checkbox-solid label:before {
  border-color: #f8f9fa;
  background: #f8f9fa
}

.checkbox.checkbox-light.inverted input:checked + label:after {
  color: #f8f9fa
}

.checkbox.checkbox-dark input:checked + label:before {
  border-color: #343a40;
  background: #343a40
}

.checkbox.checkbox-dark.checkbox-outlined label:before {
  border-color: #343a40
}

.checkbox.checkbox-dark.checkbox-solid label:before {
  border-color: #343a40;
  background: #343a40
}

.checkbox.checkbox-dark.inverted input:checked + label:after {
  color: #343a40
}

.checkbox-inline, .radio-inline {
  padding-left: 0
}

.input-group .checkbox label:before, .input-group .radio label:before {
  margin-right: 0
}

.input-group + .input-group {
  margin-top: 10px
}

.image-background {
  position: relative;
  background-attachment: scroll;
  background-position: center center;
  background-repeat: no-repeat
}

.image-background.cover {
  background-size: cover
}

.image-background.contain {
  background-size: contain
}

.image-background.left-bottom {
  background-position: 0 bottom
}

.image-background.center-top {
  background-position: center top
}

.image-background.center-bottom {
  background-position: center bottom
}

.image-background.p100-top {
  background-position: 100% top
}

.image-background.p100-bottom {
  background-position: 100% bottom
}

.parallax {
  position: static
}

.parallax, .parallax.blurred:before {
  background-attachment: fixed
}

.parallax.blurred:before {
  position: absolute
}

.bg-1 {
  background-color: #fff !important
}

.bg-1.edge.bottom-right {
  position: relative
}

.bg-1.edge.bottom-right::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 250' fill='rgb(255,255,255)' fill-opacity='1'%3E%3Cpolygon points='0,0 100,0 0,250'%3E%3C/polygon%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: '';
  height: 250px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
  bottom: -250px
}

.bg-1.edge.top-left {
  position: relative
}

.bg-1.edge.top-left::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 250' fill='rgb(255,255,255)' fill-opacity='1'%3E%3Cpolygon points='0,250 100,250 100,0'%3E%3C/polygon%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: '';
  height: 250px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
  top: -250px
}

.bg-1.arrow:after {
  background-color: #fff
}

.bg-1.alpha-1 {
  background-color: rgba(255, 255, 255, .1) !important
}

.bg-1.alpha-2 {
  background-color: rgba(255, 255, 255, .2) !important
}

.bg-1.alpha-3 {
  background-color: rgba(255, 255, 255, .3) !important
}

.bg-1.alpha-4 {
  background-color: rgba(255, 255, 255, .4) !important
}

.bg-1.alpha-5 {
  background-color: rgba(255, 255, 255, .5) !important
}

.bg-1.alpha-6 {
  background-color: rgba(255, 255, 255, .6) !important
}

.bg-1.alpha-7 {
  background-color: rgba(255, 255, 255, .7) !important
}

.bg-1.alpha-8 {
  background-color: rgba(255, 255, 255, .8) !important
}

.bg-1.alpha-9 {
  background-color: rgba(255, 255, 255, .9) !important
}

.bg-1-gradient {
  background: -webkit-gradient(linear, left top, left bottom, from(white), to(#fff));
  background: linear-gradient(white, #fff)
}

.bg-1-gradient-start {
  background: #fff
}

.bg-2 {
  background-color: #646f79 !important
}

.bg-2.edge.bottom-right {
  position: relative
}

.bg-2.edge.bottom-right::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 250' fill='rgb(100,111,121)' fill-opacity='1'%3E%3Cpolygon points='0,0 100,0 0,250'%3E%3C/polygon%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: '';
  height: 250px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
  bottom: -250px
}

.bg-2.edge.top-left {
  position: relative
}

.bg-2.edge.top-left::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 250' fill='rgb(100,111,121)' fill-opacity='1'%3E%3Cpolygon points='0,250 100,250 100,0'%3E%3C/polygon%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: '';
  height: 250px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
  top: -250px
}

.bg-2.arrow:after {
  background-color: #646f79
}

.bg-2.alpha-1 {
  background-color: rgba(100, 111, 121, .1) !important
}

.bg-2.alpha-2 {
  background-color: rgba(100, 111, 121, .2) !important
}

.bg-2.alpha-3 {
  background-color: rgba(100, 111, 121, .3) !important
}

.bg-2.alpha-4 {
  background-color: rgba(100, 111, 121, .4) !important
}

.bg-2.alpha-5 {
  background-color: rgba(100, 111, 121, .5) !important
}

.bg-2.alpha-6 {
  background-color: rgba(100, 111, 121, .6) !important
}

.bg-2.alpha-7 {
  background-color: rgba(100, 111, 121, .7) !important
}

.bg-2.alpha-8 {
  background-color: rgba(100, 111, 121, .8) !important
}

.bg-2.alpha-9 {
  background-color: rgba(100, 111, 121, .9) !important
}

.bg-2-gradient {
  background: -webkit-gradient(linear, left top, left bottom, from(#66717b), to(#646f79));
  background: linear-gradient(#66717b, #646f79)
}

.bg-2-gradient-start {
  background: #66717b
}

.bg-3 {
  background-color: #9f55ff !important
}

.bg-3.edge.bottom-right {
  position: relative
}

.bg-3.edge.bottom-right::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 250' fill='rgb(159,85,255)' fill-opacity='1'%3E%3Cpolygon points='0,0 100,0 0,250'%3E%3C/polygon%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: '';
  height: 250px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
  bottom: -250px
}

.bg-3.edge.top-left {
  position: relative
}

.bg-3.edge.top-left::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 250' fill='rgb(159,85,255)' fill-opacity='1'%3E%3Cpolygon points='0,250 100,250 100,0'%3E%3C/polygon%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: '';
  height: 250px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
  top: -250px
}

.bg-3.arrow:after {
  background-color: #9f55ff
}

.bg-3.alpha-1 {
  background-color: rgba(159, 85, 255, .1) !important
}

.bg-3.alpha-2 {
  background-color: rgba(159, 85, 255, .2) !important
}

.bg-3.alpha-3 {
  background-color: rgba(159, 85, 255, .3) !important
}

.bg-3.alpha-4 {
  background-color: rgba(159, 85, 255, .4) !important
}

.bg-3.alpha-5 {
  background-color: rgba(159, 85, 255, .5) !important
}

.bg-3.alpha-6 {
  background-color: rgba(159, 85, 255, .6) !important
}

.bg-3.alpha-7 {
  background-color: rgba(159, 85, 255, .7) !important
}

.bg-3.alpha-8 {
  background-color: rgba(159, 85, 255, .8) !important
}

.bg-3.alpha-9 {
  background-color: rgba(159, 85, 255, .9) !important
}

.bg-3-gradient {
  background: -webkit-gradient(linear, left top, left bottom, from(#a159ff), to(#9f55ff));
  background: linear-gradient(#a159ff, #9f55ff)
}

.bg-3-gradient-start {
  background: #a159ff
}

.bg-4 {
  background-color: #028fff !important
}

.bg-4.edge.bottom-right {
  position: relative
}

.bg-4.edge.bottom-right::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 250' fill='rgb(2,143,255)' fill-opacity='1'%3E%3Cpolygon points='0,0 100,0 0,250'%3E%3C/polygon%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: '';
  height: 250px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
  bottom: -250px
}

.bg-4.edge.top-left {
  position: relative
}

.bg-4.edge.top-left::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 250' fill='rgb(2,143,255)' fill-opacity='1'%3E%3Cpolygon points='0,250 100,250 100,0'%3E%3C/polygon%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: '';
  height: 250px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
  top: -250px
}

.bg-4.arrow:after {
  background-color: #028fff
}

.bg-4.alpha-1 {
  background-color: rgba(2, 143, 255, .1) !important
}

.bg-4.alpha-2 {
  background-color: rgba(2, 143, 255, .2) !important
}

.bg-4.alpha-3 {
  background-color: rgba(2, 143, 255, .3) !important
}

.bg-4.alpha-4 {
  background-color: rgba(2, 143, 255, .4) !important
}

.bg-4.alpha-5 {
  background-color: rgba(2, 143, 255, .5) !important
}

.bg-4.alpha-6 {
  background-color: rgba(2, 143, 255, .6) !important
}

.bg-4.alpha-7 {
  background-color: rgba(2, 143, 255, .7) !important
}

.bg-4.alpha-8 {
  background-color: rgba(2, 143, 255, .8) !important
}

.bg-4.alpha-9 {
  background-color: rgba(2, 143, 255, .9) !important
}

.bg-4-gradient {
  background: -webkit-gradient(linear, left top, left bottom, from(#0691ff), to(#028fff));
  background: linear-gradient(#0691ff, #028fff)
}

.bg-4-gradient-start {
  background: #0691ff
}

.bg-5 {
  background-color: #101480 !important
}

.bg-5.edge.bottom-right {
  position: relative
}

.bg-5.edge.bottom-right::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 250' fill='rgb(16,20,128)' fill-opacity='1'%3E%3Cpolygon points='0,0 100,0 0,250'%3E%3C/polygon%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: '';
  height: 250px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
  bottom: -250px
}

.bg-5.edge.top-left {
  position: relative
}

.bg-5.edge.top-left::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 250' fill='rgb(16,20,128)' fill-opacity='1'%3E%3Cpolygon points='0,250 100,250 100,0'%3E%3C/polygon%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: '';
  height: 250px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
  top: -250px
}

.bg-5.arrow:after {
  background-color: #101480
}

.bg-5.alpha-1 {
  background-color: rgba(16, 20, 128, .1) !important
}

.bg-5.alpha-2 {
  background-color: rgba(16, 20, 128, .2) !important
}

.bg-5.alpha-3 {
  background-color: rgba(16, 20, 128, .3) !important
}

.bg-5.alpha-4 {
  background-color: rgba(16, 20, 128, .4) !important
}

.bg-5.alpha-5 {
  background-color: rgba(16, 20, 128, .5) !important
}

.bg-5.alpha-6 {
  background-color: rgba(16, 20, 128, .6) !important
}

.bg-5.alpha-7 {
  background-color: rgba(16, 20, 128, .7) !important
}

.bg-5.alpha-8 {
  background-color: rgba(16, 20, 128, .8) !important
}

.bg-5.alpha-9 {
  background-color: rgba(16, 20, 128, .9) !important
}

.bg-5-gradient {
  background: -webkit-gradient(linear, left top, left bottom, from(#101583), to(#101480));
  background: linear-gradient(#101583, #101480)
}

.bg-5-gradient-start {
  background: #101583
}

.bg-6 {
  background-color: #f4f8fb !important
}

.bg-6.edge.bottom-right {
  position: relative
}

.bg-6.edge.bottom-right::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 250' fill='rgb(244,248,251)' fill-opacity='1'%3E%3Cpolygon points='0,0 100,0 0,250'%3E%3C/polygon%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: '';
  height: 250px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
  bottom: -250px
}

.bg-6.edge.top-left {
  position: relative
}

.bg-6.edge.top-left::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 250' fill='rgb(244,248,251)' fill-opacity='1'%3E%3Cpolygon points='0,250 100,250 100,0'%3E%3C/polygon%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: '';
  height: 250px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
  top: -250px
}

.bg-6.arrow:after {
  background-color: #f4f8fb
}

.bg-6.alpha-1 {
  background-color: rgba(244, 248, 251, .1) !important
}

.bg-6.alpha-2 {
  background-color: rgba(244, 248, 251, .2) !important
}

.bg-6.alpha-3 {
  background-color: rgba(244, 248, 251, .3) !important
}

.bg-6.alpha-4 {
  background-color: rgba(244, 248, 251, .4) !important
}

.bg-6.alpha-5 {
  background-color: rgba(244, 248, 251, .5) !important
}

.bg-6.alpha-6 {
  background-color: rgba(244, 248, 251, .6) !important
}

.bg-6.alpha-7 {
  background-color: rgba(244, 248, 251, .7) !important
}

.bg-6.alpha-8 {
  background-color: rgba(244, 248, 251, .8) !important
}

.bg-6.alpha-9 {
  background-color: rgba(244, 248, 251, .9) !important
}

.bg-6-gradient {
  background: -webkit-gradient(linear, left top, left bottom, from(#f7fafc), to(#f4f8fb));
  background: linear-gradient(#f7fafc, #f4f8fb)
}

.bg-6-gradient-start {
  background: #f7fafc
}

.arrow {
  position: relative
}

.arrow:after {
  pointer-events: none;
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}

.arrow.bottom:after, .arrow.top:after {
  margin-left: -15px;
  left: 50%;
  border-radius: 10px 5px
}

.arrow.left:after, .arrow.right:after {
  top: 50%;
  margin-top: -15px;
  border-radius: 5px 10px
}

.arrow.top:after {
  top: -13px
}

.arrow.right:after {
  right: -13px
}

.arrow.bottom:after {
  bottom: -13px
}

.arrow.left:after {
  left: -13px
}

.b-t {
  border-top: 1px solid #edf4f8
}

.b-r {
  border-right: 1px solid #edf4f8
}

.b-b {
  border-bottom: 1px solid #edf4f8
}

.b-l {
  border-left: 1px solid #edf4f8
}

@media (min-width: 576px) {
  .b-sm-t {
    border-top: 1px solid #edf4f8
  }
  .b-sm-r {
    border-right: 1px solid #edf4f8
  }
  .b-sm-b {
    border-bottom: 1px solid #edf4f8
  }
  .b-sm-l {
    border-left: 1px solid #edf4f8
  }
}

@media (min-width: 768px) {
  .b-md-t {
    border-top: 1px solid #edf4f8
  }
  .b-md-r {
    border-right: 1px solid #edf4f8
  }
  .b-md-b {
    border-bottom: 1px solid #edf4f8
  }
  .b-md-l {
    border-left: 1px solid #edf4f8
  }
}

@media (min-width: 992px) {
  .b-lg-t {
    border-top: 1px solid #edf4f8
  }
  .b-lg-r {
    border-right: 1px solid #edf4f8
  }
  .b-lg-b {
    border-bottom: 1px solid #edf4f8
  }
  .b-lg-l {
    border-left: 1px solid #edf4f8
  }
}

@media (min-width: 1200px) {
  .b-xl-t {
    border-top: 1px solid #edf4f8
  }
  .b-xl-r {
    border-right: 1px solid #edf4f8
  }
  .b-xl-b {
    border-bottom: 1px solid #edf4f8
  }
  .b-xl-l {
    border-left: 1px solid #edf4f8
  }
}

.b-1 {
  border-color: #fff !important
}

.shadow-1 {
  -webkit-box-shadow: 0 1px 10px rgba(255, 255, 255, .4) !important;
  box-shadow: 0 1px 10px rgba(255, 255, 255, .4) !important
}

.b-2 {
  border-color: #646f79 !important
}

.shadow-2 {
  -webkit-box-shadow: 0 1px 10px rgba(100, 111, 121, .4) !important;
  box-shadow: 0 1px 10px rgba(100, 111, 121, .4) !important
}

.b-3 {
  border-color: #9f55ff !important
}

.shadow-3 {
  -webkit-box-shadow: 0 1px 10px rgba(159, 85, 255, .4) !important;
  box-shadow: 0 1px 10px rgba(159, 85, 255, .4) !important
}

.b-4 {
  border-color: #028fff !important
}

.shadow-4 {
  -webkit-box-shadow: 0 1px 10px rgba(2, 143, 255, .4) !important;
  box-shadow: 0 1px 10px rgba(2, 143, 255, .4) !important
}

.b-5 {
  border-color: #101480 !important
}

.shadow-5 {
  -webkit-box-shadow: 0 1px 10px rgba(16, 20, 128, .4) !important;
  box-shadow: 0 1px 10px rgba(16, 20, 128, .4) !important
}

.b-6 {
  border-color: #f4f8fb !important
}

.shadow-6 {
  -webkit-box-shadow: 0 1px 10px rgba(244, 248, 251, .4) !important;
  box-shadow: 0 1px 10px rgba(244, 248, 251, .4) !important
}

.b-primary {
  border-color: #007bff !important
}

.shadow-primary {
  -webkit-box-shadow: 0 1px 10px rgba(0, 123, 255, .4) !important;
  box-shadow: 0 1px 10px rgba(0, 123, 255, .4) !important
}

.b-secondary {
  border-color: #6c757d !important
}

.shadow-secondary {
  -webkit-box-shadow: 0 1px 10px rgba(108, 117, 125, .4) !important;
  box-shadow: 0 1px 10px rgba(108, 117, 125, .4) !important
}

.b-success {
  border-color: #28a745 !important
}

.shadow-success {
  -webkit-box-shadow: 0 1px 10px rgba(40, 167, 69, .4) !important;
  box-shadow: 0 1px 10px rgba(40, 167, 69, .4) !important
}

.b-info {
  border-color: #17a2b8 !important
}

.shadow-info {
  -webkit-box-shadow: 0 1px 10px rgba(23, 162, 184, .4) !important;
  box-shadow: 0 1px 10px rgba(23, 162, 184, .4) !important
}

.b-warning {
  border-color: #ffc107 !important
}

.shadow-warning {
  -webkit-box-shadow: 0 1px 10px rgba(255, 193, 7, .4) !important;
  box-shadow: 0 1px 10px rgba(255, 193, 7, .4) !important
}

.b-danger {
  border-color: #dc3545 !important
}

.shadow-danger {
  -webkit-box-shadow: 0 1px 10px rgba(220, 53, 69, .4) !important;
  box-shadow: 0 1px 10px rgba(220, 53, 69, .4) !important
}

.b-light {
  border-color: #f8f9fa !important
}

.shadow-light {
  -webkit-box-shadow: 0 1px 10px rgba(248, 249, 250, .4) !important;
  box-shadow: 0 1px 10px rgba(248, 249, 250, .4) !important
}

.b-dark {
  border-color: #343a40 !important
}

.shadow-dark {
  -webkit-box-shadow: 0 1px 10px rgba(52, 58, 64, .4) !important;
  box-shadow: 0 1px 10px rgba(52, 58, 64, .4) !important
}

.shadow {
  -webkit-box-shadow: 0 1px 9px 1px rgba(0, 0, 0, .15);
  box-shadow: 0 1px 9px 1px rgba(0, 0, 0, .15)
}

.drop-shadow {
  -webkit-filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, .25));
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, .25))
}

.shadow-box {
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, .1), 0 2px 6px rgba(0, 0, 0, .03);
  box-shadow: 0 0 1px rgba(0, 0, 0, .1), 0 2px 6px rgba(0, 0, 0, .03)
}

.shadow-hover {
  -webkit-transition: -webkit-box-shadow .3s ease;
  transition: -webkit-box-shadow .3s ease;
  transition: box-shadow .3s ease;
  transition: box-shadow .3s ease, -webkit-box-shadow .3s ease
}

.shadow-hover:hover {
  -webkit-box-shadow: 0 1px 15px rgba(0, 0, 0, .15) !important;
  box-shadow: 0 1px 15px rgba(0, 0, 0, .15) !important
}

.rounded-circle-left {
  border-top-left-radius: 5rem;
  border-bottom-left-radius: 5rem
}

.rounded-circle-right {
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem
}

.border-input {
  border: 1px solid #e7f0f6
}

.bw-2 {
  border-width: 2px
}

.brand-facebook {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998
}

.brand-facebook:hover {
  color: #fff;
  background-color: #30497c;
  border-color: #2d4373
}

.brand-facebook.focus, .brand-facebook:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(88, 114, 167, .5);
  box-shadow: 0 0 0 .2rem rgba(88, 114, 167, .5)
}

.brand-facebook.disabled, .brand-facebook:disabled {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998
}

.brand-facebook:not(:disabled):not(.disabled).active, .brand-facebook:not(:disabled):not(.disabled):active, .show > .brand-facebook.dropdown-toggle {
  color: #fff;
  background-color: #2d4373;
  border-color: #293e6a
}

.brand-facebook:not(:disabled):not(.disabled).active:focus, .brand-facebook:not(:disabled):not(.disabled):active:focus, .show > .brand-facebook.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(88, 114, 167, .5);
  box-shadow: 0 0 0 .2rem rgba(88, 114, 167, .5)
}

.brand-facebook:hover {
  -webkit-box-shadow: 0 1px 10px rgba(59, 89, 152, .4) !important;
  box-shadow: 0 1px 10px rgba(59, 89, 152, .4) !important
}

.brand-google {
  color: #fff;
  background-color: #dd4b39;
  border-color: #dd4b39
}

.brand-google:hover {
  color: #fff;
  background-color: #cd3623;
  border-color: #c23321
}

.brand-google.focus, .brand-google:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(226, 102, 87, .5);
  box-shadow: 0 0 0 .2rem rgba(226, 102, 87, .5)
}

.brand-google.disabled, .brand-google:disabled {
  color: #fff;
  background-color: #dd4b39;
  border-color: #dd4b39
}

.brand-google:not(:disabled):not(.disabled).active, .brand-google:not(:disabled):not(.disabled):active, .show > .brand-google.dropdown-toggle {
  color: #fff;
  background-color: #c23321;
  border-color: #b7301f
}

.brand-google:not(:disabled):not(.disabled).active:focus, .brand-google:not(:disabled):not(.disabled):active:focus, .show > .brand-google.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(226, 102, 87, .5);
  box-shadow: 0 0 0 .2rem rgba(226, 102, 87, .5)
}

.brand-google:hover {
  -webkit-box-shadow: 0 1px 10px rgba(221, 75, 57, .4) !important;
  box-shadow: 0 1px 10px rgba(221, 75, 57, .4) !important
}

.brand-gplus {
  color: #fff;
  background-color: #dd4b39;
  border-color: #dd4b39
}

.brand-gplus:hover {
  color: #fff;
  background-color: #cd3623;
  border-color: #c23321
}

.brand-gplus.focus, .brand-gplus:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(226, 102, 87, .5);
  box-shadow: 0 0 0 .2rem rgba(226, 102, 87, .5)
}

.brand-gplus.disabled, .brand-gplus:disabled {
  color: #fff;
  background-color: #dd4b39;
  border-color: #dd4b39
}

.brand-gplus:not(:disabled):not(.disabled).active, .brand-gplus:not(:disabled):not(.disabled):active, .show > .brand-gplus.dropdown-toggle {
  color: #fff;
  background-color: #c23321;
  border-color: #b7301f
}

.brand-gplus:not(:disabled):not(.disabled).active:focus, .brand-gplus:not(:disabled):not(.disabled):active:focus, .show > .brand-gplus.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(226, 102, 87, .5);
  box-shadow: 0 0 0 .2rem rgba(226, 102, 87, .5)
}

.brand-gplus:hover {
  -webkit-box-shadow: 0 1px 10px rgba(221, 75, 57, .4) !important;
  box-shadow: 0 1px 10px rgba(221, 75, 57, .4) !important
}

.brand-twitter {
  color: #fff;
  background-color: #00aced;
  border-color: #00aced
}

.brand-twitter:hover {
  color: #fff;
  background-color: #0090c7;
  border-color: #0087ba
}

.brand-twitter.focus, .brand-twitter:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(38, 184, 240, .5);
  box-shadow: 0 0 0 .2rem rgba(38, 184, 240, .5)
}

.brand-twitter.disabled, .brand-twitter:disabled {
  color: #fff;
  background-color: #00aced;
  border-color: #00aced
}

.brand-twitter:not(:disabled):not(.disabled).active, .brand-twitter:not(:disabled):not(.disabled):active, .show > .brand-twitter.dropdown-toggle {
  color: #fff;
  background-color: #0087ba;
  border-color: #007ead
}

.brand-twitter:not(:disabled):not(.disabled).active:focus, .brand-twitter:not(:disabled):not(.disabled):active:focus, .show > .brand-twitter.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(38, 184, 240, .5);
  box-shadow: 0 0 0 .2rem rgba(38, 184, 240, .5)
}

.brand-twitter:hover {
  -webkit-box-shadow: 0 1px 10px rgba(0, 172, 237, .4) !important;
  box-shadow: 0 1px 10px rgba(0, 172, 237, .4) !important
}

.brand-linkedin {
  color: #fff;
  background-color: #007bb6;
  border-color: #007bb6
}

.brand-linkedin:hover {
  color: #fff;
  background-color: #006190;
  border-color: #005983
}

.brand-linkedin.focus, .brand-linkedin:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(38, 143, 193, .5);
  box-shadow: 0 0 0 .2rem rgba(38, 143, 193, .5)
}

.brand-linkedin.disabled, .brand-linkedin:disabled {
  color: #fff;
  background-color: #007bb6;
  border-color: #007bb6
}

.brand-linkedin:not(:disabled):not(.disabled).active, .brand-linkedin:not(:disabled):not(.disabled):active, .show > .brand-linkedin.dropdown-toggle {
  color: #fff;
  background-color: #005983;
  border-color: #005076
}

.brand-linkedin:not(:disabled):not(.disabled).active:focus, .brand-linkedin:not(:disabled):not(.disabled):active:focus, .show > .brand-linkedin.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(38, 143, 193, .5);
  box-shadow: 0 0 0 .2rem rgba(38, 143, 193, .5)
}

.brand-linkedin:hover {
  -webkit-box-shadow: 0 1px 10px rgba(0, 123, 182, .4) !important;
  box-shadow: 0 1px 10px rgba(0, 123, 182, .4) !important
}

.brand-pinterest {
  color: #fff;
  background-color: #cb2027;
  border-color: #cb2027
}

.brand-pinterest:hover {
  color: #fff;
  background-color: #aa1b21;
  border-color: #9f191f
}

.brand-pinterest.focus, .brand-pinterest:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(211, 65, 71, .5);
  box-shadow: 0 0 0 .2rem rgba(211, 65, 71, .5)
}

.brand-pinterest.disabled, .brand-pinterest:disabled {
  color: #fff;
  background-color: #cb2027;
  border-color: #cb2027
}

.brand-pinterest:not(:disabled):not(.disabled).active, .brand-pinterest:not(:disabled):not(.disabled):active, .show > .brand-pinterest.dropdown-toggle {
  color: #fff;
  background-color: #9f191f;
  border-color: #94171c
}

.brand-pinterest:not(:disabled):not(.disabled).active:focus, .brand-pinterest:not(:disabled):not(.disabled):active:focus, .show > .brand-pinterest.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(211, 65, 71, .5);
  box-shadow: 0 0 0 .2rem rgba(211, 65, 71, .5)
}

.brand-pinterest:hover {
  -webkit-box-shadow: 0 1px 10px rgba(203, 32, 39, .4) !important;
  box-shadow: 0 1px 10px rgba(203, 32, 39, .4) !important
}

.brand-git {
  color: #fff;
  background-color: #666;
  border-color: #666
}

.brand-git:hover {
  color: #fff;
  background-color: #535353;
  border-color: #4d4d4d
}

.brand-git.focus, .brand-git:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(125, 125, 125, .5);
  box-shadow: 0 0 0 .2rem rgba(125, 125, 125, .5)
}

.brand-git.disabled, .brand-git:disabled {
  color: #fff;
  background-color: #666;
  border-color: #666
}

.brand-git:not(:disabled):not(.disabled).active, .brand-git:not(:disabled):not(.disabled):active, .show > .brand-git.dropdown-toggle {
  color: #fff;
  background-color: #4d4d4d;
  border-color: #464646
}

.brand-git:not(:disabled):not(.disabled).active:focus, .brand-git:not(:disabled):not(.disabled):active:focus, .show > .brand-git.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(125, 125, 125, .5);
  box-shadow: 0 0 0 .2rem rgba(125, 125, 125, .5)
}

.brand-git:hover {
  -webkit-box-shadow: 0 1px 10px rgba(102, 102, 102, .4) !important;
  box-shadow: 0 1px 10px rgba(102, 102, 102, .4) !important
}

.brand-tumblr {
  color: #fff;
  background-color: #32506d;
  border-color: #32506d
}

.brand-tumblr:hover {
  color: #fff;
  background-color: #263d53;
  border-color: #22364a
}

.brand-tumblr.focus, .brand-tumblr:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(81, 106, 131, .5);
  box-shadow: 0 0 0 .2rem rgba(81, 106, 131, .5)
}

.brand-tumblr.disabled, .brand-tumblr:disabled {
  color: #fff;
  background-color: #32506d;
  border-color: #32506d
}

.brand-tumblr:not(:disabled):not(.disabled).active, .brand-tumblr:not(:disabled):not(.disabled):active, .show > .brand-tumblr.dropdown-toggle {
  color: #fff;
  background-color: #22364a;
  border-color: #1e3041
}

.brand-tumblr:not(:disabled):not(.disabled).active:focus, .brand-tumblr:not(:disabled):not(.disabled):active:focus, .show > .brand-tumblr.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(81, 106, 131, .5);
  box-shadow: 0 0 0 .2rem rgba(81, 106, 131, .5)
}

.brand-tumblr:hover {
  -webkit-box-shadow: 0 1px 10px rgba(50, 80, 109, .4) !important;
  box-shadow: 0 1px 10px rgba(50, 80, 109, .4) !important
}

.brand-vimeo {
  color: #212529;
  background-color: #aad450;
  border-color: #aad450
}

.brand-vimeo:hover {
  color: #212529;
  background-color: #9bcc32;
  border-color: #93c130
}

.brand-vimeo.focus, .brand-vimeo:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(149, 186, 74, .5);
  box-shadow: 0 0 0 .2rem rgba(149, 186, 74, .5)
}

.brand-vimeo.disabled, .brand-vimeo:disabled {
  color: #212529;
  background-color: #aad450;
  border-color: #aad450
}

.brand-vimeo:not(:disabled):not(.disabled).active, .brand-vimeo:not(:disabled):not(.disabled):active, .show > .brand-vimeo.dropdown-toggle {
  color: #212529;
  background-color: #93c130;
  border-color: #8bb72d
}

.brand-vimeo:not(:disabled):not(.disabled).active:focus, .brand-vimeo:not(:disabled):not(.disabled):active:focus, .show > .brand-vimeo.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(149, 186, 74, .5);
  box-shadow: 0 0 0 .2rem rgba(149, 186, 74, .5)
}

.brand-vimeo:hover {
  -webkit-box-shadow: 0 1px 10px rgba(170, 212, 80, .4) !important;
  box-shadow: 0 1px 10px rgba(170, 212, 80, .4) !important
}

.brand-youtube {
  color: #fff;
  background-color: #f00;
  border-color: #f00
}

.brand-youtube:hover {
  color: #fff;
  background-color: #950000;
  border-color: #800
}

.brand-youtube.focus, .brand-youtube:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(197, 38, 38, .5);
  box-shadow: 0 0 0 .2rem rgba(197, 38, 38, .5)
}

.brand-youtube.disabled, .brand-youtube:disabled {
  color: #fff;
  background-color: #b00;
  border-color: #b00
}

.brand-youtube:not(:disabled):not(.disabled).active, .brand-youtube:not(:disabled):not(.disabled):active, .show > .brand-youtube.dropdown-toggle {
  color: #fff;
  background-color: #800;
  border-color: #7b0000
}

.brand-youtube:not(:disabled):not(.disabled).active:focus, .brand-youtube:not(:disabled):not(.disabled):active:focus, .show > .brand-youtube.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(197, 38, 38, .5);
  box-shadow: 0 0 0 .2rem rgba(197, 38, 38, .5)
}

.brand-youtube:hover {
  -webkit-box-shadow: 0 1px 10px rgba(187, 0, 0, .4) !important;
  box-shadow: 0 1px 10px rgba(187, 0, 0, .4) !important
}

.brand-flickr {
  color: #fff;
  background-color: #ff0084;
  border-color: #ff0084
}

.brand-flickr:hover {
  color: #fff;
  background-color: #d90070;
  border-color: #cc006a
}

.brand-flickr.focus, .brand-flickr:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(255, 38, 150, .5);
  box-shadow: 0 0 0 .2rem rgba(255, 38, 150, .5)
}

.brand-flickr.disabled, .brand-flickr:disabled {
  color: #fff;
  background-color: #ff0084;
  border-color: #ff0084
}

.brand-flickr:not(:disabled):not(.disabled).active, .brand-flickr:not(:disabled):not(.disabled):active, .show > .brand-flickr.dropdown-toggle {
  color: #fff;
  background-color: #cc006a;
  border-color: #bf0063
}

.brand-flickr:not(:disabled):not(.disabled).active:focus, .brand-flickr:not(:disabled):not(.disabled):active:focus, .show > .brand-flickr.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(255, 38, 150, .5);
  box-shadow: 0 0 0 .2rem rgba(255, 38, 150, .5)
}

.brand-flickr:hover {
  -webkit-box-shadow: 0 1px 10px rgba(255, 0, 132, .4) !important;
  box-shadow: 0 1px 10px rgba(255, 0, 132, .4) !important
}

.brand-reddit {
  color: #fff;
  background-color: #ff4500;
  border-color: #ff4500
}

.brand-reddit:hover {
  color: #fff;
  background-color: #d93b00;
  border-color: #cc3700
}

.brand-reddit.focus, .brand-reddit:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(255, 97, 38, .5);
  box-shadow: 0 0 0 .2rem rgba(255, 97, 38, .5)
}

.brand-reddit.disabled, .brand-reddit:disabled {
  color: #fff;
  background-color: #ff4500;
  border-color: #ff4500
}

.brand-reddit:not(:disabled):not(.disabled).active, .brand-reddit:not(:disabled):not(.disabled):active, .show > .brand-reddit.dropdown-toggle {
  color: #fff;
  background-color: #cc3700;
  border-color: #bf3400
}

.brand-reddit:not(:disabled):not(.disabled).active:focus, .brand-reddit:not(:disabled):not(.disabled):active:focus, .show > .brand-reddit.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(255, 97, 38, .5);
  box-shadow: 0 0 0 .2rem rgba(255, 97, 38, .5)
}

.brand-reddit:hover {
  -webkit-box-shadow: 0 1px 10px rgba(255, 69, 0, .4) !important;
  box-shadow: 0 1px 10px rgba(255, 69, 0, .4) !important
}

.brand-dribbble {
  color: #fff;
  background-color: #ea4c89;
  border-color: #ea4c89
}

.brand-dribbble:hover {
  color: #fff;
  background-color: #e62a72;
  border-color: #e51e6b
}

.brand-dribbble.focus, .brand-dribbble:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(237, 103, 155, .5);
  box-shadow: 0 0 0 .2rem rgba(237, 103, 155, .5)
}

.brand-dribbble.disabled, .brand-dribbble:disabled {
  color: #fff;
  background-color: #ea4c89;
  border-color: #ea4c89
}

.brand-dribbble:not(:disabled):not(.disabled).active, .brand-dribbble:not(:disabled):not(.disabled):active, .show > .brand-dribbble.dropdown-toggle {
  color: #fff;
  background-color: #e51e6b;
  border-color: #dc1a65
}

.brand-dribbble:not(:disabled):not(.disabled).active:focus, .brand-dribbble:not(:disabled):not(.disabled):active:focus, .show > .brand-dribbble.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(237, 103, 155, .5);
  box-shadow: 0 0 0 .2rem rgba(237, 103, 155, .5)
}

.brand-dribbble:hover {
  -webkit-box-shadow: 0 1px 10px rgba(234, 76, 137, .4) !important;
  box-shadow: 0 1px 10px rgba(234, 76, 137, .4) !important
}

.brand-skype {
  color: #fff;
  background-color: #00aff0;
  border-color: #00aff0
}

.brand-skype:hover {
  color: #fff;
  background-color: #0093ca;
  border-color: #008abd
}

.brand-skype.focus, .brand-skype:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(38, 187, 242, .5);
  box-shadow: 0 0 0 .2rem rgba(38, 187, 242, .5)
}

.brand-skype.disabled, .brand-skype:disabled {
  color: #fff;
  background-color: #00aff0;
  border-color: #00aff0
}

.brand-skype:not(:disabled):not(.disabled).active, .brand-skype:not(:disabled):not(.disabled):active, .show > .brand-skype.dropdown-toggle {
  color: #fff;
  background-color: #008abd;
  border-color: #0081b0
}

.brand-skype:not(:disabled):not(.disabled).active:focus, .brand-skype:not(:disabled):not(.disabled):active:focus, .show > .brand-skype.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(38, 187, 242, .5);
  box-shadow: 0 0 0 .2rem rgba(38, 187, 242, .5)
}

.brand-skype:hover {
  -webkit-box-shadow: 0 1px 10px rgba(0, 175, 240, .4) !important;
  box-shadow: 0 1px 10px rgba(0, 175, 240, .4) !important
}

.brand-instagram {
  color: #fff;
  background-color: #517fa4;
  border-color: #517fa4
}

.brand-instagram:hover {
  color: #fff;
  background-color: #446b8a;
  border-color: #406582
}

.brand-instagram.focus, .brand-instagram:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(107, 146, 178, .5);
  box-shadow: 0 0 0 .2rem rgba(107, 146, 178, .5)
}

.brand-instagram.disabled, .brand-instagram:disabled {
  color: #fff;
  background-color: #517fa4;
  border-color: #517fa4
}

.brand-instagram:not(:disabled):not(.disabled).active, .brand-instagram:not(:disabled):not(.disabled):active, .show > .brand-instagram.dropdown-toggle {
  color: #fff;
  background-color: #406582;
  border-color: #3c5e79
}

.brand-instagram:not(:disabled):not(.disabled).active:focus, .brand-instagram:not(:disabled):not(.disabled):active:focus, .show > .brand-instagram.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(107, 146, 178, .5);
  box-shadow: 0 0 0 .2rem rgba(107, 146, 178, .5)
}

.brand-instagram:hover {
  -webkit-box-shadow: 0 1px 10px rgba(81, 127, 164, .4) !important;
  box-shadow: 0 1px 10px rgba(81, 127, 164, .4) !important
}

.brand-lastfm {
  color: #fff;
  background-color: #c3000d;
  border-color: #c3000d
}

.brand-lastfm:hover {
  color: #fff;
  background-color: #9d000a;
  border-color: #90000a
}

.brand-lastfm.focus, .brand-lastfm:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(204, 38, 49, .5);
  box-shadow: 0 0 0 .2rem rgba(204, 38, 49, .5)
}

.brand-lastfm.disabled, .brand-lastfm:disabled {
  color: #fff;
  background-color: #c3000d;
  border-color: #c3000d
}

.brand-lastfm:not(:disabled):not(.disabled).active, .brand-lastfm:not(:disabled):not(.disabled):active, .show > .brand-lastfm.dropdown-toggle {
  color: #fff;
  background-color: #90000a;
  border-color: #830009
}

.brand-lastfm:not(:disabled):not(.disabled).active:focus, .brand-lastfm:not(:disabled):not(.disabled):active:focus, .show > .brand-lastfm.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(204, 38, 49, .5);
  box-shadow: 0 0 0 .2rem rgba(204, 38, 49, .5)
}

.brand-lastfm:hover {
  -webkit-box-shadow: 0 1px 10px rgba(195, 0, 13, .4) !important;
  box-shadow: 0 1px 10px rgba(195, 0, 13, .4) !important
}

.brand-soundcloud {
  color: #212529;
  background-color: #f80;
  border-color: #f80
}

.brand-soundcloud:hover {
  color: #fff;
  background-color: #d97400;
  border-color: #cc6d00
}

.brand-soundcloud.focus, .brand-soundcloud:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(222, 121, 6, .5);
  box-shadow: 0 0 0 .2rem rgba(222, 121, 6, .5)
}

.brand-soundcloud.disabled, .brand-soundcloud:disabled {
  color: #212529;
  background-color: #f80;
  border-color: #f80
}

.brand-soundcloud:not(:disabled):not(.disabled).active, .brand-soundcloud:not(:disabled):not(.disabled):active, .show > .brand-soundcloud.dropdown-toggle {
  color: #fff;
  background-color: #cc6d00;
  border-color: #bf6600
}

.brand-soundcloud:not(:disabled):not(.disabled).active:focus, .brand-soundcloud:not(:disabled):not(.disabled):active:focus, .show > .brand-soundcloud.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(222, 121, 6, .5);
  box-shadow: 0 0 0 .2rem rgba(222, 121, 6, .5)
}

.brand-soundcloud:hover {
  -webkit-box-shadow: 0 1px 10px rgba(255, 136, 0, .4) !important;
  box-shadow: 0 1px 10px rgba(255, 136, 0, .4) !important
}

.brand-behance {
  color: #fff;
  background-color: #1769ff;
  border-color: #1769ff
}

.brand-behance:hover {
  color: #fff;
  background-color: #0055f0;
  border-color: #0050e3
}

.brand-behance.focus, .brand-behance:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(58, 128, 255, .5);
  box-shadow: 0 0 0 .2rem rgba(58, 128, 255, .5)
}

.brand-behance.disabled, .brand-behance:disabled {
  color: #fff;
  background-color: #1769ff;
  border-color: #1769ff
}

.brand-behance:not(:disabled):not(.disabled).active, .brand-behance:not(:disabled):not(.disabled):active, .show > .brand-behance.dropdown-toggle {
  color: #fff;
  background-color: #0050e3;
  border-color: #004cd6
}

.brand-behance:not(:disabled):not(.disabled).active:focus, .brand-behance:not(:disabled):not(.disabled):active:focus, .show > .brand-behance.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(58, 128, 255, .5);
  box-shadow: 0 0 0 .2rem rgba(58, 128, 255, .5)
}

.brand-behance:hover {
  -webkit-box-shadow: 0 1px 10px rgba(23, 105, 255, .4) !important;
  box-shadow: 0 1px 10px rgba(23, 105, 255, .4) !important
}

.brand-envato {
  color: #212529;
  background-color: #82b541;
  border-color: #82b541
}

.brand-envato:hover {
  color: #fff;
  background-color: #6e9937;
  border-color: #678f34
}

.brand-envato.focus, .brand-envato:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(115, 159, 61, .5);
  box-shadow: 0 0 0 .2rem rgba(115, 159, 61, .5)
}

.brand-envato.disabled, .brand-envato:disabled {
  color: #212529;
  background-color: #82b541;
  border-color: #82b541
}

.brand-envato:not(:disabled):not(.disabled).active, .brand-envato:not(:disabled):not(.disabled):active, .show > .brand-envato.dropdown-toggle {
  color: #fff;
  background-color: #678f34;
  border-color: #608630
}

.brand-envato:not(:disabled):not(.disabled).active:focus, .brand-envato:not(:disabled):not(.disabled):active:focus, .show > .brand-envato.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(115, 159, 61, .5);
  box-shadow: 0 0 0 .2rem rgba(115, 159, 61, .5)
}

.brand-envato:hover {
  -webkit-box-shadow: 0 1px 10px rgba(130, 181, 65, .4) !important;
  box-shadow: 0 1px 10px rgba(130, 181, 65, .4) !important
}

.brand-medium {
  color: #fff;
  background-color: #00ab6c;
  border-color: #00ab6c
}

.brand-medium:hover {
  color: #fff;
  background-color: #008554;
  border-color: #00784c
}

.brand-medium.focus, .brand-medium:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(38, 184, 130, .5);
  box-shadow: 0 0 0 .2rem rgba(38, 184, 130, .5)
}

.brand-medium.disabled, .brand-medium:disabled {
  color: #fff;
  background-color: #00ab6c;
  border-color: #00ab6c
}

.brand-medium:not(:disabled):not(.disabled).active, .brand-medium:not(:disabled):not(.disabled):active, .show > .brand-medium.dropdown-toggle {
  color: #fff;
  background-color: #00784c;
  border-color: #006b44
}

.brand-medium:not(:disabled):not(.disabled).active:focus, .brand-medium:not(:disabled):not(.disabled):active:focus, .show > .brand-medium.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(38, 184, 130, .5);
  box-shadow: 0 0 0 .2rem rgba(38, 184, 130, .5)
}

.brand-medium:hover {
  -webkit-box-shadow: 0 1px 10px rgba(0, 171, 108, .4) !important;
  box-shadow: 0 1px 10px rgba(0, 171, 108, .4) !important
}

.brand-spotify {
  color: #fff;
  background-color: #1db954;
  border-color: #1db954
}

.brand-spotify:hover {
  color: #fff;
  background-color: #189845;
  border-color: #168d40
}

.brand-spotify.focus, .brand-spotify:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(63, 196, 110, .5);
  box-shadow: 0 0 0 .2rem rgba(63, 196, 110, .5)
}

.brand-spotify.disabled, .brand-spotify:disabled {
  color: #fff;
  background-color: #1db954;
  border-color: #1db954
}

.brand-spotify:not(:disabled):not(.disabled).active, .brand-spotify:not(:disabled):not(.disabled):active, .show > .brand-spotify.dropdown-toggle {
  color: #fff;
  background-color: #168d40;
  border-color: #14823b
}

.brand-spotify:not(:disabled):not(.disabled).active:focus, .brand-spotify:not(:disabled):not(.disabled):active:focus, .show > .brand-spotify.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(63, 196, 110, .5);
  box-shadow: 0 0 0 .2rem rgba(63, 196, 110, .5)
}

.brand-spotify:hover {
  -webkit-box-shadow: 0 1px 10px rgba(29, 185, 84, .4) !important;
  box-shadow: 0 1px 10px rgba(29, 185, 84, .4) !important
}

.brand-quora {
  color: #fff;
  background-color: #a82400;
  border-color: #a82400
}

.brand-quora:hover {
  color: #fff;
  background-color: #821c00;
  border-color: #751900
}

.brand-quora.focus, .brand-quora:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(181, 69, 38, .5);
  box-shadow: 0 0 0 .2rem rgba(181, 69, 38, .5)
}

.brand-quora.disabled, .brand-quora:disabled {
  color: #fff;
  background-color: #a82400;
  border-color: #a82400
}

.brand-quora:not(:disabled):not(.disabled).active, .brand-quora:not(:disabled):not(.disabled):active, .show > .brand-quora.dropdown-toggle {
  color: #fff;
  background-color: #751900;
  border-color: #681600
}

.brand-quora:not(:disabled):not(.disabled).active:focus, .brand-quora:not(:disabled):not(.disabled):active:focus, .show > .brand-quora.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(181, 69, 38, .5);
  box-shadow: 0 0 0 .2rem rgba(181, 69, 38, .5)
}

.brand-quora:hover {
  -webkit-box-shadow: 0 1px 10px rgba(168, 36, 0, .4) !important;
  box-shadow: 0 1px 10px rgba(168, 36, 0, .4) !important
}

.brand-xing {
  color: #fff;
  background-color: #026466;
  border-color: #026466
}

.brand-xing:hover {
  color: #fff;
  background-color: #013f40;
  border-color: #013334
}

.brand-xing.focus, .brand-xing:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(40, 123, 125, .5);
  box-shadow: 0 0 0 .2rem rgba(40, 123, 125, .5)
}

.brand-xing.disabled, .brand-xing:disabled {
  color: #fff;
  background-color: #026466;
  border-color: #026466
}

.brand-xing:not(:disabled):not(.disabled).active, .brand-xing:not(:disabled):not(.disabled):active, .show > .brand-xing.dropdown-toggle {
  color: #fff;
  background-color: #013334;
  border-color: #012727
}

.brand-xing:not(:disabled):not(.disabled).active:focus, .brand-xing:not(:disabled):not(.disabled):active:focus, .show > .brand-xing.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(40, 123, 125, .5);
  box-shadow: 0 0 0 .2rem rgba(40, 123, 125, .5)
}

.brand-xing:hover {
  -webkit-box-shadow: 0 1px 10px rgba(2, 100, 102, .4) !important;
  box-shadow: 0 1px 10px rgba(2, 100, 102, .4) !important
}

.brand-snapchat {
  color: #212529;
  background-color: #fffc00;
  border-color: #fffc00
}

.brand-snapchat:hover {
  color: #212529;
  background-color: #d9d600;
  border-color: #ccca00
}

.brand-snapchat.focus, .brand-snapchat:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(222, 220, 6, .5);
  box-shadow: 0 0 0 .2rem rgba(222, 220, 6, .5)
}

.brand-snapchat.disabled, .brand-snapchat:disabled {
  color: #212529;
  background-color: #fffc00;
  border-color: #fffc00
}

.brand-snapchat:not(:disabled):not(.disabled).active, .brand-snapchat:not(:disabled):not(.disabled):active, .show > .brand-snapchat.dropdown-toggle {
  color: #212529;
  background-color: #ccca00;
  border-color: #bfbd00
}

.brand-snapchat:not(:disabled):not(.disabled).active:focus, .brand-snapchat:not(:disabled):not(.disabled):active:focus, .show > .brand-snapchat.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(222, 220, 6, .5);
  box-shadow: 0 0 0 .2rem rgba(222, 220, 6, .5)
}

.brand-snapchat:hover {
  -webkit-box-shadow: 0 1px 10px rgba(255, 252, 0, .4) !important;
  box-shadow: 0 1px 10px rgba(255, 252, 0, .4) !important
}

.brand-telegram {
  color: #fff;
  background-color: #08c;
  border-color: #08c
}

.brand-telegram:hover {
  color: #fff;
  background-color: #006fa6;
  border-color: #069
}

.brand-telegram.focus, .brand-telegram:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(38, 154, 212, .5);
  box-shadow: 0 0 0 .2rem rgba(38, 154, 212, .5)
}

.brand-telegram.disabled, .brand-telegram:disabled {
  color: #fff;
  background-color: #08c;
  border-color: #08c
}

.brand-telegram:not(:disabled):not(.disabled).active, .brand-telegram:not(:disabled):not(.disabled):active, .show > .brand-telegram.dropdown-toggle {
  color: #fff;
  background-color: #069;
  border-color: #005e8c
}

.brand-telegram:not(:disabled):not(.disabled).active:focus, .brand-telegram:not(:disabled):not(.disabled):active:focus, .show > .brand-telegram.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(38, 154, 212, .5);
  box-shadow: 0 0 0 .2rem rgba(38, 154, 212, .5)
}

.brand-telegram:hover {
  -webkit-box-shadow: 0 1px 10px rgba(0, 136, 204, .4) !important;
  box-shadow: 0 1px 10px rgba(0, 136, 204, .4) !important
}

.brand-whatsapp {
  color: #fff;
  background-color: #075e54;
  border-color: #075e54
}

.brand-whatsapp:hover {
  color: #fff;
  background-color: #043a34;
  border-color: #032f2a
}

.brand-whatsapp.focus, .brand-whatsapp:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(44, 118, 110, .5);
  box-shadow: 0 0 0 .2rem rgba(44, 118, 110, .5)
}

.brand-whatsapp.disabled, .brand-whatsapp:disabled {
  color: #fff;
  background-color: #075e54;
  border-color: #075e54
}

.brand-whatsapp:not(:disabled):not(.disabled).active, .brand-whatsapp:not(:disabled):not(.disabled):active, .show > .brand-whatsapp.dropdown-toggle {
  color: #fff;
  background-color: #032f2a;
  border-color: #03231f
}

.brand-whatsapp:not(:disabled):not(.disabled).active:focus, .brand-whatsapp:not(:disabled):not(.disabled):active:focus, .show > .brand-whatsapp.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(44, 118, 110, .5);
  box-shadow: 0 0 0 .2rem rgba(44, 118, 110, .5)
}

.brand-whatsapp:hover {
  -webkit-box-shadow: 0 1px 10px rgba(7, 94, 84, .4) !important;
  box-shadow: 0 1px 10px rgba(7, 94, 84, .4) !important
}

.brand-rss {
  color: #fff;
  background-color: #f26522;
  border-color: #f26522
}

.brand-rss:hover {
  color: #fff;
  background-color: #e1510d;
  border-color: #d54d0d
}

.brand-rss.focus, .brand-rss:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(244, 124, 67, .5);
  box-shadow: 0 0 0 .2rem rgba(244, 124, 67, .5)
}

.brand-rss.disabled, .brand-rss:disabled {
  color: #fff;
  background-color: #f26522;
  border-color: #f26522
}

.brand-rss:not(:disabled):not(.disabled).active, .brand-rss:not(:disabled):not(.disabled):active, .show > .brand-rss.dropdown-toggle {
  color: #fff;
  background-color: #d54d0d;
  border-color: #c8490c
}

.brand-rss:not(:disabled):not(.disabled).active:focus, .brand-rss:not(:disabled):not(.disabled):active:focus, .show > .brand-rss.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(244, 124, 67, .5);
  box-shadow: 0 0 0 .2rem rgba(244, 124, 67, .5)
}

.brand-rss:hover {
  -webkit-box-shadow: 0 1px 10px rgba(242, 101, 34, .4) !important;
  box-shadow: 0 1px 10px rgba(242, 101, 34, .4) !important
}

.ipad, .iphone, .iphone-x {
  border: 1px solid #edf4f8;
  margin: 0 auto;
  position: relative
}

.ipad:after, .ipad:before, .iphone-x:after, .iphone-x:before, .iphone:after, .iphone:before {
  content: '';
  position: absolute
}

.ipad:before, .iphone-x:before, .iphone:before {
  width: 45px;
  height: 4px;
  left: 0;
  right: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
  border-radius: 30px
}

.ipad:after, .iphone-x:after, .iphone:after {
  left: 50%;
  width: 8px;
  height: 8px;
  -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, .03);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, .03);
  display: block;
  border-radius: 50%
}

.ipad .screen, .iphone .screen, .iphone-x .screen {
  margin: 0 auto
}

.ipad .screen img, .iphone .screen img, .iphone-x .screen img {
  max-width: 100%
}

.ipad, .iphone {
  background: #fff;
  max-width: 255px;
  border-radius: 36px;
  padding: 55px 0;
  -webkit-box-shadow: inset 0 4px 10px 1px #fff, inset 0 0 6px 0 rgba(66, 77, 86, .5), 0 2px 0 #aeb5bb, 0 20px 50px -25px rgba(0, 0, 0, .5);
  box-shadow: inset 0 4px 10px 1px #fff, inset 0 0 6px 0 rgba(66, 77, 86, .5), 0 2px 0 #aeb5bb, 0 20px 50px -25px rgba(0, 0, 0, .5)
}

.ipad:before, .iphone:before {
  background: #e3e8eb;
  margin: -25px auto 0
}

.ipad:after, .iphone:after {
  top: 30px;
  margin-left: -45px;
  background: #e3e8eb
}

.ipad .screen, .iphone .screen {
  width: 90%
}

.ipad .screen img, .iphone .screen img {
  -webkit-box-shadow: 0 0 0 1px rgba(0, 17, 53, .16);
  box-shadow: 0 0 0 1px rgba(0, 17, 53, .16)
}

.ipad .button, .iphone .button {
  -webkit-box-shadow: 0 0 1px 2px #e3e8eb inset;
  box-shadow: 0 0 1px 2px #e3e8eb inset;
  border: 1px solid #edf4f8;
  border-radius: 50%;
  bottom: 1.75%;
  height: 0;
  padding-top: 36px;
  width: 36px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%)
}

.ipad.small, .iphone.small {
  max-width: 185px;
  border-radius: 26px;
  padding: 45px 0
}

.ipad.small:before, .iphone.small:before {
  width: 35px
}

.ipad.small:after, .iphone.small:after {
  top: 20px
}

.ipad.small .button, .iphone.small .button {
  width: 28px;
  padding-top: 28px;
  margin-left: -8px
}

.ipad.landscape, .iphone.landscape {
  padding: 1rem 3rem
}

.ipad.landscape:after, .ipad.landscape:before, .iphone.landscape:after, .iphone.landscape:before {
  left: 16%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.ipad.landscape .button, .iphone.landscape .button {
  left: 100%;
  margin-left: -50px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.ipad:before {
  content: none
}

.ipad.portrait:after {
  margin-left: 0;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%)
}

.iphone-x {
  background: #152975;
  max-width: 243.75px;
  border-radius: 26px;
  padding: 9.75px;
  -webkit-box-shadow: 0 1px 0 #aeb5bb, 0 20px 50px -25px rgba(0, 0, 0, .5);
  box-shadow: 0 1px 0 #aeb5bb, 0 20px 50px -25px rgba(0, 0, 0, .5)
}

.iphone-x:after, .iphone-x:before {
  background-color: #1a3290;
  z-index: 1
}

.iphone-x:before {
  margin: 0 auto
}

.iphone-x:after {
  top: 8px;
  margin-left: 33px
}

.iphone-x .screen img {
  border-radius: 13px;
  -webkit-box-shadow: 0 0 1px 1px rgba(0, 0, 0, .015);
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, .015)
}

.iphone-x .notch {
  position: absolute;
  border-radius: 13px;
  width: 135.85px;
  height: 22.75px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #152975;
  top: 0;
  left: 50%
}

.iphone-x.light {
  background-color: #f2f2f5
}

.iphone-x.light .notch {
  background-color: #f2f2f5
}

.iphone-x.light:after, .iphone-x.light:before {
  background-color: #e3e8eb
}

.device-twin {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative
}

.device-twin .mockup {
  -webkit-box-shadow: inset 0 4px 10px 1px #fff, inset 0 0 6px 0 rgba(66, 77, 86, .5), 0 20px 50px -25px rgba(0, 0, 0, .5), 0 0 1px rgba(0, 0, 0, .1);
  box-shadow: inset 0 4px 10px 1px #fff, inset 0 0 6px 0 rgba(66, 77, 86, .5), 0 20px 50px -25px rgba(0, 0, 0, .5), 0 0 1px rgba(0, 0, 0, .1);
  max-width: 210px
}

@media (min-width: 768px) {
  .device-twin .phone {
    -webkit-transition: all .4s ease;
    transition: all .4s ease
  }
  .device-twin .phone.front:hover {
    -webkit-transform: rotate(2deg) translate3d(0, -8px, 0);
    transform: rotate(2deg) translate3d(0, -8px, 0)
  }
  .device-twin .phone.absolute {
    margin-top: -30px
  }
  .device-twin .phone.absolute:hover {
    -webkit-transform: rotate(-2deg) translate3d(-10px, 0, 0);
    transform: rotate(-2deg) translate3d(-10px, 0, 0)
  }
}

.browser {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(1%, #fff), color-stop(10%, #eef1f3));
  background: linear-gradient(#fff 1%, #eef1f3 10%);
  position: relative;
  padding-top: 2em;
  border-radius: .4em
}

.browser:before {
  display: block;
  position: absolute;
  content: '';
  top: .75em;
  left: 1em;
  width: .5em;
  height: .5em;
  border-radius: 50%;
  background: #f44;
  -webkit-box-shadow: 0 0 0 1px #f44, 1.5em 0 0 1px #9b3, 3em 0 0 1px #fb5;
  box-shadow: 0 0 0 1px #f44, 1.5em 0 0 1px #9b3, 3em 0 0 1px #fb5
}

.browser img {
  max-width: 100%
}

.browser.shadow {
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, .3), 0 2px 4px rgba(0, 0, 0, .09);
  box-shadow: 0 0 1px rgba(0, 0, 0, .3), 0 2px 4px rgba(0, 0, 0, .09)
}

.browser.border {
  border: 1px solid #edf4f8
}

.fade-bottom:after {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(70%, #fff), to(#fff));
  background: linear-gradient(rgba(255, 255, 255, 0), #fff 70%, #fff);
  bottom: -25px;
  content: "";
  display: block;
  height: 85px;
  left: 0;
  position: absolute;
  right: 0
}

.gradient {
  background-color: transparent
}

.gradient.gradient-12 {
  background-image: linear-gradient(-135deg, #fff 25%, #646f79 100%)
}

.gradient.gradient-13 {
  background-image: linear-gradient(-135deg, #fff 25%, #9f55ff 100%)
}

.gradient.gradient-14 {
  background-image: linear-gradient(-135deg, #fff 25%, #028fff 100%)
}

.gradient.gradient-15 {
  background-image: linear-gradient(-135deg, #fff 25%, #101480 100%)
}

.gradient.gradient-16 {
  background-image: linear-gradient(-135deg, #fff 25%, #f4f8fb 100%)
}

.gradient.gradient-21 {
  background-image: linear-gradient(-135deg, #646f79 25%, #fff 100%)
}

.gradient.gradient-23 {
  background-image: linear-gradient(-135deg, #646f79 25%, #9f55ff 100%)
}

.gradient.gradient-24 {
  background-image: linear-gradient(-135deg, #646f79 25%, #028fff 100%)
}

.gradient.gradient-25 {
  background-image: linear-gradient(-135deg, #646f79 25%, #101480 100%)
}

.gradient.gradient-26 {
  background-image: linear-gradient(-135deg, #646f79 25%, #f4f8fb 100%)
}

.gradient.gradient-31 {
  background-image: linear-gradient(-135deg, #9f55ff 25%, #fff 100%)
}

.gradient.gradient-32 {
  background-image: linear-gradient(-135deg, #9f55ff 25%, #646f79 100%)
}

.gradient.gradient-34 {
  background-image: linear-gradient(-135deg, #9f55ff 25%, #028fff 100%)
}

.gradient.gradient-35 {
  background-image: linear-gradient(-135deg, #9f55ff 25%, #101480 100%)
}

.gradient.gradient-36 {
  background-image: linear-gradient(-135deg, #9f55ff 25%, #f4f8fb 100%)
}

.gradient.gradient-41 {
  background-image: linear-gradient(-135deg, #028fff 25%, #fff 100%)
}

.gradient.gradient-42 {
  background-image: linear-gradient(-135deg, #028fff 25%, #646f79 100%)
}

.gradient.gradient-43 {
  background-image: linear-gradient(-135deg, #028fff 25%, #9f55ff 100%)
}

.gradient.gradient-45 {
  background-image: linear-gradient(-135deg, #028fff 25%, #101480 100%)
}

.gradient.gradient-46 {
  background-image: linear-gradient(-135deg, #028fff 25%, #f4f8fb 100%)
}

.gradient.gradient-51 {
  background-image: linear-gradient(-135deg, #101480 25%, #fff 100%)
}

.gradient.gradient-52 {
  background-image: linear-gradient(-135deg, #101480 25%, #646f79 100%)
}

.gradient.gradient-53 {
  background-image: linear-gradient(-135deg, #101480 25%, #9f55ff 100%)
}

.gradient.gradient-54 {
  background-image: linear-gradient(-135deg, #101480 25%, #028fff 100%)
}

.gradient.gradient-56 {
  background-image: linear-gradient(-135deg, #101480 25%, #f4f8fb 100%)
}

.gradient.gradient-61 {
  background-image: linear-gradient(-135deg, #f4f8fb 25%, #fff 100%)
}

.gradient.gradient-62 {
  background-image: linear-gradient(-135deg, #f4f8fb 25%, #646f79 100%)
}

.gradient.gradient-63 {
  background-image: linear-gradient(-135deg, #f4f8fb 25%, #9f55ff 100%)
}

.gradient.gradient-64 {
  background-image: linear-gradient(-135deg, #f4f8fb 25%, #028fff 100%)
}

.gradient.gradient-65 {
  background-image: linear-gradient(-135deg, #f4f8fb 25%, #101480 100%)
}

.list li + li {
  padding-top: 10px
}

.list.list-striped li:nth-of-type(2n+1) {
  background-color: rgba(64, 64, 64, .075)
}

.list.list-bordered li {
  border-top: 1px solid #edf4f8
}

.list.list-bordered li:last-child {
  border-bottom: 1px solid #edf4f8
}

.list.list-inline li {
  display: inline-block
}

.grid-of-images a {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
  border-bottom: 0;
  margin: 0 5px 5px 0
}

blockquote {
  position: relative
}

blockquote:before {
  font-family: $fa-style-family;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 900;
  content: "\f10d";
  opacity: .5;
  font-size: 2rem;
  position: absolute;
  left: -16px;
  top: -16px;
}

blockquote.team-quote:before {
  left: 0;
  top: -8px
}

.fullscreen {
  min-height: 100vh
}

@media (min-width: 576px) {
  .fullscreen-sm {
    min-height: 100vh
  }
}

@media (min-width: 768px) {
  .fullscreen-md {
    min-height: 100vh
  }
}

@media (min-width: 992px) {
  .fullscreen-lg {
    min-height: 100vh
  }
}

@media (min-width: 1200px) {
  .fullscreen-xl {
    min-height: 100vh
  }
}

.rounded-split {
  overflow: hidden
}

.rounded-split:after {
  content: '';
  position: absolute;
  background-color: #fff;
  z-index: 0;
  border-radius: 50%;
  -webkit-transform: translate3d(90%, -25%, 0);
  transform: translate3d(90%, -25%, 0);
  height: 200%;
  width: 100%;
  -webkit-box-shadow: 0 0 25px 3px rgba(16, 20, 128, .05);
  box-shadow: 0 0 25px 3px rgba(16, 20, 128, .05)
}

.overflow-hidden {
  overflow: hidden
}

.overflow-x {
  overflow-x: auto
}

.overflow-x-hidden {
  overflow-x: hidden
}

.overflow-y {
  overflow-y: auto
}

.overflow-y-hidden {
  overflow-y: hidden
}

.top {
  top: 0
}

.right {
  right: 0
}

.bottom {
  bottom: 0
}

.left {
  left: 0
}

.op-1 {
  opacity: .1
}

.w-10 {
  width: 10%
}

.op-2 {
  opacity: .2
}

.w-20 {
  width: 20%
}

.op-3 {
  opacity: .3
}

.w-30 {
  width: 30%
}

.op-4 {
  opacity: .4
}

.w-40 {
  width: 40%
}

.op-5 {
  opacity: .5
}

.w-50 {
  width: 50%
}

.op-6 {
  opacity: .6
}

.w-60 {
  width: 60%
}

.op-7 {
  opacity: .7
}

.w-70 {
  width: 70%
}

.op-8 {
  opacity: .8
}

.w-80 {
  width: 80%
}

.op-9 {
  opacity: .9
}

.w-90 {
  width: 90%
}

.arrow-down {
  position: relative;
  margin: 0 auto;
  text-align: center;
  -webkit-animation: jump 5s infinite;
  animation: jump 5s infinite
}

.overlay {
  position: relative
}

.overlay:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(39, 51, 62, .6);
  opacity: 1;
  z-index: 0
}

.overlay [class^=container], .overlay > .content {
  position: relative;
  z-index: 1
}

.overlay > .content {
  position: relative
}

.overlay.gradient.gradient-12:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .6) 25%, rgba(100, 111, 121, .6) 100%)
}

.overlay.gradient.gradient-13:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .6) 25%, rgba(159, 85, 255, .6) 100%)
}

.overlay.gradient.gradient-14:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .6) 25%, rgba(2, 143, 255, .6) 100%)
}

.overlay.gradient.gradient-15:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .6) 25%, rgba(16, 20, 128, .6) 100%)
}

.overlay.gradient.gradient-16:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .6) 25%, rgba(244, 248, 251, .6) 100%)
}

.overlay.gradient.gradient-21:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .6) 25%, rgba(255, 255, 255, .6) 100%)
}

.overlay.gradient.gradient-23:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .6) 25%, rgba(159, 85, 255, .6) 100%)
}

.overlay.gradient.gradient-24:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .6) 25%, rgba(2, 143, 255, .6) 100%)
}

.overlay.gradient.gradient-25:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .6) 25%, rgba(16, 20, 128, .6) 100%)
}

.overlay.gradient.gradient-26:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .6) 25%, rgba(244, 248, 251, .6) 100%)
}

.overlay.gradient.gradient-31:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .6) 25%, rgba(255, 255, 255, .6) 100%)
}

.overlay.gradient.gradient-32:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .6) 25%, rgba(100, 111, 121, .6) 100%)
}

.overlay.gradient.gradient-34:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .6) 25%, rgba(2, 143, 255, .6) 100%)
}

.overlay.gradient.gradient-35:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .6) 25%, rgba(16, 20, 128, .6) 100%)
}

.overlay.gradient.gradient-36:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .6) 25%, rgba(244, 248, 251, .6) 100%)
}

.overlay.gradient.gradient-41:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .6) 25%, rgba(255, 255, 255, .6) 100%)
}

.overlay.gradient.gradient-42:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .6) 25%, rgba(100, 111, 121, .6) 100%)
}

.overlay.gradient.gradient-43:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .6) 25%, rgba(159, 85, 255, .6) 100%)
}

.overlay.gradient.gradient-45:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .6) 25%, rgba(16, 20, 128, .6) 100%)
}

.overlay.gradient.gradient-46:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .6) 25%, rgba(244, 248, 251, .6) 100%)
}

.overlay.gradient.gradient-51:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .6) 25%, rgba(255, 255, 255, .6) 100%)
}

.overlay.gradient.gradient-52:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .6) 25%, rgba(100, 111, 121, .6) 100%)
}

.overlay.gradient.gradient-53:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .6) 25%, rgba(159, 85, 255, .6) 100%)
}

.overlay.gradient.gradient-54:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .6) 25%, rgba(2, 143, 255, .6) 100%)
}

.overlay.gradient.gradient-56:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .6) 25%, rgba(244, 248, 251, .6) 100%)
}

.overlay.gradient.gradient-61:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .6) 25%, rgba(255, 255, 255, .6) 100%)
}

.overlay.gradient.gradient-62:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .6) 25%, rgba(100, 111, 121, .6) 100%)
}

.overlay.gradient.gradient-63:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .6) 25%, rgba(159, 85, 255, .6) 100%)
}

.overlay.gradient.gradient-64:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .6) 25%, rgba(2, 143, 255, .6) 100%)
}

.overlay.gradient.gradient-65:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .6) 25%, rgba(16, 20, 128, .6) 100%)
}

.overlay.gradient.alpha-1.gradient-12:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .1) 25%, rgba(100, 111, 121, .1) 100%)
}

.overlay.gradient.alpha-1.gradient-13:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .1) 25%, rgba(159, 85, 255, .1) 100%)
}

.overlay.gradient.alpha-1.gradient-14:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .1) 25%, rgba(2, 143, 255, .1) 100%)
}

.overlay.gradient.alpha-1.gradient-15:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .1) 25%, rgba(16, 20, 128, .1) 100%)
}

.overlay.gradient.alpha-1.gradient-16:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .1) 25%, rgba(244, 248, 251, .1) 100%)
}

.overlay.gradient.alpha-1.gradient-21:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .1) 25%, rgba(255, 255, 255, .1) 100%)
}

.overlay.gradient.alpha-1.gradient-23:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .1) 25%, rgba(159, 85, 255, .1) 100%)
}

.overlay.gradient.alpha-1.gradient-24:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .1) 25%, rgba(2, 143, 255, .1) 100%)
}

.overlay.gradient.alpha-1.gradient-25:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .1) 25%, rgba(16, 20, 128, .1) 100%)
}

.overlay.gradient.alpha-1.gradient-26:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .1) 25%, rgba(244, 248, 251, .1) 100%)
}

.overlay.gradient.alpha-1.gradient-31:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .1) 25%, rgba(255, 255, 255, .1) 100%)
}

.overlay.gradient.alpha-1.gradient-32:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .1) 25%, rgba(100, 111, 121, .1) 100%)
}

.overlay.gradient.alpha-1.gradient-34:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .1) 25%, rgba(2, 143, 255, .1) 100%)
}

.overlay.gradient.alpha-1.gradient-35:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .1) 25%, rgba(16, 20, 128, .1) 100%)
}

.overlay.gradient.alpha-1.gradient-36:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .1) 25%, rgba(244, 248, 251, .1) 100%)
}

.overlay.gradient.alpha-1.gradient-41:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .1) 25%, rgba(255, 255, 255, .1) 100%)
}

.overlay.gradient.alpha-1.gradient-42:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .1) 25%, rgba(100, 111, 121, .1) 100%)
}

.overlay.gradient.alpha-1.gradient-43:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .1) 25%, rgba(159, 85, 255, .1) 100%)
}

.overlay.gradient.alpha-1.gradient-45:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .1) 25%, rgba(16, 20, 128, .1) 100%)
}

.overlay.gradient.alpha-1.gradient-46:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .1) 25%, rgba(244, 248, 251, .1) 100%)
}

.overlay.gradient.alpha-1.gradient-51:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .1) 25%, rgba(255, 255, 255, .1) 100%)
}

.overlay.gradient.alpha-1.gradient-52:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .1) 25%, rgba(100, 111, 121, .1) 100%)
}

.overlay.gradient.alpha-1.gradient-53:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .1) 25%, rgba(159, 85, 255, .1) 100%)
}

.overlay.gradient.alpha-1.gradient-54:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .1) 25%, rgba(2, 143, 255, .1) 100%)
}

.overlay.gradient.alpha-1.gradient-56:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .1) 25%, rgba(244, 248, 251, .1) 100%)
}

.overlay.gradient.alpha-1.gradient-61:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .1) 25%, rgba(255, 255, 255, .1) 100%)
}

.overlay.gradient.alpha-1.gradient-62:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .1) 25%, rgba(100, 111, 121, .1) 100%)
}

.overlay.gradient.alpha-1.gradient-63:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .1) 25%, rgba(159, 85, 255, .1) 100%)
}

.overlay.gradient.alpha-1.gradient-64:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .1) 25%, rgba(2, 143, 255, .1) 100%)
}

.overlay.gradient.alpha-1.gradient-65:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .1) 25%, rgba(16, 20, 128, .1) 100%)
}

.overlay.gradient.alpha-2.gradient-12:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .2) 25%, rgba(100, 111, 121, .2) 100%)
}

.overlay.gradient.alpha-2.gradient-13:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .2) 25%, rgba(159, 85, 255, .2) 100%)
}

.overlay.gradient.alpha-2.gradient-14:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .2) 25%, rgba(2, 143, 255, .2) 100%)
}

.overlay.gradient.alpha-2.gradient-15:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .2) 25%, rgba(16, 20, 128, .2) 100%)
}

.overlay.gradient.alpha-2.gradient-16:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .2) 25%, rgba(244, 248, 251, .2) 100%)
}

.overlay.gradient.alpha-2.gradient-21:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .2) 25%, rgba(255, 255, 255, .2) 100%)
}

.overlay.gradient.alpha-2.gradient-23:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .2) 25%, rgba(159, 85, 255, .2) 100%)
}

.overlay.gradient.alpha-2.gradient-24:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .2) 25%, rgba(2, 143, 255, .2) 100%)
}

.overlay.gradient.alpha-2.gradient-25:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .2) 25%, rgba(16, 20, 128, .2) 100%)
}

.overlay.gradient.alpha-2.gradient-26:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .2) 25%, rgba(244, 248, 251, .2) 100%)
}

.overlay.gradient.alpha-2.gradient-31:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .2) 25%, rgba(255, 255, 255, .2) 100%)
}

.overlay.gradient.alpha-2.gradient-32:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .2) 25%, rgba(100, 111, 121, .2) 100%)
}

.overlay.gradient.alpha-2.gradient-34:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .2) 25%, rgba(2, 143, 255, .2) 100%)
}

.overlay.gradient.alpha-2.gradient-35:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .2) 25%, rgba(16, 20, 128, .2) 100%)
}

.overlay.gradient.alpha-2.gradient-36:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .2) 25%, rgba(244, 248, 251, .2) 100%)
}

.overlay.gradient.alpha-2.gradient-41:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .2) 25%, rgba(255, 255, 255, .2) 100%)
}

.overlay.gradient.alpha-2.gradient-42:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .2) 25%, rgba(100, 111, 121, .2) 100%)
}

.overlay.gradient.alpha-2.gradient-43:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .2) 25%, rgba(159, 85, 255, .2) 100%)
}

.overlay.gradient.alpha-2.gradient-45:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .2) 25%, rgba(16, 20, 128, .2) 100%)
}

.overlay.gradient.alpha-2.gradient-46:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .2) 25%, rgba(244, 248, 251, .2) 100%)
}

.overlay.gradient.alpha-2.gradient-51:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .2) 25%, rgba(255, 255, 255, .2) 100%)
}

.overlay.gradient.alpha-2.gradient-52:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .2) 25%, rgba(100, 111, 121, .2) 100%)
}

.overlay.gradient.alpha-2.gradient-53:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .2) 25%, rgba(159, 85, 255, .2) 100%)
}

.overlay.gradient.alpha-2.gradient-54:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .2) 25%, rgba(2, 143, 255, .2) 100%)
}

.overlay.gradient.alpha-2.gradient-56:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .2) 25%, rgba(244, 248, 251, .2) 100%)
}

.overlay.gradient.alpha-2.gradient-61:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .2) 25%, rgba(255, 255, 255, .2) 100%)
}

.overlay.gradient.alpha-2.gradient-62:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .2) 25%, rgba(100, 111, 121, .2) 100%)
}

.overlay.gradient.alpha-2.gradient-63:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .2) 25%, rgba(159, 85, 255, .2) 100%)
}

.overlay.gradient.alpha-2.gradient-64:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .2) 25%, rgba(2, 143, 255, .2) 100%)
}

.overlay.gradient.alpha-2.gradient-65:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .2) 25%, rgba(16, 20, 128, .2) 100%)
}

.overlay.gradient.alpha-3.gradient-12:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .3) 25%, rgba(100, 111, 121, .3) 100%)
}

.overlay.gradient.alpha-3.gradient-13:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .3) 25%, rgba(159, 85, 255, .3) 100%)
}

.overlay.gradient.alpha-3.gradient-14:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .3) 25%, rgba(2, 143, 255, .3) 100%)
}

.overlay.gradient.alpha-3.gradient-15:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .3) 25%, rgba(16, 20, 128, .3) 100%)
}

.overlay.gradient.alpha-3.gradient-16:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .3) 25%, rgba(244, 248, 251, .3) 100%)
}

.overlay.gradient.alpha-3.gradient-21:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .3) 25%, rgba(255, 255, 255, .3) 100%)
}

.overlay.gradient.alpha-3.gradient-23:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .3) 25%, rgba(159, 85, 255, .3) 100%)
}

.overlay.gradient.alpha-3.gradient-24:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .3) 25%, rgba(2, 143, 255, .3) 100%)
}

.overlay.gradient.alpha-3.gradient-25:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .3) 25%, rgba(16, 20, 128, .3) 100%)
}

.overlay.gradient.alpha-3.gradient-26:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .3) 25%, rgba(244, 248, 251, .3) 100%)
}

.overlay.gradient.alpha-3.gradient-31:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .3) 25%, rgba(255, 255, 255, .3) 100%)
}

.overlay.gradient.alpha-3.gradient-32:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .3) 25%, rgba(100, 111, 121, .3) 100%)
}

.overlay.gradient.alpha-3.gradient-34:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .3) 25%, rgba(2, 143, 255, .3) 100%)
}

.overlay.gradient.alpha-3.gradient-35:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .3) 25%, rgba(16, 20, 128, .3) 100%)
}

.overlay.gradient.alpha-3.gradient-36:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .3) 25%, rgba(244, 248, 251, .3) 100%)
}

.overlay.gradient.alpha-3.gradient-41:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .3) 25%, rgba(255, 255, 255, .3) 100%)
}

.overlay.gradient.alpha-3.gradient-42:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .3) 25%, rgba(100, 111, 121, .3) 100%)
}

.overlay.gradient.alpha-3.gradient-43:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .3) 25%, rgba(159, 85, 255, .3) 100%)
}

.overlay.gradient.alpha-3.gradient-45:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .3) 25%, rgba(16, 20, 128, .3) 100%)
}

.overlay.gradient.alpha-3.gradient-46:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .3) 25%, rgba(244, 248, 251, .3) 100%)
}

.overlay.gradient.alpha-3.gradient-51:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .3) 25%, rgba(255, 255, 255, .3) 100%)
}

.overlay.gradient.alpha-3.gradient-52:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .3) 25%, rgba(100, 111, 121, .3) 100%)
}

.overlay.gradient.alpha-3.gradient-53:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .3) 25%, rgba(159, 85, 255, .3) 100%)
}

.overlay.gradient.alpha-3.gradient-54:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .3) 25%, rgba(2, 143, 255, .3) 100%)
}

.overlay.gradient.alpha-3.gradient-56:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .3) 25%, rgba(244, 248, 251, .3) 100%)
}

.overlay.gradient.alpha-3.gradient-61:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .3) 25%, rgba(255, 255, 255, .3) 100%)
}

.overlay.gradient.alpha-3.gradient-62:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .3) 25%, rgba(100, 111, 121, .3) 100%)
}

.overlay.gradient.alpha-3.gradient-63:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .3) 25%, rgba(159, 85, 255, .3) 100%)
}

.overlay.gradient.alpha-3.gradient-64:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .3) 25%, rgba(2, 143, 255, .3) 100%)
}

.overlay.gradient.alpha-3.gradient-65:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .3) 25%, rgba(16, 20, 128, .3) 100%)
}

.overlay.gradient.alpha-4.gradient-12:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .4) 25%, rgba(100, 111, 121, .4) 100%)
}

.overlay.gradient.alpha-4.gradient-13:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .4) 25%, rgba(159, 85, 255, .4) 100%)
}

.overlay.gradient.alpha-4.gradient-14:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .4) 25%, rgba(2, 143, 255, .4) 100%)
}

.overlay.gradient.alpha-4.gradient-15:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .4) 25%, rgba(16, 20, 128, .4) 100%)
}

.overlay.gradient.alpha-4.gradient-16:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .4) 25%, rgba(244, 248, 251, .4) 100%)
}

.overlay.gradient.alpha-4.gradient-21:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .4) 25%, rgba(255, 255, 255, .4) 100%)
}

.overlay.gradient.alpha-4.gradient-23:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .4) 25%, rgba(159, 85, 255, .4) 100%)
}

.overlay.gradient.alpha-4.gradient-24:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .4) 25%, rgba(2, 143, 255, .4) 100%)
}

.overlay.gradient.alpha-4.gradient-25:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .4) 25%, rgba(16, 20, 128, .4) 100%)
}

.overlay.gradient.alpha-4.gradient-26:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .4) 25%, rgba(244, 248, 251, .4) 100%)
}

.overlay.gradient.alpha-4.gradient-31:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .4) 25%, rgba(255, 255, 255, .4) 100%)
}

.overlay.gradient.alpha-4.gradient-32:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .4) 25%, rgba(100, 111, 121, .4) 100%)
}

.overlay.gradient.alpha-4.gradient-34:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .4) 25%, rgba(2, 143, 255, .4) 100%)
}

.overlay.gradient.alpha-4.gradient-35:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .4) 25%, rgba(16, 20, 128, .4) 100%)
}

.overlay.gradient.alpha-4.gradient-36:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .4) 25%, rgba(244, 248, 251, .4) 100%)
}

.overlay.gradient.alpha-4.gradient-41:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .4) 25%, rgba(255, 255, 255, .4) 100%)
}

.overlay.gradient.alpha-4.gradient-42:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .4) 25%, rgba(100, 111, 121, .4) 100%)
}

.overlay.gradient.alpha-4.gradient-43:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .4) 25%, rgba(159, 85, 255, .4) 100%)
}

.overlay.gradient.alpha-4.gradient-45:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .4) 25%, rgba(16, 20, 128, .4) 100%)
}

.overlay.gradient.alpha-4.gradient-46:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .4) 25%, rgba(244, 248, 251, .4) 100%)
}

.overlay.gradient.alpha-4.gradient-51:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .4) 25%, rgba(255, 255, 255, .4) 100%)
}

.overlay.gradient.alpha-4.gradient-52:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .4) 25%, rgba(100, 111, 121, .4) 100%)
}

.overlay.gradient.alpha-4.gradient-53:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .4) 25%, rgba(159, 85, 255, .4) 100%)
}

.overlay.gradient.alpha-4.gradient-54:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .4) 25%, rgba(2, 143, 255, .4) 100%)
}

.overlay.gradient.alpha-4.gradient-56:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .4) 25%, rgba(244, 248, 251, .4) 100%)
}

.overlay.gradient.alpha-4.gradient-61:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .4) 25%, rgba(255, 255, 255, .4) 100%)
}

.overlay.gradient.alpha-4.gradient-62:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .4) 25%, rgba(100, 111, 121, .4) 100%)
}

.overlay.gradient.alpha-4.gradient-63:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .4) 25%, rgba(159, 85, 255, .4) 100%)
}

.overlay.gradient.alpha-4.gradient-64:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .4) 25%, rgba(2, 143, 255, .4) 100%)
}

.overlay.gradient.alpha-4.gradient-65:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .4) 25%, rgba(16, 20, 128, .4) 100%)
}

.overlay.gradient.alpha-5.gradient-12:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .5) 25%, rgba(100, 111, 121, .5) 100%)
}

.overlay.gradient.alpha-5.gradient-13:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .5) 25%, rgba(159, 85, 255, .5) 100%)
}

.overlay.gradient.alpha-5.gradient-14:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .5) 25%, rgba(2, 143, 255, .5) 100%)
}

.overlay.gradient.alpha-5.gradient-15:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .5) 25%, rgba(16, 20, 128, .5) 100%)
}

.overlay.gradient.alpha-5.gradient-16:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .5) 25%, rgba(244, 248, 251, .5) 100%)
}

.overlay.gradient.alpha-5.gradient-21:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .5) 25%, rgba(255, 255, 255, .5) 100%)
}

.overlay.gradient.alpha-5.gradient-23:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .5) 25%, rgba(159, 85, 255, .5) 100%)
}

.overlay.gradient.alpha-5.gradient-24:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .5) 25%, rgba(2, 143, 255, .5) 100%)
}

.overlay.gradient.alpha-5.gradient-25:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .5) 25%, rgba(16, 20, 128, .5) 100%)
}

.overlay.gradient.alpha-5.gradient-26:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .5) 25%, rgba(244, 248, 251, .5) 100%)
}

.overlay.gradient.alpha-5.gradient-31:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .5) 25%, rgba(255, 255, 255, .5) 100%)
}

.overlay.gradient.alpha-5.gradient-32:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .5) 25%, rgba(100, 111, 121, .5) 100%)
}

.overlay.gradient.alpha-5.gradient-34:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .5) 25%, rgba(2, 143, 255, .5) 100%)
}

.overlay.gradient.alpha-5.gradient-35:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .5) 25%, rgba(16, 20, 128, .5) 100%)
}

.overlay.gradient.alpha-5.gradient-36:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .5) 25%, rgba(244, 248, 251, .5) 100%)
}

.overlay.gradient.alpha-5.gradient-41:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .5) 25%, rgba(255, 255, 255, .5) 100%)
}

.overlay.gradient.alpha-5.gradient-42:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .5) 25%, rgba(100, 111, 121, .5) 100%)
}

.overlay.gradient.alpha-5.gradient-43:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .5) 25%, rgba(159, 85, 255, .5) 100%)
}

.overlay.gradient.alpha-5.gradient-45:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .5) 25%, rgba(16, 20, 128, .5) 100%)
}

.overlay.gradient.alpha-5.gradient-46:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .5) 25%, rgba(244, 248, 251, .5) 100%)
}

.overlay.gradient.alpha-5.gradient-51:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .5) 25%, rgba(255, 255, 255, .5) 100%)
}

.overlay.gradient.alpha-5.gradient-52:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .5) 25%, rgba(100, 111, 121, .5) 100%)
}

.overlay.gradient.alpha-5.gradient-53:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .5) 25%, rgba(159, 85, 255, .5) 100%)
}

.overlay.gradient.alpha-5.gradient-54:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .5) 25%, rgba(2, 143, 255, .5) 100%)
}

.overlay.gradient.alpha-5.gradient-56:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .5) 25%, rgba(244, 248, 251, .5) 100%)
}

.overlay.gradient.alpha-5.gradient-61:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .5) 25%, rgba(255, 255, 255, .5) 100%)
}

.overlay.gradient.alpha-5.gradient-62:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .5) 25%, rgba(100, 111, 121, .5) 100%)
}

.overlay.gradient.alpha-5.gradient-63:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .5) 25%, rgba(159, 85, 255, .5) 100%)
}

.overlay.gradient.alpha-5.gradient-64:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .5) 25%, rgba(2, 143, 255, .5) 100%)
}

.overlay.gradient.alpha-5.gradient-65:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .5) 25%, rgba(16, 20, 128, .5) 100%)
}

.overlay.gradient.alpha-6.gradient-12:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .6) 25%, rgba(100, 111, 121, .6) 100%)
}

.overlay.gradient.alpha-6.gradient-13:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .6) 25%, rgba(159, 85, 255, .6) 100%)
}

.overlay.gradient.alpha-6.gradient-14:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .6) 25%, rgba(2, 143, 255, .6) 100%)
}

.overlay.gradient.alpha-6.gradient-15:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .6) 25%, rgba(16, 20, 128, .6) 100%)
}

.overlay.gradient.alpha-6.gradient-16:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .6) 25%, rgba(244, 248, 251, .6) 100%)
}

.overlay.gradient.alpha-6.gradient-21:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .6) 25%, rgba(255, 255, 255, .6) 100%)
}

.overlay.gradient.alpha-6.gradient-23:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .6) 25%, rgba(159, 85, 255, .6) 100%)
}

.overlay.gradient.alpha-6.gradient-24:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .6) 25%, rgba(2, 143, 255, .6) 100%)
}

.overlay.gradient.alpha-6.gradient-25:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .6) 25%, rgba(16, 20, 128, .6) 100%)
}

.overlay.gradient.alpha-6.gradient-26:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .6) 25%, rgba(244, 248, 251, .6) 100%)
}

.overlay.gradient.alpha-6.gradient-31:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .6) 25%, rgba(255, 255, 255, .6) 100%)
}

.overlay.gradient.alpha-6.gradient-32:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .6) 25%, rgba(100, 111, 121, .6) 100%)
}

.overlay.gradient.alpha-6.gradient-34:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .6) 25%, rgba(2, 143, 255, .6) 100%)
}

.overlay.gradient.alpha-6.gradient-35:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .6) 25%, rgba(16, 20, 128, .6) 100%)
}

.overlay.gradient.alpha-6.gradient-36:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .6) 25%, rgba(244, 248, 251, .6) 100%)
}

.overlay.gradient.alpha-6.gradient-41:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .6) 25%, rgba(255, 255, 255, .6) 100%)
}

.overlay.gradient.alpha-6.gradient-42:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .6) 25%, rgba(100, 111, 121, .6) 100%)
}

.overlay.gradient.alpha-6.gradient-43:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .6) 25%, rgba(159, 85, 255, .6) 100%)
}

.overlay.gradient.alpha-6.gradient-45:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .6) 25%, rgba(16, 20, 128, .6) 100%)
}

.overlay.gradient.alpha-6.gradient-46:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .6) 25%, rgba(244, 248, 251, .6) 100%)
}

.overlay.gradient.alpha-6.gradient-51:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .6) 25%, rgba(255, 255, 255, .6) 100%)
}

.overlay.gradient.alpha-6.gradient-52:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .6) 25%, rgba(100, 111, 121, .6) 100%)
}

.overlay.gradient.alpha-6.gradient-53:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .6) 25%, rgba(159, 85, 255, .6) 100%)
}

.overlay.gradient.alpha-6.gradient-54:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .6) 25%, rgba(2, 143, 255, .6) 100%)
}

.overlay.gradient.alpha-6.gradient-56:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .6) 25%, rgba(244, 248, 251, .6) 100%)
}

.overlay.gradient.alpha-6.gradient-61:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .6) 25%, rgba(255, 255, 255, .6) 100%)
}

.overlay.gradient.alpha-6.gradient-62:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .6) 25%, rgba(100, 111, 121, .6) 100%)
}

.overlay.gradient.alpha-6.gradient-63:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .6) 25%, rgba(159, 85, 255, .6) 100%)
}

.overlay.gradient.alpha-6.gradient-64:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .6) 25%, rgba(2, 143, 255, .6) 100%)
}

.overlay.gradient.alpha-6.gradient-65:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .6) 25%, rgba(16, 20, 128, .6) 100%)
}

.overlay.gradient.alpha-7.gradient-12:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .7) 25%, rgba(100, 111, 121, .7) 100%)
}

.overlay.gradient.alpha-7.gradient-13:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .7) 25%, rgba(159, 85, 255, .7) 100%)
}

.overlay.gradient.alpha-7.gradient-14:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .7) 25%, rgba(2, 143, 255, .7) 100%)
}

.overlay.gradient.alpha-7.gradient-15:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .7) 25%, rgba(16, 20, 128, .7) 100%)
}

.overlay.gradient.alpha-7.gradient-16:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .7) 25%, rgba(244, 248, 251, .7) 100%)
}

.overlay.gradient.alpha-7.gradient-21:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .7) 25%, rgba(255, 255, 255, .7) 100%)
}

.overlay.gradient.alpha-7.gradient-23:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .7) 25%, rgba(159, 85, 255, .7) 100%)
}

.overlay.gradient.alpha-7.gradient-24:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .7) 25%, rgba(2, 143, 255, .7) 100%)
}

.overlay.gradient.alpha-7.gradient-25:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .7) 25%, rgba(16, 20, 128, .7) 100%)
}

.overlay.gradient.alpha-7.gradient-26:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .7) 25%, rgba(244, 248, 251, .7) 100%)
}

.overlay.gradient.alpha-7.gradient-31:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .7) 25%, rgba(255, 255, 255, .7) 100%)
}

.overlay.gradient.alpha-7.gradient-32:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .7) 25%, rgba(100, 111, 121, .7) 100%)
}

.overlay.gradient.alpha-7.gradient-34:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .7) 25%, rgba(2, 143, 255, .7) 100%)
}

.overlay.gradient.alpha-7.gradient-35:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .7) 25%, rgba(16, 20, 128, .7) 100%)
}

.overlay.gradient.alpha-7.gradient-36:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .7) 25%, rgba(244, 248, 251, .7) 100%)
}

.overlay.gradient.alpha-7.gradient-41:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .7) 25%, rgba(255, 255, 255, .7) 100%)
}

.overlay.gradient.alpha-7.gradient-42:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .7) 25%, rgba(100, 111, 121, .7) 100%)
}

.overlay.gradient.alpha-7.gradient-43:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .7) 25%, rgba(159, 85, 255, .7) 100%)
}

.overlay.gradient.alpha-7.gradient-45:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .7) 25%, rgba(16, 20, 128, .7) 100%)
}

.overlay.gradient.alpha-7.gradient-46:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .7) 25%, rgba(244, 248, 251, .7) 100%)
}

.overlay.gradient.alpha-7.gradient-51:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .7) 25%, rgba(255, 255, 255, .7) 100%)
}

.overlay.gradient.alpha-7.gradient-52:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .7) 25%, rgba(100, 111, 121, .7) 100%)
}

.overlay.gradient.alpha-7.gradient-53:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .7) 25%, rgba(159, 85, 255, .7) 100%)
}

.overlay.gradient.alpha-7.gradient-54:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .7) 25%, rgba(2, 143, 255, .7) 100%)
}

.overlay.gradient.alpha-7.gradient-56:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .7) 25%, rgba(244, 248, 251, .7) 100%)
}

.overlay.gradient.alpha-7.gradient-61:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .7) 25%, rgba(255, 255, 255, .7) 100%)
}

.overlay.gradient.alpha-7.gradient-62:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .7) 25%, rgba(100, 111, 121, .7) 100%)
}

.overlay.gradient.alpha-7.gradient-63:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .7) 25%, rgba(159, 85, 255, .7) 100%)
}

.overlay.gradient.alpha-7.gradient-64:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .7) 25%, rgba(2, 143, 255, .7) 100%)
}

.overlay.gradient.alpha-7.gradient-65:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .7) 25%, rgba(16, 20, 128, .7) 100%)
}

.overlay.gradient.alpha-8.gradient-12:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .8) 25%, rgba(100, 111, 121, .8) 100%)
}

.overlay.gradient.alpha-8.gradient-13:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .8) 25%, rgba(159, 85, 255, .8) 100%)
}

.overlay.gradient.alpha-8.gradient-14:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .8) 25%, rgba(2, 143, 255, .8) 100%)
}

.overlay.gradient.alpha-8.gradient-15:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .8) 25%, rgba(16, 20, 128, .8) 100%)
}

.overlay.gradient.alpha-8.gradient-16:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .8) 25%, rgba(244, 248, 251, .8) 100%)
}

.overlay.gradient.alpha-8.gradient-21:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .8) 25%, rgba(255, 255, 255, .8) 100%)
}

.overlay.gradient.alpha-8.gradient-23:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .8) 25%, rgba(159, 85, 255, .8) 100%)
}

.overlay.gradient.alpha-8.gradient-24:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .8) 25%, rgba(2, 143, 255, .8) 100%)
}

.overlay.gradient.alpha-8.gradient-25:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .8) 25%, rgba(16, 20, 128, .8) 100%)
}

.overlay.gradient.alpha-8.gradient-26:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .8) 25%, rgba(244, 248, 251, .8) 100%)
}

.overlay.gradient.alpha-8.gradient-31:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .8) 25%, rgba(255, 255, 255, .8) 100%)
}

.overlay.gradient.alpha-8.gradient-32:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .8) 25%, rgba(100, 111, 121, .8) 100%)
}

.overlay.gradient.alpha-8.gradient-34:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .8) 25%, rgba(2, 143, 255, .8) 100%)
}

.overlay.gradient.alpha-8.gradient-35:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .8) 25%, rgba(16, 20, 128, .8) 100%)
}

.overlay.gradient.alpha-8.gradient-36:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .8) 25%, rgba(244, 248, 251, .8) 100%)
}

.overlay.gradient.alpha-8.gradient-41:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .8) 25%, rgba(255, 255, 255, .8) 100%)
}

.overlay.gradient.alpha-8.gradient-42:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .8) 25%, rgba(100, 111, 121, .8) 100%)
}

.overlay.gradient.alpha-8.gradient-43:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .8) 25%, rgba(159, 85, 255, .8) 100%)
}

.overlay.gradient.alpha-8.gradient-45:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .8) 25%, rgba(16, 20, 128, .8) 100%)
}

.overlay.gradient.alpha-8.gradient-46:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .8) 25%, rgba(244, 248, 251, .8) 100%)
}

.overlay.gradient.alpha-8.gradient-51:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .8) 25%, rgba(255, 255, 255, .8) 100%)
}

.overlay.gradient.alpha-8.gradient-52:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .8) 25%, rgba(100, 111, 121, .8) 100%)
}

.overlay.gradient.alpha-8.gradient-53:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .8) 25%, rgba(159, 85, 255, .8) 100%)
}

.overlay.gradient.alpha-8.gradient-54:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .8) 25%, rgba(2, 143, 255, .8) 100%)
}

.overlay.gradient.alpha-8.gradient-56:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .8) 25%, rgba(244, 248, 251, .8) 100%)
}

.overlay.gradient.alpha-8.gradient-61:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .8) 25%, rgba(255, 255, 255, .8) 100%)
}

.overlay.gradient.alpha-8.gradient-62:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .8) 25%, rgba(100, 111, 121, .8) 100%)
}

.overlay.gradient.alpha-8.gradient-63:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .8) 25%, rgba(159, 85, 255, .8) 100%)
}

.overlay.gradient.alpha-8.gradient-64:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .8) 25%, rgba(2, 143, 255, .8) 100%)
}

.overlay.gradient.alpha-8.gradient-65:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .8) 25%, rgba(16, 20, 128, .8) 100%)
}

.overlay.gradient.alpha-9.gradient-12:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .9) 25%, rgba(100, 111, 121, .9) 100%)
}

.overlay.gradient.alpha-9.gradient-13:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .9) 25%, rgba(159, 85, 255, .9) 100%)
}

.overlay.gradient.alpha-9.gradient-14:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .9) 25%, rgba(2, 143, 255, .9) 100%)
}

.overlay.gradient.alpha-9.gradient-15:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .9) 25%, rgba(16, 20, 128, .9) 100%)
}

.overlay.gradient.alpha-9.gradient-16:after {
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, .9) 25%, rgba(244, 248, 251, .9) 100%)
}

.overlay.gradient.alpha-9.gradient-21:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .9) 25%, rgba(255, 255, 255, .9) 100%)
}

.overlay.gradient.alpha-9.gradient-23:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .9) 25%, rgba(159, 85, 255, .9) 100%)
}

.overlay.gradient.alpha-9.gradient-24:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .9) 25%, rgba(2, 143, 255, .9) 100%)
}

.overlay.gradient.alpha-9.gradient-25:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .9) 25%, rgba(16, 20, 128, .9) 100%)
}

.overlay.gradient.alpha-9.gradient-26:after {
  background-image: linear-gradient(-135deg, rgba(100, 111, 121, .9) 25%, rgba(244, 248, 251, .9) 100%)
}

.overlay.gradient.alpha-9.gradient-31:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .9) 25%, rgba(255, 255, 255, .9) 100%)
}

.overlay.gradient.alpha-9.gradient-32:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .9) 25%, rgba(100, 111, 121, .9) 100%)
}

.overlay.gradient.alpha-9.gradient-34:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .9) 25%, rgba(2, 143, 255, .9) 100%)
}

.overlay.gradient.alpha-9.gradient-35:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .9) 25%, rgba(16, 20, 128, .9) 100%)
}

.overlay.gradient.alpha-9.gradient-36:after {
  background-image: linear-gradient(-135deg, rgba(159, 85, 255, .9) 25%, rgba(244, 248, 251, .9) 100%)
}

.overlay.gradient.alpha-9.gradient-41:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .9) 25%, rgba(255, 255, 255, .9) 100%)
}

.overlay.gradient.alpha-9.gradient-42:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .9) 25%, rgba(100, 111, 121, .9) 100%)
}

.overlay.gradient.alpha-9.gradient-43:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .9) 25%, rgba(159, 85, 255, .9) 100%)
}

.overlay.gradient.alpha-9.gradient-45:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .9) 25%, rgba(16, 20, 128, .9) 100%)
}

.overlay.gradient.alpha-9.gradient-46:after {
  background-image: linear-gradient(-135deg, rgba(2, 143, 255, .9) 25%, rgba(244, 248, 251, .9) 100%)
}

.overlay.gradient.alpha-9.gradient-51:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .9) 25%, rgba(255, 255, 255, .9) 100%)
}

.overlay.gradient.alpha-9.gradient-52:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .9) 25%, rgba(100, 111, 121, .9) 100%)
}

.overlay.gradient.alpha-9.gradient-53:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .9) 25%, rgba(159, 85, 255, .9) 100%)
}

.overlay.gradient.alpha-9.gradient-54:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .9) 25%, rgba(2, 143, 255, .9) 100%)
}

.overlay.gradient.alpha-9.gradient-56:after {
  background-image: linear-gradient(-135deg, rgba(16, 20, 128, .9) 25%, rgba(244, 248, 251, .9) 100%)
}

.overlay.gradient.alpha-9.gradient-61:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .9) 25%, rgba(255, 255, 255, .9) 100%)
}

.overlay.gradient.alpha-9.gradient-62:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .9) 25%, rgba(100, 111, 121, .9) 100%)
}

.overlay.gradient.alpha-9.gradient-63:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .9) 25%, rgba(159, 85, 255, .9) 100%)
}

.overlay.gradient.alpha-9.gradient-64:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .9) 25%, rgba(2, 143, 255, .9) 100%)
}

.overlay.gradient.alpha-9.gradient-65:after {
  background-image: linear-gradient(-135deg, rgba(244, 248, 251, .9) 25%, rgba(16, 20, 128, .9) 100%)
}

.overlay.overlay-1:after {
  background-color: rgba(255, 255, 255, .6)
}

.overlay.overlay-1.alpha-1:after {
  background-color: rgba(255, 255, 255, .1)
}

.overlay.overlay-1.alpha-2:after {
  background-color: rgba(255, 255, 255, .2)
}

.overlay.overlay-1.alpha-3:after {
  background-color: rgba(255, 255, 255, .3)
}

.overlay.overlay-1.alpha-4:after {
  background-color: rgba(255, 255, 255, .4)
}

.overlay.overlay-1.alpha-5:after {
  background-color: rgba(255, 255, 255, .5)
}

.overlay.overlay-1.alpha-6:after {
  background-color: rgba(255, 255, 255, .6)
}

.overlay.overlay-1.alpha-7:after {
  background-color: rgba(255, 255, 255, .7)
}

.overlay.overlay-1.alpha-8:after {
  background-color: rgba(255, 255, 255, .8)
}

.overlay.overlay-1.alpha-9:after {
  background-color: rgba(255, 255, 255, .9)
}

.overlay.overlay-2:after {
  background-color: rgba(100, 111, 121, .6)
}

.overlay.overlay-2.alpha-1:after {
  background-color: rgba(100, 111, 121, .1)
}

.overlay.overlay-2.alpha-2:after {
  background-color: rgba(100, 111, 121, .2)
}

.overlay.overlay-2.alpha-3:after {
  background-color: rgba(100, 111, 121, .3)
}

.overlay.overlay-2.alpha-4:after {
  background-color: rgba(100, 111, 121, .4)
}

.overlay.overlay-2.alpha-5:after {
  background-color: rgba(100, 111, 121, .5)
}

.overlay.overlay-2.alpha-6:after {
  background-color: rgba(100, 111, 121, .6)
}

.overlay.overlay-2.alpha-7:after {
  background-color: rgba(100, 111, 121, .7)
}

.overlay.overlay-2.alpha-8:after {
  background-color: rgba(100, 111, 121, .8)
}

.overlay.overlay-2.alpha-9:after {
  background-color: rgba(100, 111, 121, .9)
}

.overlay.overlay-3:after {
  background-color: rgba(159, 85, 255, .6)
}

.overlay.overlay-3.alpha-1:after {
  background-color: rgba(159, 85, 255, .1)
}

.overlay.overlay-3.alpha-2:after {
  background-color: rgba(159, 85, 255, .2)
}

.overlay.overlay-3.alpha-3:after {
  background-color: rgba(159, 85, 255, .3)
}

.overlay.overlay-3.alpha-4:after {
  background-color: rgba(159, 85, 255, .4)
}

.overlay.overlay-3.alpha-5:after {
  background-color: rgba(159, 85, 255, .5)
}

.overlay.overlay-3.alpha-6:after {
  background-color: rgba(159, 85, 255, .6)
}

.overlay.overlay-3.alpha-7:after {
  background-color: rgba(159, 85, 255, .7)
}

.overlay.overlay-3.alpha-8:after {
  background-color: rgba(159, 85, 255, .8)
}

.overlay.overlay-3.alpha-9:after {
  background-color: rgba(159, 85, 255, .9)
}

.overlay.overlay-4:after {
  background-color: rgba(2, 143, 255, .6)
}

.overlay.overlay-4.alpha-1:after {
  background-color: rgba(2, 143, 255, .1)
}

.overlay.overlay-4.alpha-2:after {
  background-color: rgba(2, 143, 255, .2)
}

.overlay.overlay-4.alpha-3:after {
  background-color: rgba(2, 143, 255, .3)
}

.overlay.overlay-4.alpha-4:after {
  background-color: rgba(2, 143, 255, .4)
}

.overlay.overlay-4.alpha-5:after {
  background-color: rgba(2, 143, 255, .5)
}

.overlay.overlay-4.alpha-6:after {
  background-color: rgba(2, 143, 255, .6)
}

.overlay.overlay-4.alpha-7:after {
  background-color: rgba(2, 143, 255, .7)
}

.overlay.overlay-4.alpha-8:after {
  background-color: rgba(2, 143, 255, .8)
}

.overlay.overlay-4.alpha-9:after {
  background-color: rgba(2, 143, 255, .9)
}

.overlay.overlay-5:after {
  background-color: rgba(16, 20, 128, .6)
}

.overlay.overlay-5.alpha-1:after {
  background-color: rgba(16, 20, 128, .1)
}

.overlay.overlay-5.alpha-2:after {
  background-color: rgba(16, 20, 128, .2)
}

.overlay.overlay-5.alpha-3:after {
  background-color: rgba(16, 20, 128, .3)
}

.overlay.overlay-5.alpha-4:after {
  background-color: rgba(16, 20, 128, .4)
}

.overlay.overlay-5.alpha-5:after {
  background-color: rgba(16, 20, 128, .5)
}

.overlay.overlay-5.alpha-6:after {
  background-color: rgba(16, 20, 128, .6)
}

.overlay.overlay-5.alpha-7:after {
  background-color: rgba(16, 20, 128, .7)
}

.overlay.overlay-5.alpha-8:after {
  background-color: rgba(16, 20, 128, .8)
}

.overlay.overlay-5.alpha-9:after {
  background-color: rgba(16, 20, 128, .9)
}

.overlay.overlay-6:after {
  background-color: rgba(244, 248, 251, .6)
}

.overlay.overlay-6.alpha-1:after {
  background-color: rgba(244, 248, 251, .1)
}

.overlay.overlay-6.alpha-2:after {
  background-color: rgba(244, 248, 251, .2)
}

.overlay.overlay-6.alpha-3:after {
  background-color: rgba(244, 248, 251, .3)
}

.overlay.overlay-6.alpha-4:after {
  background-color: rgba(244, 248, 251, .4)
}

.overlay.overlay-6.alpha-5:after {
  background-color: rgba(244, 248, 251, .5)
}

.overlay.overlay-6.alpha-6:after {
  background-color: rgba(244, 248, 251, .6)
}

.overlay.overlay-6.alpha-7:after {
  background-color: rgba(244, 248, 251, .7)
}

.overlay.overlay-6.alpha-8:after {
  background-color: rgba(244, 248, 251, .8)
}

.overlay.overlay-6.alpha-9:after {
  background-color: rgba(244, 248, 251, .9)
}

.inner-overlay {
  position: absolute;
  top: -100px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -1
}

.inner-overlay.gradient {
  background-image: linear-gradient(-48deg, #b67eff 25%, #028fff 100%)
}

.relative {
  position: relative !important
}

.absolute {
  position: absolute !important
}

.center-x {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%)
}

.center-y {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.center-xy {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0)
}

@media (min-width: 576px) {
  .absolute-sm {
    position: absolute !important
  }
}

@media (min-width: 576px) {
  .center-x-sm {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
  }
}

@media (min-width: 576px) {
  .center-y-sm {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
  }
}

@media (min-width: 576px) {
  .center-xy-sm {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0)
  }
}

@media (min-width: 768px) {
  .absolute-md {
    position: absolute !important
  }
}

@media (min-width: 768px) {
  .center-x-md {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
  }
}

@media (min-width: 768px) {
  .center-y-md {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
  }
}

@media (min-width: 768px) {
  .center-xy-md {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0)
  }
}

@media (min-width: 992px) {
  .absolute-lg {
    position: absolute !important
  }
}

@media (min-width: 992px) {
  .center-x-lg {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
  }
}

@media (min-width: 992px) {
  .center-y-lg {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
  }
}

@media (min-width: 992px) {
  .center-xy-lg {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0)
  }
}

@media (min-width: 1200px) {
  .absolute-xl {
    position: absolute !important
  }
}

@media (min-width: 1200px) {
  .center-x-xl {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
  }
}

@media (min-width: 1200px) {
  .center-y-xl {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
  }
}

@media (min-width: 1200px) {
  .center-xy-xl {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0)
  }
}

.top {
  top: 0 !important
}

.right {
  right: 0 !important
}

.bottom {
  bottom: 0 !important
}

.left {
  left: 0 !important
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto
}

.row.gap-y {
  margin-top: -15px;
  margin-bottom: -15px
}

.row.gap-y > .col, .row.gap-y > [class*=col-] {
  padding-top: 15px;
  padding-bottom: 15px
}

.row.gap-2x {
  margin: -30px;
  margin: -30px
}

.row.gap-2x > .col, .row.gap-2x > [class*=col-] {
  padding: 30px;
  padding: 30px
}

.shape-wrapper {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0
}

.main-shape-wrapper {
  position: relative
}

.shape-center, .shape-left, .shape-right, .shape-top {
  position: absolute;
  max-width: 560px
}

.transparentize {
  opacity: .035
}

.shape-left {
  left: 0;
  top: 200px
}

.shape-left.shape-rounded {
  top: 0;
  -webkit-transform: translate3d(-15%, -1%, 0) rotate(28deg) scale(1.4);
  transform: translate3d(-15%, -1%, 0) rotate(28deg) scale(1.4)
}

.shape-right {
  right: 0;
  left: 51vw;
  top: 90px
}

.shape-right.w-50 {
  max-width: 50%;
  left: 47vw
}

.shape-center {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%)
}

.shape-ring {
  border-radius: 50%;
  height: 920px;
  width: 920px;
  border: 100px solid #f4f8fb;
  max-width: none;
  margin-right: -25%
}

@media (min-width: 1200px) {
  .shape-ring {
    margin-right: 0
  }
}

.shape-fill {
  fill: #000
}

.shape-fill.shape-fill-1 {
  fill: #fff
}

.shape-fill.shape-fill-2 {
  fill: #646f79
}

.shape-fill.shape-fill-3 {
  fill: #9f55ff
}

.shape-fill.shape-fill-4 {
  fill: #028fff
}

.shape-fill.shape-fill-5 {
  fill: #101480
}

.shape-fill.shape-fill-6 {
  fill: #f4f8fb
}

.shape-stroke {
  fill: none;
  stroke: #6146d7;
  stroke-width: 2px
}

.shape-stroke.shape-stroke-1 {
  stroke: #e6e6e6
}

.shape-stroke.shape-stroke-2 {
  stroke: #4d555d
}

.shape-stroke.shape-stroke-3 {
  stroke: #8222ff
}

.shape-stroke.shape-stroke-4 {
  stroke: #0073ce
}

.shape-stroke.shape-stroke-5 {
  stroke: #0a0d53
}

.shape-stroke.shape-stroke-6 {
  stroke: #cfe0ed
}

.divider-shape svg.shape-waves {
  height: 100px
}

@media (min-width: 768px) {
  .divider-shape svg.shape-waves {
    height: 133px
  }
}

.shape-background {
  background-color: #f9fbfd;
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 120px;
  z-index: -1
}

.shape-background.shape-right {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 0;
  left: 180%
}

@media (min-width: 768px) {
  .shape-background.shape-right {
    left: 120%
  }
}

@media (min-width: 992px) {
  .shape-background.shape-right {
    left: 88%
  }
}

.shape-background.shape-left {
  -webkit-transform: rotate(-43deg);
  transform: rotate(-43deg);
  left: -669px;
  max-width: 750px;
  top: -182px
}

.m-6 {
  margin: 6rem !important
}

.mt-6 {
  margin-top: 6rem !important
}

.mr-6 {
  margin-right: 6rem !important
}

.mb-6 {
  margin-bottom: 6rem !important
}

.ml-6 {
  margin-left: 6rem !important
}

.mx-6 {
  margin-right: 6rem !important;
  margin-left: 6rem !important
}

.my-6 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important
}

.m-7 {
  margin: 7rem !important
}

.mt-7 {
  margin-top: 7rem !important
}

.mr-7 {
  margin-right: 7rem !important
}

.mb-7 {
  margin-bottom: 7rem !important
}

.ml-7 {
  margin-left: 7rem !important
}

.mx-7 {
  margin-right: 7rem !important;
  margin-left: 7rem !important
}

.my-7 {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important
}

.m-8 {
  margin: 8rem !important
}

.mt-8 {
  margin-top: 8rem !important
}

.mr-8 {
  margin-right: 8rem !important
}

.mb-8 {
  margin-bottom: 8rem !important
}

.ml-8 {
  margin-left: 8rem !important
}

.mx-8 {
  margin-right: 8rem !important;
  margin-left: 8rem !important
}

.my-8 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important
}

.m-9 {
  margin: 9rem !important
}

.mt-9 {
  margin-top: 9rem !important
}

.mr-9 {
  margin-right: 9rem !important
}

.mb-9 {
  margin-bottom: 9rem !important
}

.ml-9 {
  margin-left: 9rem !important
}

.mx-9 {
  margin-right: 9rem !important;
  margin-left: 9rem !important
}

.my-9 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important
}

.p-6 {
  padding: 6rem !important
}

.pt-6 {
  padding-top: 6rem !important
}

.pr-6 {
  padding-right: 6rem !important
}

.pb-6 {
  padding-bottom: 6rem !important
}

.pl-6 {
  padding-left: 6rem !important
}

.px-6 {
  padding-right: 6rem !important;
  padding-left: 6rem !important
}

.py-6 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important
}

.p-7 {
  padding: 7rem !important
}

.pt-7 {
  padding-top: 7rem !important
}

.pr-7 {
  padding-right: 7rem !important
}

.pb-7 {
  padding-bottom: 7rem !important
}

.pl-7 {
  padding-left: 7rem !important
}

.px-7 {
  padding-right: 7rem !important;
  padding-left: 7rem !important
}

.py-7 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important
}

.p-8 {
  padding: 8rem !important
}

.pt-8 {
  padding-top: 8rem !important
}

.pr-8 {
  padding-right: 8rem !important
}

.pb-8 {
  padding-bottom: 8rem !important
}

.pl-8 {
  padding-left: 8rem !important
}

.px-8 {
  padding-right: 8rem !important;
  padding-left: 8rem !important
}

.py-8 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important
}

.p-9 {
  padding: 9rem !important
}

.pt-9 {
  padding-top: 9rem !important
}

.pr-9 {
  padding-right: 9rem !important
}

.pb-9 {
  padding-bottom: 9rem !important
}

.pl-9 {
  padding-left: 9rem !important
}

.px-9 {
  padding-right: 9rem !important;
  padding-left: 9rem !important
}

.py-9 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important
}

@media (min-width: 576px) {
  .m-sm-6 {
    margin: 6rem !important
  }
  .mt-sm-6 {
    margin-top: 6rem !important
  }
  .mr-sm-6 {
    margin-right: 6rem !important
  }
  .mb-sm-6 {
    margin-bottom: 6rem !important
  }
  .ml-sm-6 {
    margin-left: 6rem !important
  }
  .mx-sm-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important
  }
  .my-sm-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important
  }
  .m-sm-7 {
    margin: 7rem !important
  }
  .mt-sm-7 {
    margin-top: 7rem !important
  }
  .mr-sm-7 {
    margin-right: 7rem !important
  }
  .mb-sm-7 {
    margin-bottom: 7rem !important
  }
  .ml-sm-7 {
    margin-left: 7rem !important
  }
  .mx-sm-7 {
    margin-right: 7rem !important;
    margin-left: 7rem !important
  }
  .my-sm-7 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important
  }
  .m-sm-8 {
    margin: 8rem !important
  }
  .mt-sm-8 {
    margin-top: 8rem !important
  }
  .mr-sm-8 {
    margin-right: 8rem !important
  }
  .mb-sm-8 {
    margin-bottom: 8rem !important
  }
  .ml-sm-8 {
    margin-left: 8rem !important
  }
  .mx-sm-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important
  }
  .my-sm-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important
  }
  .m-sm-9 {
    margin: 9rem !important
  }
  .mt-sm-9 {
    margin-top: 9rem !important
  }
  .mr-sm-9 {
    margin-right: 9rem !important
  }
  .mb-sm-9 {
    margin-bottom: 9rem !important
  }
  .ml-sm-9 {
    margin-left: 9rem !important
  }
  .mx-sm-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important
  }
  .my-sm-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important
  }
  .p-sm-6 {
    padding: 6rem !important
  }
  .pt-sm-6 {
    padding-top: 6rem !important
  }
  .pr-sm-6 {
    padding-right: 6rem !important
  }
  .pb-sm-6 {
    padding-bottom: 6rem !important
  }
  .pl-sm-6 {
    padding-left: 6rem !important
  }
  .px-sm-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important
  }
  .py-sm-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important
  }
  .p-sm-7 {
    padding: 7rem !important
  }
  .pt-sm-7 {
    padding-top: 7rem !important
  }
  .pr-sm-7 {
    padding-right: 7rem !important
  }
  .pb-sm-7 {
    padding-bottom: 7rem !important
  }
  .pl-sm-7 {
    padding-left: 7rem !important
  }
  .px-sm-7 {
    padding-right: 7rem !important;
    padding-left: 7rem !important
  }
  .py-sm-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important
  }
  .p-sm-8 {
    padding: 8rem !important
  }
  .pt-sm-8 {
    padding-top: 8rem !important
  }
  .pr-sm-8 {
    padding-right: 8rem !important
  }
  .pb-sm-8 {
    padding-bottom: 8rem !important
  }
  .pl-sm-8 {
    padding-left: 8rem !important
  }
  .px-sm-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important
  }
  .py-sm-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important
  }
  .p-sm-9 {
    padding: 9rem !important
  }
  .pt-sm-9 {
    padding-top: 9rem !important
  }
  .pr-sm-9 {
    padding-right: 9rem !important
  }
  .pb-sm-9 {
    padding-bottom: 9rem !important
  }
  .pl-sm-9 {
    padding-left: 9rem !important
  }
  .px-sm-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important
  }
  .py-sm-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important
  }
}

@media (min-width: 768px) {
  .m-md-6 {
    margin: 6rem !important
  }
  .mt-md-6 {
    margin-top: 6rem !important
  }
  .mr-md-6 {
    margin-right: 6rem !important
  }
  .mb-md-6 {
    margin-bottom: 6rem !important
  }
  .ml-md-6 {
    margin-left: 6rem !important
  }
  .mx-md-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important
  }
  .my-md-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important
  }
  .m-md-7 {
    margin: 7rem !important
  }
  .mt-md-7 {
    margin-top: 7rem !important
  }
  .mr-md-7 {
    margin-right: 7rem !important
  }
  .mb-md-7 {
    margin-bottom: 7rem !important
  }
  .ml-md-7 {
    margin-left: 7rem !important
  }
  .mx-md-7 {
    margin-right: 7rem !important;
    margin-left: 7rem !important
  }
  .my-md-7 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important
  }
  .m-md-8 {
    margin: 8rem !important
  }
  .mt-md-8 {
    margin-top: 8rem !important
  }
  .mr-md-8 {
    margin-right: 8rem !important
  }
  .mb-md-8 {
    margin-bottom: 8rem !important
  }
  .ml-md-8 {
    margin-left: 8rem !important
  }
  .mx-md-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important
  }
  .my-md-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important
  }
  .m-md-9 {
    margin: 9rem !important
  }
  .mt-md-9 {
    margin-top: 9rem !important
  }
  .mr-md-9 {
    margin-right: 9rem !important
  }
  .mb-md-9 {
    margin-bottom: 9rem !important
  }
  .ml-md-9 {
    margin-left: 9rem !important
  }
  .mx-md-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important
  }
  .my-md-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important
  }
  .p-md-6 {
    padding: 6rem !important
  }
  .pt-md-6 {
    padding-top: 6rem !important
  }
  .pr-md-6 {
    padding-right: 6rem !important
  }
  .pb-md-6 {
    padding-bottom: 6rem !important
  }
  .pl-md-6 {
    padding-left: 6rem !important
  }
  .px-md-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important
  }
  .py-md-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important
  }
  .p-md-7 {
    padding: 7rem !important
  }
  .pt-md-7 {
    padding-top: 7rem !important
  }
  .pr-md-7 {
    padding-right: 7rem !important
  }
  .pb-md-7 {
    padding-bottom: 7rem !important
  }
  .pl-md-7 {
    padding-left: 7rem !important
  }
  .px-md-7 {
    padding-right: 7rem !important;
    padding-left: 7rem !important
  }
  .py-md-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important
  }
  .p-md-8 {
    padding: 8rem !important
  }
  .pt-md-8 {
    padding-top: 8rem !important
  }
  .pr-md-8 {
    padding-right: 8rem !important
  }
  .pb-md-8 {
    padding-bottom: 8rem !important
  }
  .pl-md-8 {
    padding-left: 8rem !important
  }
  .px-md-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important
  }
  .py-md-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important
  }
  .p-md-9 {
    padding: 9rem !important
  }
  .pt-md-9 {
    padding-top: 9rem !important
  }
  .pr-md-9 {
    padding-right: 9rem !important
  }
  .pb-md-9 {
    padding-bottom: 9rem !important
  }
  .pl-md-9 {
    padding-left: 9rem !important
  }
  .px-md-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important
  }
  .py-md-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important
  }
}

@media (min-width: 992px) {
  .m-lg-6 {
    margin: 6rem !important
  }
  .mt-lg-6 {
    margin-top: 6rem !important
  }
  .mr-lg-6 {
    margin-right: 6rem !important
  }
  .mb-lg-6 {
    margin-bottom: 6rem !important
  }
  .ml-lg-6 {
    margin-left: 6rem !important
  }
  .mx-lg-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important
  }
  .my-lg-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important
  }
  .m-lg-7 {
    margin: 7rem !important
  }
  .mt-lg-7 {
    margin-top: 7rem !important
  }
  .mr-lg-7 {
    margin-right: 7rem !important
  }
  .mb-lg-7 {
    margin-bottom: 7rem !important
  }
  .ml-lg-7 {
    margin-left: 7rem !important
  }
  .mx-lg-7 {
    margin-right: 7rem !important;
    margin-left: 7rem !important
  }
  .my-lg-7 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important
  }
  .m-lg-8 {
    margin: 8rem !important
  }
  .mt-lg-8 {
    margin-top: 8rem !important
  }
  .mr-lg-8 {
    margin-right: 8rem !important
  }
  .mb-lg-8 {
    margin-bottom: 8rem !important
  }
  .ml-lg-8 {
    margin-left: 8rem !important
  }
  .mx-lg-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important
  }
  .my-lg-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important
  }
  .m-lg-9 {
    margin: 9rem !important
  }
  .mt-lg-9 {
    margin-top: 9rem !important
  }
  .mr-lg-9 {
    margin-right: 9rem !important
  }
  .mb-lg-9 {
    margin-bottom: 9rem !important
  }
  .ml-lg-9 {
    margin-left: 9rem !important
  }
  .mx-lg-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important
  }
  .my-lg-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important
  }
  .p-lg-6 {
    padding: 6rem !important
  }
  .pt-lg-6 {
    padding-top: 6rem !important
  }
  .pr-lg-6 {
    padding-right: 6rem !important
  }
  .pb-lg-6 {
    padding-bottom: 6rem !important
  }
  .pl-lg-6 {
    padding-left: 6rem !important
  }
  .px-lg-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important
  }
  .py-lg-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important
  }
  .p-lg-7 {
    padding: 7rem !important
  }
  .pt-lg-7 {
    padding-top: 7rem !important
  }
  .pr-lg-7 {
    padding-right: 7rem !important
  }
  .pb-lg-7 {
    padding-bottom: 7rem !important
  }
  .pl-lg-7 {
    padding-left: 7rem !important
  }
  .px-lg-7 {
    padding-right: 7rem !important;
    padding-left: 7rem !important
  }
  .py-lg-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important
  }
  .p-lg-8 {
    padding: 8rem !important
  }
  .pt-lg-8 {
    padding-top: 8rem !important
  }
  .pr-lg-8 {
    padding-right: 8rem !important
  }
  .pb-lg-8 {
    padding-bottom: 8rem !important
  }
  .pl-lg-8 {
    padding-left: 8rem !important
  }
  .px-lg-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important
  }
  .py-lg-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important
  }
  .p-lg-9 {
    padding: 9rem !important
  }
  .pt-lg-9 {
    padding-top: 9rem !important
  }
  .pr-lg-9 {
    padding-right: 9rem !important
  }
  .pb-lg-9 {
    padding-bottom: 9rem !important
  }
  .pl-lg-9 {
    padding-left: 9rem !important
  }
  .px-lg-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important
  }
  .py-lg-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important
  }
}

@media (min-width: 1200px) {
  .m-xl-6 {
    margin: 6rem !important
  }
  .mt-xl-6 {
    margin-top: 6rem !important
  }
  .mr-xl-6 {
    margin-right: 6rem !important
  }
  .mb-xl-6 {
    margin-bottom: 6rem !important
  }
  .ml-xl-6 {
    margin-left: 6rem !important
  }
  .mx-xl-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important
  }
  .my-xl-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important
  }
  .m-xl-7 {
    margin: 7rem !important
  }
  .mt-xl-7 {
    margin-top: 7rem !important
  }
  .mr-xl-7 {
    margin-right: 7rem !important
  }
  .mb-xl-7 {
    margin-bottom: 7rem !important
  }
  .ml-xl-7 {
    margin-left: 7rem !important
  }
  .mx-xl-7 {
    margin-right: 7rem !important;
    margin-left: 7rem !important
  }
  .my-xl-7 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important
  }
  .m-xl-8 {
    margin: 8rem !important
  }
  .mt-xl-8 {
    margin-top: 8rem !important
  }
  .mr-xl-8 {
    margin-right: 8rem !important
  }
  .mb-xl-8 {
    margin-bottom: 8rem !important
  }
  .ml-xl-8 {
    margin-left: 8rem !important
  }
  .mx-xl-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important
  }
  .my-xl-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important
  }
  .m-xl-9 {
    margin: 9rem !important
  }
  .mt-xl-9 {
    margin-top: 9rem !important
  }
  .mr-xl-9 {
    margin-right: 9rem !important
  }
  .mb-xl-9 {
    margin-bottom: 9rem !important
  }
  .ml-xl-9 {
    margin-left: 9rem !important
  }
  .mx-xl-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important
  }
  .my-xl-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important
  }
  .p-xl-6 {
    padding: 6rem !important
  }
  .pt-xl-6 {
    padding-top: 6rem !important
  }
  .pr-xl-6 {
    padding-right: 6rem !important
  }
  .pb-xl-6 {
    padding-bottom: 6rem !important
  }
  .pl-xl-6 {
    padding-left: 6rem !important
  }
  .px-xl-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important
  }
  .py-xl-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important
  }
  .p-xl-7 {
    padding: 7rem !important
  }
  .pt-xl-7 {
    padding-top: 7rem !important
  }
  .pr-xl-7 {
    padding-right: 7rem !important
  }
  .pb-xl-7 {
    padding-bottom: 7rem !important
  }
  .pl-xl-7 {
    padding-left: 7rem !important
  }
  .px-xl-7 {
    padding-right: 7rem !important;
    padding-left: 7rem !important
  }
  .py-xl-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important
  }
  .p-xl-8 {
    padding: 8rem !important
  }
  .pt-xl-8 {
    padding-top: 8rem !important
  }
  .pr-xl-8 {
    padding-right: 8rem !important
  }
  .pb-xl-8 {
    padding-bottom: 8rem !important
  }
  .pl-xl-8 {
    padding-left: 8rem !important
  }
  .px-xl-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important
  }
  .py-xl-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important
  }
  .p-xl-9 {
    padding: 9rem !important
  }
  .pt-xl-9 {
    padding-top: 9rem !important
  }
  .pr-xl-9 {
    padding-right: 9rem !important
  }
  .pb-xl-9 {
    padding-bottom: 9rem !important
  }
  .pl-xl-9 {
    padding-left: 9rem !important
  }
  .px-xl-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important
  }
  .py-xl-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important
  }
}

.mt-0n {
  margin-top: 0 !important
}

.mb-0n {
  margin-bottom: 0 !important
}

.mt-1n {
  margin-top: -.25rem !important
}

.mb-1n {
  margin-bottom: -.25rem !important
}

.mt-2n {
  margin-top: -.5rem !important
}

.mb-2n {
  margin-bottom: -.5rem !important
}

.mt-3n {
  margin-top: -1rem !important
}

.mb-3n {
  margin-bottom: -1rem !important
}

.mt-4n {
  margin-top: -1.5rem !important
}

.mb-4n {
  margin-bottom: -1.5rem !important
}

.mt-5n {
  margin-top: -3rem !important
}

.mb-5n {
  margin-bottom: -3rem !important
}

.mt-6n {
  margin-top: -6rem !important
}

.mb-6n {
  margin-bottom: -6rem !important
}

.mt-7n {
  margin-top: -7rem !important
}

.mb-7n {
  margin-bottom: -7rem !important
}

.mt-8n {
  margin-top: -8rem !important
}

.mb-8n {
  margin-bottom: -8rem !important
}

.mt-9n {
  margin-top: -9rem !important
}

.mb-9n {
  margin-bottom: -9rem !important
}

.pt-0n {
  padding-top: 0 !important
}

.pb-0n {
  padding-bottom: 0 !important
}

@media (min-width: 576px) {
  .mt-sm-0n {
    margin-top: 0 !important
  }
  .mb-sm-0n {
    margin-bottom: 0 !important
  }
  .mt-sm-1n {
    margin-top: -.25rem !important
  }
  .mb-sm-1n {
    margin-bottom: -.25rem !important
  }
  .mt-sm-2n {
    margin-top: -.5rem !important
  }
  .mb-sm-2n {
    margin-bottom: -.5rem !important
  }
  .mt-sm-3n {
    margin-top: -1rem !important
  }
  .mb-sm-3n {
    margin-bottom: -1rem !important
  }
  .mt-sm-4n {
    margin-top: -1.5rem !important
  }
  .mb-sm-4n {
    margin-bottom: -1.5rem !important
  }
  .mt-sm-5n {
    margin-top: -3rem !important
  }
  .mb-sm-5n {
    margin-bottom: -3rem !important
  }
  .mt-sm-6n {
    margin-top: -6rem !important
  }
  .mb-sm-6n {
    margin-bottom: -6rem !important
  }
  .mt-sm-7n {
    margin-top: -7rem !important
  }
  .mb-sm-7n {
    margin-bottom: -7rem !important
  }
  .mt-sm-8n {
    margin-top: -8rem !important
  }
  .mb-sm-8n {
    margin-bottom: -8rem !important
  }
  .mt-sm-9n {
    margin-top: -9rem !important
  }
  .mb-sm-9n {
    margin-bottom: -9rem !important
  }
  .pt-sm-0n {
    padding-top: 0 !important
  }
  .pb-sm-0n {
    padding-bottom: 0 !important
  }
}

@media (min-width: 768px) {
  .mt-md-0n {
    margin-top: 0 !important
  }
  .mb-md-0n {
    margin-bottom: 0 !important
  }
  .mt-md-1n {
    margin-top: -.25rem !important
  }
  .mb-md-1n {
    margin-bottom: -.25rem !important
  }
  .mt-md-2n {
    margin-top: -.5rem !important
  }
  .mb-md-2n {
    margin-bottom: -.5rem !important
  }
  .mt-md-3n {
    margin-top: -1rem !important
  }
  .mb-md-3n {
    margin-bottom: -1rem !important
  }
  .mt-md-4n {
    margin-top: -1.5rem !important
  }
  .mb-md-4n {
    margin-bottom: -1.5rem !important
  }
  .mt-md-5n {
    margin-top: -3rem !important
  }
  .mb-md-5n {
    margin-bottom: -3rem !important
  }
  .mt-md-6n {
    margin-top: -6rem !important
  }
  .mb-md-6n {
    margin-bottom: -6rem !important
  }
  .mt-md-7n {
    margin-top: -7rem !important
  }
  .mb-md-7n {
    margin-bottom: -7rem !important
  }
  .mt-md-8n {
    margin-top: -8rem !important
  }
  .mb-md-8n {
    margin-bottom: -8rem !important
  }
  .mt-md-9n {
    margin-top: -9rem !important
  }
  .mb-md-9n {
    margin-bottom: -9rem !important
  }
  .pt-md-0n {
    padding-top: 0 !important
  }
  .pb-md-0n {
    padding-bottom: 0 !important
  }
}

@media (min-width: 992px) {
  .mt-lg-0n {
    margin-top: 0 !important
  }
  .mb-lg-0n {
    margin-bottom: 0 !important
  }
  .mt-lg-1n {
    margin-top: -.25rem !important
  }
  .mb-lg-1n {
    margin-bottom: -.25rem !important
  }
  .mt-lg-2n {
    margin-top: -.5rem !important
  }
  .mb-lg-2n {
    margin-bottom: -.5rem !important
  }
  .mt-lg-3n {
    margin-top: -1rem !important
  }
  .mb-lg-3n {
    margin-bottom: -1rem !important
  }
  .mt-lg-4n {
    margin-top: -1.5rem !important
  }
  .mb-lg-4n {
    margin-bottom: -1.5rem !important
  }
  .mt-lg-5n {
    margin-top: -3rem !important
  }
  .mb-lg-5n {
    margin-bottom: -3rem !important
  }
  .mt-lg-6n {
    margin-top: -6rem !important
  }
  .mb-lg-6n {
    margin-bottom: -6rem !important
  }
  .mt-lg-7n {
    margin-top: -7rem !important
  }
  .mb-lg-7n {
    margin-bottom: -7rem !important
  }
  .mt-lg-8n {
    margin-top: -8rem !important
  }
  .mb-lg-8n {
    margin-bottom: -8rem !important
  }
  .mt-lg-9n {
    margin-top: -9rem !important
  }
  .mb-lg-9n {
    margin-bottom: -9rem !important
  }
  .pt-lg-0n {
    padding-top: 0 !important
  }
  .pb-lg-0n {
    padding-bottom: 0 !important
  }
}

@media (min-width: 1200px) {
  .mt-xl-0n {
    margin-top: 0 !important
  }
  .mb-xl-0n {
    margin-bottom: 0 !important
  }
  .mt-xl-1n {
    margin-top: -.25rem !important
  }
  .mb-xl-1n {
    margin-bottom: -.25rem !important
  }
  .mt-xl-2n {
    margin-top: -.5rem !important
  }
  .mb-xl-2n {
    margin-bottom: -.5rem !important
  }
  .mt-xl-3n {
    margin-top: -1rem !important
  }
  .mb-xl-3n {
    margin-bottom: -1rem !important
  }
  .mt-xl-4n {
    margin-top: -1.5rem !important
  }
  .mb-xl-4n {
    margin-bottom: -1.5rem !important
  }
  .mt-xl-5n {
    margin-top: -3rem !important
  }
  .mb-xl-5n {
    margin-bottom: -3rem !important
  }
  .mt-xl-6n {
    margin-top: -6rem !important
  }
  .mb-xl-6n {
    margin-bottom: -6rem !important
  }
  .mt-xl-7n {
    margin-top: -7rem !important
  }
  .mb-xl-7n {
    margin-bottom: -7rem !important
  }
  .mt-xl-8n {
    margin-top: -8rem !important
  }
  .mb-xl-8n {
    margin-bottom: -8rem !important
  }
  .mt-xl-9n {
    margin-top: -9rem !important
  }
  .mb-xl-9n {
    margin-bottom: -9rem !important
  }
  .pt-xl-0n {
    padding-top: 0 !important
  }
  .pb-xl-0n {
    padding-bottom: 0 !important
  }
}

.speech-bubble {
  position: relative;
  background: #9f55ff;
  border-radius: .4em;
  padding: .25rem .5rem
}

.speech-bubble:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: #9f55ff;
  border-bottom: 0;
  margin-left: -10px;
  margin-bottom: -10px
}

.speech-bubble:after {
  content: '';
  width: 6px;
  height: 6px;
  -webkit-animation: pulse .5s linear infinite alternate;
  animation: pulse .5s linear infinite alternate;
  background-color: #9f55ff;
  position: absolute;
  bottom: -20px;
  border-radius: 50%;
  border: 1px solid #9f55ff;
  padding: 2px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%)
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 8px 2px #9f55ff;
    box-shadow: 0 0 8px 2px #9f55ff
  }
  100% {
    -webkit-box-shadow: 0 0 20px 2px #9f55ff;
    box-shadow: 0 0 20px 2px #9f55ff
  }
}

@keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 8px 2px #9f55ff;
    box-shadow: 0 0 8px 2px #9f55ff
  }
  100% {
    -webkit-box-shadow: 0 0 20px 2px #9f55ff;
    box-shadow: 0 0 20px 2px #9f55ff
  }
}

.italic {
  font-style: italic !important
}

.underline {
  text-decoration: underline !important
}

.dotted {
  border-bottom: 1px dotted
}

.strike-through, s {
  text-decoration: line-through !important;
  color: #7d8993;
  font-weight: 300
}

.text-wrap {
  white-space: normal !important
}

.bold, strong {
  font-weight: 700 !important
}

.thin {
  font-weight: 100 !important
}

.light {
  font-weight: 300 !important
}

.regular {
  font-weight: 400 !important
}

.extra-bold {
  font-weight: 800 !important
}

.accent {
  color: #9f55ff
}

.color-1 {
  color: #fff !important
}

.color-1.alpha-1 {
  color: rgba(255, 255, 255, .1)
}

.color-1.alpha-2 {
  color: rgba(255, 255, 255, .2)
}

.color-1.alpha-3 {
  color: rgba(255, 255, 255, .3)
}

.color-1.alpha-4 {
  color: rgba(255, 255, 255, .4)
}

.color-1.alpha-5 {
  color: rgba(255, 255, 255, .5)
}

.color-1.alpha-6 {
  color: rgba(255, 255, 255, .6)
}

.color-1.alpha-7 {
  color: rgba(255, 255, 255, .7)
}

.color-1.alpha-8 {
  color: rgba(255, 255, 255, .8)
}

.color-1.alpha-9 {
  color: rgba(255, 255, 255, .9)
}

.color-2 {
  color: #646f79 !important
}

.color-2.alpha-1 {
  color: rgba(100, 111, 121, .1)
}

.color-2.alpha-2 {
  color: rgba(100, 111, 121, .2)
}

.color-2.alpha-3 {
  color: rgba(100, 111, 121, .3)
}

.color-2.alpha-4 {
  color: rgba(100, 111, 121, .4)
}

.color-2.alpha-5 {
  color: rgba(100, 111, 121, .5)
}

.color-2.alpha-6 {
  color: rgba(100, 111, 121, .6)
}

.color-2.alpha-7 {
  color: rgba(100, 111, 121, .7)
}

.color-2.alpha-8 {
  color: rgba(100, 111, 121, .8)
}

.color-2.alpha-9 {
  color: rgba(100, 111, 121, .9)
}

.color-3 {
  color: #9f55ff !important
}

.color-3.alpha-1 {
  color: rgba(159, 85, 255, .1)
}

.color-3.alpha-2 {
  color: rgba(159, 85, 255, .2)
}

.color-3.alpha-3 {
  color: rgba(159, 85, 255, .3)
}

.color-3.alpha-4 {
  color: rgba(159, 85, 255, .4)
}

.color-3.alpha-5 {
  color: rgba(159, 85, 255, .5)
}

.color-3.alpha-6 {
  color: rgba(159, 85, 255, .6)
}

.color-3.alpha-7 {
  color: rgba(159, 85, 255, .7)
}

.color-3.alpha-8 {
  color: rgba(159, 85, 255, .8)
}

.color-3.alpha-9 {
  color: rgba(159, 85, 255, .9)
}

.color-4 {
  color: #028fff !important
}

.color-4.alpha-1 {
  color: rgba(2, 143, 255, .1)
}

.color-4.alpha-2 {
  color: rgba(2, 143, 255, .2)
}

.color-4.alpha-3 {
  color: rgba(2, 143, 255, .3)
}

.color-4.alpha-4 {
  color: rgba(2, 143, 255, .4)
}

.color-4.alpha-5 {
  color: rgba(2, 143, 255, .5)
}

.color-4.alpha-6 {
  color: rgba(2, 143, 255, .6)
}

.color-4.alpha-7 {
  color: rgba(2, 143, 255, .7)
}

.color-4.alpha-8 {
  color: rgba(2, 143, 255, .8)
}

.color-4.alpha-9 {
  color: rgba(2, 143, 255, .9)
}

.color-5 {
  color: #101480 !important
}

.color-5.alpha-1 {
  color: rgba(16, 20, 128, .1)
}

.color-5.alpha-2 {
  color: rgba(16, 20, 128, .2)
}

.color-5.alpha-3 {
  color: rgba(16, 20, 128, .3)
}

.color-5.alpha-4 {
  color: rgba(16, 20, 128, .4)
}

.color-5.alpha-5 {
  color: rgba(16, 20, 128, .5)
}

.color-5.alpha-6 {
  color: rgba(16, 20, 128, .6)
}

.color-5.alpha-7 {
  color: rgba(16, 20, 128, .7)
}

.color-5.alpha-8 {
  color: rgba(16, 20, 128, .8)
}

.color-5.alpha-9 {
  color: rgba(16, 20, 128, .9)
}

.color-6 {
  color: #f4f8fb !important
}

.color-6.alpha-1 {
  color: rgba(244, 248, 251, .1)
}

.color-6.alpha-2 {
  color: rgba(244, 248, 251, .2)
}

.color-6.alpha-3 {
  color: rgba(244, 248, 251, .3)
}

.color-6.alpha-4 {
  color: rgba(244, 248, 251, .4)
}

.color-6.alpha-5 {
  color: rgba(244, 248, 251, .5)
}

.color-6.alpha-6 {
  color: rgba(244, 248, 251, .6)
}

.color-6.alpha-7 {
  color: rgba(244, 248, 251, .7)
}

.color-6.alpha-8 {
  color: rgba(244, 248, 251, .8)
}

.color-6.alpha-9 {
  color: rgba(244, 248, 251, .9)
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-family: Poppins, sans-serif;
  font-weight: 400
}

.heading-line:after {
  content: '';
  display: block;
  margin-top: 32px;
  margin-bottom: 24px;
  width: 40px;
  height: 2px;
  background-color: #9f55ff
}

.font-regular {
  font-size: 1rem !important
}

.font-xs {
  font-size: .45rem !important
}

.font-sm {
  font-size: .85rem !important
}

.font-md {
  font-size: 2rem !important
}

.font-lg {
  font-size: 3.25rem !important
}

.font-xl {
  font-size: 4.5rem !important
}

.font-xxl {
  font-size: 6.5rem !important
}

.font-l {
  font-size: 1.25rem
}

.display-1 {
  font-size: 6rem !important
}

.display-2 {
  font-size: 5.5rem !important
}

.display-3 {
  font-size: 4.5rem !important
}

.display-4 {
  font-size: 3.5rem !important
}

@media (min-width: 576px) {
  .display-sm-1 {
    font-size: 6rem !important
  }
  .display-sm-2 {
    font-size: 5.5rem !important
  }
  .display-sm-3 {
    font-size: 4.5rem !important
  }
  .display-sm-4 {
    font-size: 3.5rem !important
  }
}

@media (min-width: 768px) {
  .display-md-1 {
    font-size: 6rem !important
  }
  .display-md-2 {
    font-size: 5.5rem !important
  }
  .display-md-3 {
    font-size: 4.5rem !important
  }
  .display-md-4 {
    font-size: 3.5rem !important
  }
}

@media (min-width: 992px) {
  .display-lg-1 {
    font-size: 6rem !important
  }
  .display-lg-2 {
    font-size: 5.5rem !important
  }
  .display-lg-3 {
    font-size: 4.5rem !important
  }
  .display-lg-4 {
    font-size: 3.5rem !important
  }
}

@media (min-width: 1200px) {
  .display-xl-1 {
    font-size: 6rem !important
  }
  .display-xl-2 {
    font-size: 5.5rem !important
  }
  .display-xl-3 {
    font-size: 4.5rem !important
  }
  .display-xl-4 {
    font-size: 3.5rem !important
  }
}

.handwritten {
  font-family: Caveat, cursive, Poppins, sans-serif
}

.handwritten.highlight {
  line-height: 1.25em;
  -webkit-transform: rotate(-5deg);
  transform: rotate(-5deg)
}

.handwritten.highlight:before {
  content: "";
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAA3CAMAAADDj2FQAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAGYUExURQAAAMXe58Te58Xf6Mbf6MTe58Xg5////8Xf5sXf5sTe5sja7MTf58Tf58TX68fd6MPf6Mbb6cXe5sbf5cXe58Tf5sPh58Te6MTe58Te5v///8Tf5sbe58Xf5sTf5sTd5sXf5qr//8zd7sXe5sTe5sTd6Lba2sXf58Tf58Xe5sTf5sTe58Xf5sPh5sXe58Xf58Tf58Xf5szM/7+//8Xf5tTU1MXe58Tf58Hg6sXe5sXe5sLa5sTe5sTe5sTf5sTf58Ph4cXf58Xf58bi4sXc58Tg5cXf58Tf58Tf5sXf5cTe5sTf58Pg58Te5sXe58Xc58ff5sLd5MXe58Xe5sPe6cbi4sPf5sXe5sXe58bd58Xe5sPf58Tf5sTe58Xe58zl5cXf58bd6MTf5sTf5sTf58Te58Tc5sXe5sXf5sXf58Pe58Xe58Te58fc6sXg6MTf5sXf5sXe58Lg5sXf58Te5sPd58Tf5sXe57/U6cHg58Tf5sTf58Te58Xe58Te5MTf5sXf5sXe5sTf5sXe5sXf5sTe5cTe6MXf59gagUYAAACHdFJOUwB3dllaxEsC+5L+DqFgDRc4JHxwl9IrTtf9AfFVsfpc8gMP3OdkB+Gr/NHW0DPP+INzBQTIBqT5GR+FFX+HcYIRgcMSFltiwMlQ34tjlJYsKSa2xi8JSb5uNpx52/WNCtgtaZC49jTlm+tWzLclQ7OT7iqgfmzajgwhSuBX7yeRqZ/o9GplT5WzxmIAAAFXSURBVBgZfcEDdxwBAIXRbznrjW2btY2ktm07tdv3t3s2bU7SzvTdC2zEatiGtRuvBu8gVvU8VlscK34A61ASq70Ra28aa99+rPokVv0erFQc68F1rOdDWC8msBKTszjjeoZTGi5iTRSwngxi3dNhnHLzdqwdTVhbVIWzPrcGK7V2HU5Cm3FW93XtwtmqLM7O1swJnBodDzBODuoaTloXG3AKGr2Mke9VezfG1Vada2FJVZ5/NTarroU/UnWETEuFbn47ljlKSEy60cOiTg0Rdl5qekhFMJI7S1h/h7qKVLTpNBEePZU+l4Hggk4R4eOY9OFlAP0aC4gw8CknjSaCmV51EuntO0lXztRqboC/lI7cGn9TgtcLGUnDUi0rBNkOVUz2vbp/W4vu5llWVNgUy9IbNsViU5fuPB65qSXfykQIqpPZVRlVTPM/Pe+/fJW+Y8z8/DH7C72mVfoIDlnRAAAAAElFTkSuQmCC);
  margin-left: -1.5rem;
  width: 31px;
  height: 55px;
  position: absolute
}

[data-toggle=collapse]:before {
  font-family: $fa-style-family;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 900;
  content: "\f107";
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease;
  margin-right: .5rem
}

[data-toggle=collapse].collapsed:before {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg)
}

.accordion .card {
  border: 1px solid #edf4f8
}

.accordion .card-header {
  border-bottom: none;
  background-color: #f4f8fb
}

.accordion .card-header .btn:active, .accordion .card-header .btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none
}

.accordion .card-header .card-title {
  padding-right: 0;
  padding-left: 0
}

.accordion .card-title {
  margin-bottom: 0
}

.accordion:not(.accordion-collapsed) .card {
  border-bottom: 1px solid #edf4f8 !important
}

.accordion.accordion-clean .card {
  border-bottom: 1px solid #edf4f8
}

.accordion.accordion-clean .card-header {
  background-color: #fff
}

.accordion.accordion-collapsed .card {
  border-bottom: none
}

.accordion.accordion-collapsed .card:last-of-type {
  border-bottom: 1px solid #edf4f8
}

.accordion.accordion-primary .card {
  border-color: #007bff
}

.accordion.accordion-primary .card-header {
  background-color: #007bff
}

.accordion.accordion-primary .card-header .card-title {
  color: #fff
}

.accordion.accordion-primary:not(.accordion-collapsed) .card {
  border-bottom: 1px solid #007bff !important
}

.accordion.accordion-secondary .card {
  border-color: #6c757d
}

.accordion.accordion-secondary .card-header {
  background-color: #6c757d
}

.accordion.accordion-secondary .card-header .card-title {
  color: #fff
}

.accordion.accordion-secondary:not(.accordion-collapsed) .card {
  border-bottom: 1px solid #6c757d !important
}

.accordion.accordion-success .card {
  border-color: #28a745
}

.accordion.accordion-success .card-header {
  background-color: #28a745
}

.accordion.accordion-success .card-header .card-title {
  color: #fff
}

.accordion.accordion-success:not(.accordion-collapsed) .card {
  border-bottom: 1px solid #28a745 !important
}

.accordion.accordion-info .card {
  border-color: #17a2b8
}

.accordion.accordion-info .card-header {
  background-color: #17a2b8
}

.accordion.accordion-info .card-header .card-title {
  color: #fff
}

.accordion.accordion-info:not(.accordion-collapsed) .card {
  border-bottom: 1px solid #17a2b8 !important
}

.accordion.accordion-warning .card {
  border-color: #ffc107
}

.accordion.accordion-warning .card-header {
  background-color: #ffc107
}

.accordion.accordion-warning .card-header .card-title {
  color: #212529
}

.accordion.accordion-warning:not(.accordion-collapsed) .card {
  border-bottom: 1px solid #ffc107 !important
}

.accordion.accordion-danger .card {
  border-color: #dc3545
}

.accordion.accordion-danger .card-header {
  background-color: #dc3545
}

.accordion.accordion-danger .card-header .card-title {
  color: #fff
}

.accordion.accordion-danger:not(.accordion-collapsed) .card {
  border-bottom: 1px solid #dc3545 !important
}

.accordion.accordion-light .card {
  border-color: #f8f9fa
}

.accordion.accordion-light .card-header {
  background-color: #f8f9fa
}

.accordion.accordion-light .card-header .card-title {
  color: #212529
}

.accordion.accordion-light:not(.accordion-collapsed) .card {
  border-bottom: 1px solid #f8f9fa !important
}

.accordion.accordion-dark .card {
  border-color: #343a40
}

.accordion.accordion-dark .card-header {
  background-color: #343a40
}

.accordion.accordion-dark .card-header .card-title {
  color: #fff
}

.accordion.accordion-dark:not(.accordion-collapsed) .card {
  border-bottom: 1px solid #343a40 !important
}

.accordion.accordion-1 .card {
  border-color: #fff
}

.accordion.accordion-1 .card-header {
  background-color: #fff
}

.accordion.accordion-1 .card-header .card-title {
  color: #212529
}

.accordion.accordion-1:not(.accordion-collapsed) .card {
  border-bottom: 1px solid #fff !important
}

.accordion.accordion-2 .card {
  border-color: #646f79
}

.accordion.accordion-2 .card-header {
  background-color: #646f79
}

.accordion.accordion-2 .card-header .card-title {
  color: #fff
}

.accordion.accordion-2:not(.accordion-collapsed) .card {
  border-bottom: 1px solid #646f79 !important
}

.accordion.accordion-3 .card {
  border-color: #9f55ff
}

.accordion.accordion-3 .card-header {
  background-color: #9f55ff
}

.accordion.accordion-3 .card-header .card-title {
  color: #fff
}

.accordion.accordion-3:not(.accordion-collapsed) .card {
  border-bottom: 1px solid #9f55ff !important
}

.accordion.accordion-4 .card {
  border-color: #028fff
}

.accordion.accordion-4 .card-header {
  background-color: #028fff
}

.accordion.accordion-4 .card-header .card-title {
  color: #fff
}

.accordion.accordion-4:not(.accordion-collapsed) .card {
  border-bottom: 1px solid #028fff !important
}

.accordion.accordion-5 .card {
  border-color: #101480
}

.accordion.accordion-5 .card-header {
  background-color: #101480
}

.accordion.accordion-5 .card-header .card-title {
  color: #fff
}

.accordion.accordion-5:not(.accordion-collapsed) .card {
  border-bottom: 1px solid #101480 !important
}

.accordion.accordion-6 .card {
  border-color: #f4f8fb
}

.accordion.accordion-6 .card-header {
  background-color: #f4f8fb
}

.accordion.accordion-6 .card-header .card-title {
  color: #212529
}

.accordion.accordion-6:not(.accordion-collapsed) .card {
  border-bottom: 1px solid #f4f8fb !important
}

.payment-accordion [data-toggle=collapse]:before {
  content: none
}

.alert-icon {
  border-width: 0;
  border-radius: 0;
  border-left-width: 4rem
}

.alert-icon:before {
  width: 4rem;
  position: absolute;
  left: -4rem;
  color: #fff;
  font-size: 1.5rem;
  text-align: center;
  font-family: $fa-style-family;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 900
}

.alert-icon.alert-primary:before {
  content: "\f05a"
}

.alert-icon.alert-secondary:before {
  content: "\f058"
}

.alert-icon.alert-success:before {
  content: "\f00c"
}

.alert-icon.alert-info:before {
  content: "\f05a"
}

.alert-icon.alert-warning:before {
  content: "\f071"
}

.alert-icon.alert-danger:before {
  content: "\f06a"
}

.alert-icon.alert-light:before {
  content: "\f129"
}

.alert-icon.alert-dark:before {
  content: "\f058"
}

.alert-thin {
  border-left-width: 5px
}

.alert-outline, .alert-thin {
  background-color: transparent
}

.badge-1 {
  color: #212529;
  background-color: #fff
}

a.badge-1:focus, a.badge-1:hover {
  color: #212529;
  background-color: #e6e6e6
}

a.badge-1.focus, a.badge-1:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
  box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5)
}

.badge-outline-1 {
  border: 1px solid;
  color: #fff;
  background-color: transparent;
  border-color: #fff
}

.badge-outline-1[href]:focus, .badge-outline-1[href]:hover {
  color: #fff;
  background-color: #fff;
  border-color: #fff;
  text-decoration: none
}

.badge-2 {
  color: #fff;
  background-color: #646f79
}

a.badge-2:focus, a.badge-2:hover {
  color: #fff;
  background-color: #4d555d
}

a.badge-2.focus, a.badge-2:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 .2rem rgba(100, 111, 121, .5);
  box-shadow: 0 0 0 .2rem rgba(100, 111, 121, .5)
}

.badge-outline-2 {
  border: 1px solid;
  color: #646f79;
  background-color: transparent;
  border-color: #646f79
}

.badge-outline-2[href]:focus, .badge-outline-2[href]:hover {
  color: #fff;
  background-color: #646f79;
  border-color: #646f79;
  text-decoration: none
}

.badge-3 {
  color: #fff;
  background-color: #9f55ff
}

a.badge-3:focus, a.badge-3:hover {
  color: #fff;
  background-color: #8222ff
}

a.badge-3.focus, a.badge-3:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 .2rem rgba(159, 85, 255, .5);
  box-shadow: 0 0 0 .2rem rgba(159, 85, 255, .5)
}

.badge-outline-3 {
  border: 1px solid;
  color: #9f55ff;
  background-color: transparent;
  border-color: #9f55ff
}

.badge-outline-3[href]:focus, .badge-outline-3[href]:hover {
  color: #fff;
  background-color: #9f55ff;
  border-color: #9f55ff;
  text-decoration: none
}

.badge-4 {
  color: #fff;
  background-color: #028fff
}

a.badge-4:focus, a.badge-4:hover {
  color: #fff;
  background-color: #0073ce
}

a.badge-4.focus, a.badge-4:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 .2rem rgba(2, 143, 255, .5);
  box-shadow: 0 0 0 .2rem rgba(2, 143, 255, .5)
}

.badge-outline-4 {
  border: 1px solid;
  color: #028fff;
  background-color: transparent;
  border-color: #028fff
}

.badge-outline-4[href]:focus, .badge-outline-4[href]:hover {
  color: #fff;
  background-color: #028fff;
  border-color: #028fff;
  text-decoration: none
}

.badge-5 {
  color: #fff;
  background-color: #101480
}

a.badge-5:focus, a.badge-5:hover {
  color: #fff;
  background-color: #0a0d53
}

a.badge-5.focus, a.badge-5:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 .2rem rgba(16, 20, 128, .5);
  box-shadow: 0 0 0 .2rem rgba(16, 20, 128, .5)
}

.badge-outline-5 {
  border: 1px solid;
  color: #101480;
  background-color: transparent;
  border-color: #101480
}

.badge-outline-5[href]:focus, .badge-outline-5[href]:hover {
  color: #fff;
  background-color: #101480;
  border-color: #101480;
  text-decoration: none
}

.badge-6 {
  color: #212529;
  background-color: #f4f8fb
}

a.badge-6:focus, a.badge-6:hover {
  color: #212529;
  background-color: #cfe0ed
}

a.badge-6.focus, a.badge-6:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 .2rem rgba(244, 248, 251, .5);
  box-shadow: 0 0 0 .2rem rgba(244, 248, 251, .5)
}

.badge-outline-6 {
  border: 1px solid;
  color: #f4f8fb;
  background-color: transparent;
  border-color: #f4f8fb
}

.badge-outline-6[href]:focus, .badge-outline-6[href]:hover {
  color: #fff;
  background-color: #f4f8fb;
  border-color: #f4f8fb;
  text-decoration: none
}

.badge-outline-primary {
  border: 1px solid;
  color: #007bff;
  background-color: transparent;
  border-color: #007bff
}

.badge-outline-primary[href]:focus, .badge-outline-primary[href]:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  text-decoration: none
}

.badge-outline-secondary {
  border: 1px solid;
  color: #6c757d;
  background-color: transparent;
  border-color: #6c757d
}

.badge-outline-secondary[href]:focus, .badge-outline-secondary[href]:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  text-decoration: none
}

.badge-outline-success {
  border: 1px solid;
  color: #28a745;
  background-color: transparent;
  border-color: #28a745
}

.badge-outline-success[href]:focus, .badge-outline-success[href]:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  text-decoration: none
}

.badge-outline-info {
  border: 1px solid;
  color: #17a2b8;
  background-color: transparent;
  border-color: #17a2b8
}

.badge-outline-info[href]:focus, .badge-outline-info[href]:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  text-decoration: none
}

.badge-outline-warning {
  border: 1px solid;
  color: #ffc107;
  background-color: transparent;
  border-color: #ffc107
}

.badge-outline-warning[href]:focus, .badge-outline-warning[href]:hover {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
  text-decoration: none
}

.badge-outline-danger {
  border: 1px solid;
  color: #dc3545;
  background-color: transparent;
  border-color: #dc3545
}

.badge-outline-danger[href]:focus, .badge-outline-danger[href]:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  text-decoration: none
}

.badge-outline-light {
  border: 1px solid;
  color: #f8f9fa;
  background-color: transparent;
  border-color: #f8f9fa
}

.badge-outline-light[href]:focus, .badge-outline-light[href]:hover {
  color: #fff;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  text-decoration: none
}

.badge-outline-dark {
  border: 1px solid;
  color: #343a40;
  background-color: transparent;
  border-color: #343a40
}

.badge-outline-dark[href]:focus, .badge-outline-dark[href]:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  text-decoration: none
}

.popup-wrapper {
  height: 100%
}

.popup-wrapper {
  position: relative
}

@media (min-width: 768px) {
  .mfp-content, .popup-wrapper {
    height: auto
  }
  .popup-wrapper {
    padding: 20px 30px;
    margin: 0 auto
  }
}

.progress.progress-sl {
  height: 1px
}

.progress.progress-ty {
  height: 2px
}

.progress.progress-xs {
  height: 4px
}

.progress.progress-sm {
  height: 6px
}

.progress.progress-md {
  height: 12px
}

.progress.progress-1 {
  background-color: rgba(255, 255, 255, .3)
}

.progress.progress-1 .progress-bar {
  background-color: #fff
}

.progress.progress-2 {
  background-color: rgba(100, 111, 121, .3)
}

.progress.progress-2 .progress-bar {
  background-color: #646f79
}

.progress.progress-3 {
  background-color: rgba(159, 85, 255, .3)
}

.progress.progress-3 .progress-bar {
  background-color: #9f55ff
}

.progress.progress-4 {
  background-color: rgba(2, 143, 255, .3)
}

.progress.progress-4 .progress-bar {
  background-color: #028fff
}

.progress.progress-5 {
  background-color: rgba(16, 20, 128, .3)
}

.progress.progress-5 .progress-bar {
  background-color: #101480
}

.progress.progress-6 {
  background-color: rgba(244, 248, 251, .3)
}

.progress.progress-6 .progress-bar {
  background-color: #f4f8fb
}

.progress.progress-primary {
  background-color: rgba(0, 123, 255, .3)
}

.progress.progress-primary .progress-bar {
  background-color: #007bff
}

.progress.progress-secondary {
  background-color: rgba(108, 117, 125, .3)
}

.progress.progress-secondary .progress-bar {
  background-color: #6c757d
}

.progress.progress-success {
  background-color: rgba(40, 167, 69, .3)
}

.progress.progress-success .progress-bar {
  background-color: #28a745
}

.progress.progress-info {
  background-color: rgba(23, 162, 184, .3)
}

.progress.progress-info .progress-bar {
  background-color: #17a2b8
}

.progress.progress-warning {
  background-color: rgba(255, 193, 7, .3)
}

.progress.progress-warning .progress-bar {
  background-color: #ffc107
}

.progress.progress-danger {
  background-color: rgba(220, 53, 69, .3)
}

.progress.progress-danger .progress-bar {
  background-color: #dc3545
}

.progress.progress-light {
  background-color: rgba(248, 249, 250, .3)
}

.progress.progress-light .progress-bar {
  background-color: #f8f9fa
}

.progress.progress-dark {
  background-color: rgba(52, 58, 64, .3)
}

.progress.progress-dark .progress-bar {
  background-color: #343a40
}

.animate-bars .progress-bars {
  margin: 0;
  padding: 0;
  list-style-type: none
}

.animate-bars .progress-bars p {
  font-size: .85rem
}

.animate-bars .progress-bars.progress-vertical {
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around
}

.animate-bars .progress-bars.progress-vertical p {
  margin-top: 0;
  margin-left: 1rem
}

.animate-bars .progress-bars.progress-vertical li {
  height: 100px;
  width: 8px
}

.animate-bars .progress-bars.progress-vertical .progress, .animate-bars .progress-bars.progress-vertical .progress-bar {
  bottom: 0;
  position: absolute;
  width: 8px
}

.animate-bars .progress-bars.progress-horizontal {
  width: 100%
}

.animate-bars .progress-bars.progress-horizontal p {
  margin-top: 0;
  margin-bottom: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.animate-bars .progress-bars.progress-horizontal li + li {
  margin-top: 1.5rem
}

.animate-bars .progress-bars li {
  position: relative
}

.navbar-nav {
  position: relative
}

@media (min-width: 768px) {
  .navbar .dropdown.mega {
    position: static
  }
}

.nav-process .nav-item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start
}

.nav-process .nav-item:after, .nav-process .nav-item:before {
  content: '';
  width: 50%;
  border-top: 1px dashed #9f55ff;
  -webkit-transition: all .3s;
  transition: all .3s;
  margin-top: .375rem
}

.nav-process .nav-item:before {
  left: 0
}

.nav-process .nav-item:after {
  right: 0
}

.nav-process .nav-item:first-of-type:before {
  border: none
}

.nav-process .nav-item:last-of-type:after {
  border: none
}

.nav-process.nav-circle .nav-item.active .nav-link:before {
  padding: .75rem;
  margin-top: -.375rem
}

.nav-process.nav-circle .nav-item.active .nav-link:after {
  content: attr(data-step);
  color: #fff;
  font-size: .55rem;
  font-weight: 700;
  position: absolute;
  top: 1px
}

.nav-process.nav-circle .nav-link {
  position: relative;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.nav-process.nav-circle .nav-link:before {
  content: '';
  background-color: #9f55ff;
  padding: .375rem;
  display: block;
  border-radius: 50%;
  margin: 0 .5rem;
  -webkit-transition: all .3s ease;
  transition: all .3s ease
}

.table thead th {
  border-bottom: 1px solid #edf4f8
}

.table td, .table th {
  border-color: #edf4f8
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f8f9fa
}

.table-hover tbody tr:hover {
  background-color: #f5f6f8
}

@media (max-width: 767.98px) {
  .table-responsive tbody, .table-responsive th, .table-responsive thead, .table-responsive tr {
    display: block
  }
  .table-responsive thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px
  }
  .table-responsive tr {
    border: 1px solid #edf4f8;
    margin-bottom: .5rem
  }
  .table-responsive td {
    border: none;
    border-bottom: 1px solid #edf4f8;
    position: relative;
    white-space: normal;
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }
  .table-responsive td:before {
    white-space: nowrap;
    text-align: left;
    font-weight: 700;
    content: attr(data-title);
    font-size: .85rem;
    padding-bottom: .5rem;
    color: #646f79;
    opacity: .5
  }
}

.nav-tabs .nav-link {
  letter-spacing: 1.2px;
  font-size: .75rem;
  font-weight: 400;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem
}

@media (min-width: 768px) {
  .nav-tabs .nav-link:hover {
    background-color: transparent
  }
}

.nav-tabs .nav-link [disabled], .nav-tabs .nav-link.disabled {
  color: #c3c8cd !important
}

.nav-tabs .nav-link.active {
  color: #9f55ff
}

.nav-tabs.tabs-clean .nav-link {
  border-color: transparent
}

.nav-tabs.tabs-clean .nav-link .icon {
  -webkit-transform: scale(.95);
  transform: scale(.95);
  -webkit-transition: -webkit-transform .3s linear;
  transition: -webkit-transform .3s linear;
  transition: transform .3s linear;
  transition: transform .3s linear, -webkit-transform .3s linear;
  margin: auto
}

.nav-tabs.tabs-clean .nav-link.active {
  border-bottom-color: #9f55ff
}

.nav-tabs.tabs-clean .nav-link.active .icon {
  -webkit-transform: scale(1.25);
  transform: scale(1.25)
}

.nav-tabs.tabs-bordered .nav-link.active {
  border-top: 2px solid #9f55ff
}

.nav-tabs.nav-outlined {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  border-bottom: none
}

@media (min-width: 576px) {
  .nav-tabs.nav-outlined {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
  }
}

.nav-tabs.nav-outlined .nav-link {
  border: 1px solid #9f55ff;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: .5rem;
  color: #9f55ff
}

@media (min-width: 576px) {
  .nav-tabs.nav-outlined .nav-link + .nav-link {
    margin-left: -1px
  }
}

@media (min-width: 768px) {
  .nav-tabs.nav-outlined .nav-link {
    margin-top: 0;
    padding: .25rem 1.25rem
  }
}

.nav-tabs.nav-outlined .nav-link.active {
  background-color: #9f55ff;
  color: #fff
}

.nav-tabs.nav-outlined .nav-link:last-child {
  border-right: 1px solid #9f55ff
}

.nav-tabs.nav-outlined.nav-rounded .nav-link {
    border-radius: 5rem;
    padding: .25rem 1.25rem;
    margin-right: 0;
}

.nav-tabs.nav-outlined.nav-rounded .nav-link:first-child {
    border-radius: 5rem 0 0 5rem;
}
.nav-tabs.nav-outlined.nav-rounded .nav-link:last-child {
    border-radius: 0 5rem 5rem 0;
}

@media (min-width: 768px) {
  .nav-tabs.slide + .tab-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    overflow: hidden
  }
  .nav-tabs.slide + .tab-content > .tab-pane {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: -webkit-transform .3s ease-in-out;
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
    margin-right: -100%;
    display: block;
    opacity: 0;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
  }
  .nav-tabs.slide + .tab-content > .tab-pane.active {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
    margin-right: 0
  }
}

.nav-tabs.nav-tabs-1 .nav-link.active {
  color: #fff
}

.nav-tabs.nav-tabs-1.tabs-clean .nav-link.active {
  border-bottom-color: #fff
}

.nav-tabs.nav-tabs-1.tabs-bordered .nav-link.active {
  border-top-color: #fff !important
}

.nav-tabs.nav-tabs-1.nav-outlined .nav-link {
  border-color: #fff;
  color: #fff
}

.nav-tabs.nav-tabs-1.nav-outlined .nav-link.active {
  background-color: #fff;
  color: #fff
}

.nav-tabs.nav-tabs-1.nav-outlined .nav-link:last-child {
  border-right-color: #fff
}

.nav-tabs.nav-tabs-2 .nav-link.active {
  color: #646f79
}

.nav-tabs.nav-tabs-2.tabs-clean .nav-link.active {
  border-bottom-color: #646f79
}

.nav-tabs.nav-tabs-2.tabs-bordered .nav-link.active {
  border-top-color: #646f79 !important
}

.nav-tabs.nav-tabs-2.nav-outlined .nav-link {
  border-color: #646f79;
  color: #646f79
}

.nav-tabs.nav-tabs-2.nav-outlined .nav-link.active {
  background-color: #646f79;
  color: #fff
}

.nav-tabs.nav-tabs-2.nav-outlined .nav-link:last-child {
  border-right-color: #646f79
}

.nav-tabs.nav-tabs-3 .nav-link.active {
  color: #9f55ff
}

.nav-tabs.nav-tabs-3.tabs-clean .nav-link.active {
  border-bottom-color: #9f55ff
}

.nav-tabs.nav-tabs-3.tabs-bordered .nav-link.active {
  border-top-color: #9f55ff !important
}

.nav-tabs.nav-tabs-3.nav-outlined .nav-link {
  border-color: #9f55ff;
  color: #9f55ff
}

.nav-tabs.nav-tabs-3.nav-outlined .nav-link.active {
  background-color: #9f55ff;
  color: #fff
}

.nav-tabs.nav-tabs-3.nav-outlined .nav-link:last-child {
  border-right-color: #9f55ff
}

.nav-tabs.nav-tabs-4 .nav-link.active {
  color: #028fff
}

.nav-tabs.nav-tabs-4.tabs-clean .nav-link.active {
  border-bottom-color: #028fff
}

.nav-tabs.nav-tabs-4.tabs-bordered .nav-link.active {
  border-top-color: #028fff !important
}

.nav-tabs.nav-tabs-4.nav-outlined .nav-link {
  border-color: #028fff;
  color: #028fff
}

.nav-tabs.nav-tabs-4.nav-outlined .nav-link.active {
  background-color: #028fff;
  color: #fff
}

.nav-tabs.nav-tabs-4.nav-outlined .nav-link:last-child {
  border-right-color: #028fff
}

.nav-tabs.nav-tabs-5 .nav-link.active {
  color: #101480
}

.nav-tabs.nav-tabs-5.tabs-clean .nav-link.active {
  border-bottom-color: #101480
}

.nav-tabs.nav-tabs-5.tabs-bordered .nav-link.active {
  border-top-color: #101480 !important
}

.nav-tabs.nav-tabs-5.nav-outlined .nav-link {
  border-color: #101480;
  color: #101480
}

.nav-tabs.nav-tabs-5.nav-outlined .nav-link.active {
  background-color: #101480;
  color: #fff
}

.nav-tabs.nav-tabs-5.nav-outlined .nav-link:last-child {
  border-right-color: #101480
}

.nav-tabs.nav-tabs-6 .nav-link.active {
  color: #f4f8fb
}

.nav-tabs.nav-tabs-6.tabs-clean .nav-link.active {
  border-bottom-color: #f4f8fb
}

.nav-tabs.nav-tabs-6.tabs-bordered .nav-link.active {
  border-top-color: #f4f8fb !important
}

.nav-tabs.nav-tabs-6.nav-outlined .nav-link {
  border-color: #f4f8fb;
  color: #f4f8fb
}

.nav-tabs.nav-tabs-6.nav-outlined .nav-link.active {
  background-color: #f4f8fb;
  color: #fff
}

.nav-tabs.nav-tabs-6.nav-outlined .nav-link:last-child {
  border-right-color: #f4f8fb
}

.nav-tabs.nav-tabs-primary .nav-link.active {
  color: #007bff
}

.nav-tabs.nav-tabs-primary.tabs-clean .nav-link.active {
  border-bottom-color: #007bff
}

.nav-tabs.nav-tabs-primary.tabs-bordered .nav-link.active {
  border-top-color: #007bff !important
}

.nav-tabs.nav-tabs-primary.nav-outlined .nav-link {
  border-color: #007bff;
  color: #007bff
}

.nav-tabs.nav-tabs-primary.nav-outlined .nav-link.active {
  background-color: #007bff;
  color: #fff
}

.nav-tabs.nav-tabs-primary.nav-outlined .nav-link:last-child {
  border-right-color: #007bff
}

.nav-tabs.nav-tabs-secondary .nav-link.active {
  color: #6c757d
}

.nav-tabs.nav-tabs-secondary.tabs-clean .nav-link.active {
  border-bottom-color: #6c757d
}

.nav-tabs.nav-tabs-secondary.tabs-bordered .nav-link.active {
  border-top-color: #6c757d !important
}

.nav-tabs.nav-tabs-secondary.nav-outlined .nav-link {
  border-color: #6c757d;
  color: #6c757d
}

.nav-tabs.nav-tabs-secondary.nav-outlined .nav-link.active {
  background-color: #6c757d;
  color: #fff
}

.nav-tabs.nav-tabs-secondary.nav-outlined .nav-link:last-child {
  border-right-color: #6c757d
}

.nav-tabs.nav-tabs-success .nav-link.active {
  color: #28a745
}

.nav-tabs.nav-tabs-success.tabs-clean .nav-link.active {
  border-bottom-color: #28a745
}

.nav-tabs.nav-tabs-success.tabs-bordered .nav-link.active {
  border-top-color: #28a745 !important
}

.nav-tabs.nav-tabs-success.nav-outlined .nav-link {
  border-color: #28a745;
  color: #28a745
}

.nav-tabs.nav-tabs-success.nav-outlined .nav-link.active {
  background-color: #28a745;
  color: #fff
}

.nav-tabs.nav-tabs-success.nav-outlined .nav-link:last-child {
  border-right-color: #28a745
}

.nav-tabs.nav-tabs-info .nav-link.active {
  color: #17a2b8
}

.nav-tabs.nav-tabs-info.tabs-clean .nav-link.active {
  border-bottom-color: #17a2b8
}

.nav-tabs.nav-tabs-info.tabs-bordered .nav-link.active {
  border-top-color: #17a2b8 !important
}

.nav-tabs.nav-tabs-info.nav-outlined .nav-link {
  border-color: #17a2b8;
  color: #17a2b8
}

.nav-tabs.nav-tabs-info.nav-outlined .nav-link.active {
  background-color: #17a2b8;
  color: #fff
}

.nav-tabs.nav-tabs-info.nav-outlined .nav-link:last-child {
  border-right-color: #17a2b8
}

.nav-tabs.nav-tabs-warning .nav-link.active {
  color: #ffc107
}

.nav-tabs.nav-tabs-warning.tabs-clean .nav-link.active {
  border-bottom-color: #ffc107
}

.nav-tabs.nav-tabs-warning.tabs-bordered .nav-link.active {
  border-top-color: #ffc107 !important
}

.nav-tabs.nav-tabs-warning.nav-outlined .nav-link {
  border-color: #ffc107;
  color: #ffc107
}

.nav-tabs.nav-tabs-warning.nav-outlined .nav-link.active {
  background-color: #ffc107;
  color: #fff
}

.nav-tabs.nav-tabs-warning.nav-outlined .nav-link:last-child {
  border-right-color: #ffc107
}

.nav-tabs.nav-tabs-danger .nav-link.active {
  color: #dc3545
}

.nav-tabs.nav-tabs-danger.tabs-clean .nav-link.active {
  border-bottom-color: #dc3545
}

.nav-tabs.nav-tabs-danger.tabs-bordered .nav-link.active {
  border-top-color: #dc3545 !important
}

.nav-tabs.nav-tabs-danger.nav-outlined .nav-link {
  border-color: #dc3545;
  color: #dc3545
}

.nav-tabs.nav-tabs-danger.nav-outlined .nav-link.active {
  background-color: #dc3545;
  color: #fff
}

.nav-tabs.nav-tabs-danger.nav-outlined .nav-link:last-child {
  border-right-color: #dc3545
}

.nav-tabs.nav-tabs-light .nav-link.active {
  color: #f8f9fa
}

.nav-tabs.nav-tabs-light.tabs-clean .nav-link.active {
  border-bottom-color: #f8f9fa
}

.nav-tabs.nav-tabs-light.tabs-bordered .nav-link.active {
  border-top-color: #f8f9fa !important
}

.nav-tabs.nav-tabs-light.nav-outlined .nav-link {
  border-color: #f8f9fa;
  color: #f8f9fa
}

.nav-tabs.nav-tabs-light.nav-outlined .nav-link.active {
  background-color: #f8f9fa;
  color: #fff
}

.nav-tabs.nav-tabs-light.nav-outlined .nav-link:last-child {
  border-right-color: #f8f9fa
}

.nav-tabs.nav-tabs-dark .nav-link.active {
  color: #343a40
}

.nav-tabs.nav-tabs-dark.tabs-clean .nav-link.active {
  border-bottom-color: #343a40
}

.nav-tabs.nav-tabs-dark.tabs-bordered .nav-link.active {
  border-top-color: #343a40 !important
}

.nav-tabs.nav-tabs-dark.nav-outlined .nav-link {
  border-color: #343a40;
  color: #343a40
}

.nav-tabs.nav-tabs-dark.nav-outlined .nav-link.active {
  background-color: #343a40;
  color: #fff
}

.nav-tabs.nav-tabs-dark.nav-outlined .nav-link:last-child {
  border-right-color: #343a40
}

.sw-theme-circles > ul.step-anchor > li:before, .sw-theme-circles > ul.step-anchor > li:last-of-type:after {
  content: "";
  position: absolute;
  height: 3px;
  top: 50%;
  background-color: #edf4f8;
  width: 50%
}

.wizard.sw-main .step-content {
  background-color: transparent
}

.wizard.sw-main .sw-toolbar {
  background: 0 0
}

.wizard.sw-main > ul.step-anchor {
  background: 0 0
}

.wizard.sw-main .nav-tabs .nav-link {
  letter-spacing: 1px;
  padding: .5rem 1rem !important
}

.wizard.sw-main .nav-tabs .nav-link i {
  font-size: 1rem
}

.sw-theme-circles > ul.step-anchor:before {
  content: none
}

.sw-theme-circles > ul.step-anchor > li {
  margin-top: 60px;
  margin-left: 0;
  padding-left: 40px;
  position: relative
}

.sw-theme-circles > ul.step-anchor > li > a {
  position: relative;
  border: none;
  background: #edf4f8;
  padding: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column
}

.sw-theme-circles > ul.step-anchor > li > a:hover {
  background: #dbe9f1
}

.sw-theme-circles > ul.step-anchor > li > a > .desc, .sw-theme-circles > ul.step-anchor > li > a > .small, .sw-theme-circles > ul.step-anchor > li > a > small {
  position: absolute;
  bottom: -1.75rem;
  color: #ccc
}

.sw-theme-circles > ul.step-anchor > li:before {
  left: 0
}

.sw-theme-circles > ul.step-anchor > li:last-of-type {
  padding-right: 40px
}

.sw-theme-circles > ul.step-anchor > li:last-of-type:after {
  right: 0
}

.sw-theme-circles > ul.step-anchor > li.clickable > a:hover {
  color: #9f55ff !important
}

.sw-theme-circles > ul.step-anchor > li.active:before, .sw-theme-circles > ul.step-anchor > li.active:last-of-type:after, .sw-theme-circles > ul.step-anchor > li.done:before, .sw-theme-circles > ul.step-anchor > li.done:last-of-type:after {
  background-color: #9f55ff
}

.sw-theme-circles > ul.step-anchor > li.active > a {
  color: #fff;
  background: #9f55ff
}

.sw-theme-circles > ul.step-anchor > li.active > a > .desc, .sw-theme-circles > ul.step-anchor > li.active > a > .small, .sw-theme-circles > ul.step-anchor > li.active > a > small {
  color: #9f55ff
}

.sw-theme-circles > ul.step-anchor > li.done > a {
  -webkit-box-shadow: 0 0 0 2px #9f55ff !important;
  box-shadow: 0 0 0 2px #9f55ff !important;
  color: #646f79;
  background: #fff
}

.sw-theme-circles > ul.step-anchor > li.done > a > .desc, .sw-theme-circles > ul.step-anchor > li.done > a > .small, .sw-theme-circles > ul.step-anchor > li.done > a > small {
  color: #646f79
}

.sw-theme-circles > ul.step-anchor > li.danger > a {
  -webkit-box-shadow: 0 0 0 2px #dc3545 !important;
  box-shadow: 0 0 0 2px #dc3545 !important;
  color: #fff;
  background: #dc3545
}

.sw-theme-circles > ul.step-anchor > li.danger > a > .desc, .sw-theme-circles > ul.step-anchor > li.danger > a > .small, .sw-theme-circles > ul.step-anchor > li.danger > a > small {
  color: #dc3545
}

.sw-theme-circles > ul.step-anchor > li.disabled > a {
  color: #eee !important
}

.sw-theme-circles > ul.step-anchor > li.disabled > a:hover {
  color: #eee !important
}

@media (min-width: 768px) {
  .sw-theme-circles > ul.step-anchor > li {
    margin-top: 0
  }
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

@keyframes fade-out {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

@-webkit-keyframes bubble-v {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  100% {
    -webkit-transform: translateY(-460px);
    transform: translateY(-460px)
  }
}

@keyframes bubble-v {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  100% {
    -webkit-transform: translateY(-460px);
    transform: translateY(-460px)
  }
}

@-webkit-keyframes bubble-left-v {
  0% {
    margin-bottom: 0;
    opacity: .1
  }
  20% {
    opacity: 1
  }
  100% {
    margin-bottom: 460px;
    opacity: 1;
    left: 0
  }
}

@keyframes bubble-left-v {
  0% {
    margin-bottom: 0;
    opacity: .1
  }
  20% {
    opacity: 1
  }
  100% {
    margin-bottom: 460px;
    opacity: 1;
    left: 0
  }
}

@-webkit-keyframes shake-v {
  0% {
    margin-left: 0
  }
  100% {
    margin-left: 50px
  }
}

@keyframes shake-v {
  0% {
    margin-left: 0
  }
  100% {
    margin-left: 50px
  }
}

.animation {
  position: relative;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d
}

.typed-cursor {
  opacity: 1;
  -webkit-animation: blink .7s infinite;
  animation: blink .7s infinite
}

@-webkit-keyframes blink {
  0% {
    opacity: 1
  }
  50% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

@keyframes blink {
  0% {
    opacity: 1
  }
  50% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

@-webkit-keyframes jump {
  0% {
    top: 0
  }
  50% {
    top: 50px
  }
  100% {
    top: 0
  }
}

@keyframes jump {
  0% {
    top: 0
  }
  50% {
    top: 50px
  }
  100% {
    top: 0
  }
}

.input-group-register {
  position: relative
}

.input-group-register label {
  position: absolute;
  bottom: -4px;
  margin-bottom: 0
}

.input-group-register .form-control {
  padding: 2.5rem
}

.input-group-register .btn {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 15px
}

main {
  -webkit-transition: -webkit-transform .4s ease 0s;
  transition: -webkit-transform .4s ease 0s;
  transition: transform .4s ease 0s;
  transition: transform .4s ease 0s, -webkit-transform .4s ease 0s
}

.navigation {
  background-color: #fff;
  min-height: 90px;
  -webkit-transition: all .3s ease 0s;
  transition: all .3s ease 0s
}

@media (max-width: 767.98px) {
  .navigation .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
  }
  .navigation .dropdown-item:before {
    content: '';
    background-color: #646f79;
    padding: 3px;
    left: 0;
    border-radius: 50%;
    opacity: .5
  }
  .navigation .dropdown-item:before {
    position: absolute;
    top: 12px
  }
  .navigation .dropdown-heading {
    padding-left: 0 !important
  }
}

.navigation .navbar-toggler {
  -ms-flex-item-align: center;
  align-self: center
}

.navigation .navbar-toggler .icon-bar {
  -webkit-transform: rotate(0);
  transform: rotate(0);
  background-color: #9f55ff;
  -webkit-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out;
  display: block;
  width: 24px;
  height: 2px
}

.navigation .navbar-toggler .icon-bar + .icon-bar {
  margin-top: 6px
}

.navigation .navbar-toggler .icon-bar:hover {
  background-color: #7409ff
}

.navigation .dropdown-heading {
  margin: 0;
  padding: .5rem 1.5rem;
  font-weight: 700;
  position: relative
}

.navigation.fixed-top .navbar-collapse {
  height: 100vh
}

.navigation.navbar-expanded.sidebar-left .collapse {
  left: 0
}

.navigation.navbar-expanded.sidebar-left .navbar-brand, .navigation.navbar-expanded.sidebar-left .navbar-toggler, .navigation.navbar-expanded.sidebar-left ~ main {
  -webkit-transform: translateX(250px);
  transform: translateX(250px)
}

.navigation.navbar-expanded.sidebar-right .collapse {
  right: 0
}

.navigation.navbar-expanded.sidebar-right .navbar-brand, .navigation.navbar-expanded.sidebar-right .navbar-toggler, .navigation.navbar-expanded.sidebar-right ~ main {
  -webkit-transform: translateX(-250px);
  transform: translateX(-250px)
}

.navigation.navbar-expanded .icon-bar:nth-child(1) {
  -webkit-transform: rotate(-225deg);
  transform: rotate(-225deg)
}

.navigation.navbar-expanded .icon-bar:nth-child(2) {
  -webkit-transform: translateX(-40px);
  transform: translateX(-40px);
  opacity: 0
}

.navigation.navbar-expanded .icon-bar:nth-child(3) {
  -webkit-transform: translateY(-15px) rotate(225deg);
  transform: translateY(-15px) rotate(225deg)
}

.navigation.sidebar-left .collapse {
  left: -250px;
  border-right: 1px solid #edf4f8
}

.navigation.sidebar-right .collapse {
  right: -250px;
  border-left: 1px solid #edf4f8
}

.navigation.sidebar-right .navbar-toggler {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2
}

.navigation.sidebar-right .navbar-brand {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1
}

.navigation .collapse {
  -webkit-transition: left .4s ease 0s;
  transition: left .4s ease 0s;
  background-color: #fff;
  width: 250px;
  position: fixed;
  margin: 0;
  top: 0;
  bottom: 0;
  display: block
}

@media (max-width: 767.98px) {
  .navigation .collapse {
    overflow-y: auto
  }
}

.navigation .logo {
  max-height: 60px
}

.navigation .dropdown-menu {
  font-size: .875rem;
  padding: 1rem 0;
  border: none;
}

.navigation .dropdown-menu .dropdown-item {
  color: #4b535b;
  position: relative
}

.navigation .dropdown .fa-bars {
  color:white;
}

.navigation.navbar-sticky .dropdown .fa-bars,
.navigation.navbar-static .dropdown .fa-bars {
  color: #9f55ff;
}
.navigation .dropdown-menu .dropdown-item.disabled {
  opacity: .4
}

.navigation .sidebar-brand {
  padding: 1rem
}

.navigation .nav-link {
  color: #9f55ff;
  font-family: Poppins, sans-serif;
  font-size: .9rem;
  font-weight: 700;
  -webkit-transition: color .3s ease-in-out, background-color .3s ease-in-out, opacity .3s ease-in-out;
  transition: color .3s ease-in-out, background-color .3s ease-in-out, opacity .3s ease-in-out;
  text-decoration: none;
  letter-spacing: 1px;
  padding: 1rem
}

.navigation .nav-link i {
  display: inline-block;
  margin-right: 15px
}

.navigation .nav-link.active, .navigation .nav-link:hover {
  color: #fff
}

@media (max-width: 767.98px) {
  .navigation .nav-link.active, .navigation .nav-link:hover {
    background: #9f55ff
  }
}

.navigation.navbar-sticky {
  position: fixed !important;
  background-color: #fff;
  left: 0;
  top: 0;
  right: 0;
  border-bottom: 1px solid rgba(0, 0, 0, .11);
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .11);
  box-shadow: 0 0 5px rgba(0, 0, 0, .11);
  min-height: 90px;
  z-index: 1030;
  -webkit-transition: all .6s ease-in-out;
  transition: all .6s ease-in-out
}

.navigation .btn-outline,
.navigation.navbar-sticky,
.navigation.navbar-static {
  .btn-outline {
    color: #9f55ff;
    border-color: #9f55ff
  }

  .btn-outline:hover {
    color: #fff;
    background-color: #9f55ff;
    border-color: #9f55ff;
    -webkit-box-shadow: 0 1px 10px rgba(159, 85, 255, .4) !important;
    box-shadow: 0 1px 10px rgba(159, 85, 255, .4) !important
  }

  .btn-outline.focus,
  .btn-outline:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(159, 85, 255, .5);
    box-shadow: 0 0 0 .2rem rgba(159, 85, 255, .5)
  }

  .btn-outline.disabled,
  .btn-outline:disabled {
    -webkit-box-shadow: 0 0 0 .2rem rgba(159, 85, 255, .5);
    box-shadow: 0 0 0 .2rem rgba(159, 85, 255, .5)
  }

  .btn-outline:not(:disabled):not(.disabled).active,
  .btn-outline:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #9f55ff;
    border-color: #9f55ff
  }

  .btn-outline:not(:disabled):not(.disabled).active:focus,
  .btn-outline:not(:disabled):not(.disabled):active:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(159, 85, 255, .5);
    box-shadow: 0 0 0 .2rem rgba(159, 85, 255, .5)
  }

  .btn-solid {
    color: #fff;
    background-color: #9f55ff;
    border-color: #9f55ff
  }

  .btn-solid:hover {
    color: #fff;
    background-color: #892fff;
    border-color: #8222ff;
    -webkit-box-shadow: 0 1px 10px rgba(159, 85, 255, .4) !important;
    box-shadow: 0 1px 10px rgba(159, 85, 255, .4) !important
  }

  .btn-solid.focus,
  .btn-solid:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(159, 85, 255, .5);
    box-shadow: 0 0 0 .2rem rgba(159, 85, 255, .5)
  }

  .btn-solid.disabled,
  .btn-solid:disabled {
    color: #fff;
    background-color: #9f55ff;
    border-color: #9f55ff
  }

  .btn-solid:not(:disabled):not(.disabled).active,
  .btn-solid:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #9f55ff;
    border-color: #9f55ff
  }

  .btn-solid:not(:disabled):not(.disabled).active:focus,
  .btn-solid:not(:disabled):not(.disabled):active:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(159, 85, 255, .5);
    box-shadow: 0 0 0 .2rem rgba(159, 85, 255, .5)
  }
}


.navigation.navbar-sticky .logo {
  display: none
}

.navigation.navbar-sticky .logo.logo-sticky {
  display: block !important
}

.navigation.navbar-sticky .logo.logo-sticky + .logo {
  display: none !important
}

@media (min-width: 768px) {
  .navigation {
    background-color: #fff;
    padding: 0
  }
  .navigation:not(.fixed-top) {
    position: static;
    top: -90px
  }
  .navigation.fixed-top {
    background-color: transparent
  }
  .navigation.fixed-top .navbar-collapse {
    height: auto
  }
  .navigation.fixed-top .nav-link {
    color: #fff
  }
  .navigation.fixed-top .nav-link.active, .navigation.fixed-top .nav-link:hover {
    color: #d9d9d9
  }
  .navigation.navbar-sticky {
    background-color: #fff;
    top: 0
  }
  .navigation.navbar-sticky .nav-link {
    color: #646f79
  }
  .navigation.navbar-sticky .nav-link.active, .navigation.navbar-sticky .nav-link:hover {
    color: #9f55ff
  }
  .navigation.navbar-expanded .navbar-brand, .navigation.navbar-expanded .navbar-toggler, .navigation.navbar-expanded ~ main {
    -webkit-transform: translateX(0) !important;
    transform: translateX(0) !important
  }
  .navigation.sidebar-left .collapse {
    left: 0;
    border-right: none
  }
  .navigation.sidebar-right .collapse {
    right: 0;
    border-left: none
  }
  .navigation.sidebar-right .navbar-brand {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0
  }
  .navigation.dark-link .nav-link {
    color: #101480;
    opacity: .7
  }
  .navigation.dark-link .nav-link.active, .navigation.dark-link .nav-link:hover {
    opacity: 1;
    color: #08093c
  }
  .navigation.dark-link .btn-outline {
    color: #9f55ff;
    border-color: #9f55ff
  }
  .navigation.dark-link .btn-outline:hover {
    color: #fff;
    background-color: #9f55ff;
    border-color: #9f55ff
  }
  .navigation.dark-link .btn-outline.focus, .navigation.dark-link .btn-outline:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(159, 85, 255, .5);
    box-shadow: 0 0 0 .2rem rgba(159, 85, 255, .5)
  }
  .navigation.dark-link .btn-outline.disabled, .navigation.dark-link .btn-outline:disabled {
    color: #9f55ff;
    background-color: transparent
  }
  .navigation.dark-link .btn-outline:not(:disabled):not(.disabled).active, .navigation.dark-link .btn-outline:not(:disabled):not(.disabled):active, .show > .navigation.dark-link .btn-outline.dropdown-toggle {
    color: #fff;
    background-color: #9f55ff;
    border-color: #9f55ff
  }
  .navigation.dark-link .btn-outline:not(:disabled):not(.disabled).active:focus, .navigation.dark-link .btn-outline:not(:disabled):not(.disabled):active:focus, .show > .navigation.dark-link .btn-outline.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(159, 85, 255, .5);
    box-shadow: 0 0 0 .2rem rgba(159, 85, 255, .5)
  }
  .navigation.dark-link .btn-solid {
    color: #fff;
    background-color: #9f55ff;
    border-color: #9f55ff
  }
  .navigation.dark-link .btn-solid:hover {
    color: #fff;
    background-color: #892fff;
    border-color: #8222ff
  }
  .navigation.dark-link .btn-solid.focus, .navigation.dark-link .btn-solid:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(173, 111, 255, .5);
    box-shadow: 0 0 0 .2rem rgba(173, 111, 255, .5)
  }
  .navigation.dark-link .btn-solid.disabled, .navigation.dark-link .btn-solid:disabled {
    color: #fff;
    background-color: #9f55ff;
    border-color: #9f55ff
  }
  .navigation.dark-link .btn-solid:not(:disabled):not(.disabled).active, .navigation.dark-link .btn-solid:not(:disabled):not(.disabled):active, .show > .navigation.dark-link .btn-solid.dropdown-toggle {
    color: #fff;
    background-color: #8222ff;
    border-color: #7b15ff
  }
  .navigation.dark-link .btn-solid:not(:disabled):not(.disabled).active:focus, .navigation.dark-link .btn-solid:not(:disabled):not(.disabled):active:focus, .show > .navigation.dark-link .btn-solid.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(173, 111, 255, .5);
    box-shadow: 0 0 0 .2rem rgba(173, 111, 255, .5)
  }
  .navigation .collapse {
    position: relative;
    width: auto;
    background: 0 0
  }
  .navigation .nav-link {
    color: #41494f;
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important
  }
  .navigation .nav-link i {
    display: none
  }
  .navigation .nav-link.active, .navigation .nav-link:hover {
    color: #363c41
  }
  .navigation .dropdown.show .dropdown-toggle {
    color: #d9d9d9
  }
  .navigation .sidebar-brand {
    display: none
  }
  .navigation .btn-outline {
    color: #fff;
    border-color: #fff;
    color: #fff
  }
  .navigation .btn-outline:hover {
    color: #212529;
    background-color: #fff;
    border-color: #fff
  }
  .navigation .btn-outline.focus, .navigation .btn-outline:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
    box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5)
  }
  .navigation .btn-outline.disabled, .navigation .btn-outline:disabled {
    color: #fff;
    background-color: transparent
  }
  .navigation .btn-outline:not(:disabled):not(.disabled).active, .navigation .btn-outline:not(:disabled):not(.disabled):active, .show > .navigation .btn-outline.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #fff
  }
  .navigation .btn-outline:not(:disabled):not(.disabled).active:focus, .navigation .btn-outline:not(:disabled):not(.disabled):active:focus, .show > .navigation .btn-outline.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
    box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5)
  }
  .navigation .btn-solid {
    color: #212529;
    background-color: #fff;
    border-color: #fff
  }
  .navigation .btn-solid:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6
  }
  .navigation .btn-solid.focus, .navigation .btn-solid:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(222, 222, 223, .5);
    box-shadow: 0 0 0 .2rem rgba(222, 222, 223, .5)
  }
  .navigation .btn-solid.disabled, .navigation .btn-solid:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #fff
  }
  .navigation .btn-solid:not(:disabled):not(.disabled).active, .navigation .btn-solid:not(:disabled):not(.disabled):active, .show > .navigation .btn-solid.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf
  }
  .navigation .btn-solid:not(:disabled):not(.disabled).active:focus, .navigation .btn-solid:not(:disabled):not(.disabled):active:focus, .show > .navigation .btn-solid.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(222, 222, 223, .5);
    box-shadow: 0 0 0 .2rem rgba(222, 222, 223, .5)
  }
  .navigation .dropdown-menu {
    margin-top: -10px;
    border: 1px solid #edf4f8;
    min-width: 100%;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .075);
    box-shadow: 0 0 10px rgba(0, 0, 0, .075)
  }
  .navigation.navbar-sticky .dropdown-menu {
    margin-top: -3px
  }
}

.nav-pills-light .nav-link {
  opacity: .7;
  color: #1f2225;
  border: 1px solid transparent
}

.nav-pills-light .nav-link.active, .nav-pills-light .nav-link:hover {
  opacity: 1
}

.nav-pills-light .nav-link.active {
  background-color: #fff;
  color: #1f2225;
  -webkit-box-shadow: 0 0 64px rgba(188, 212, 231, .5);
  box-shadow: 0 0 64px rgba(188, 212, 231, .5);
  border: 1px solid #edf4f8
}

.nav-pills-outline .nav-link {
  border-radius: 0;
  padding: .5rem;
  position: relative
}

.nav-pills-outline .nav-link:after {
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: .3s transform ease;
  transition: .3s transform ease;
  content: '';
  height: 2px;
  background-color: #007bff;
  position: absolute;
  left: 25%;
  bottom: 0;
  width: 50%
}

.nav-pills-outline .nav-link.active {
  background-color: #fff;
  color: #007bff
}

.nav-pills-outline .nav-link.active:after {
  -webkit-transform: scale(1);
  transform: scale(1)
}

.st-nav {
  z-index: 500;
  -webkit-perspective: 2000px;
  perspective: 2000px
}

.st-nav ul {
  padding: 0;
  margin: 0
}

.st-nav li {
  list-style: none
}

.st-nav a {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  color: #6800ee;
  -webkit-transition: color .1s;
  transition: color .1s
}

.st-nav a:hover {
  color: #004a99
}

.st-nav .st-nav-menu {
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1
}

@media (min-width: 768px) {
  .st-nav .st-nav-menu {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
  }
}

.st-nav .st-nav-section.logo {
  position: absolute;
  top: 0;
  left: 0
}

.st-nav .st-nav-section.st-nav-primary, .st-nav .st-nav-section.st-nav-secondary {
  display: none
}

.st-nav .st-nav-section.st-nav-primary {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.st-nav .st-nav-section.st-nav-secondary {
  margin-left: auto
}

.st-nav .st-nav-section.st-nav-mobile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end
}

.st-nav .dropdown-item {
  font-weight: 300;
  padding-left: 0;
  padding-right: 0
}

.st-nav .dropdown-item:hover {
  background-color: transparent
}

.st-nav .st-root-link {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap
}

.st-nav .st-nav-section.logo .st-root-link {
  padding-left: 20px !important
}

.st-nav .st-nav-section.st-nav-secondary .st-root-link:last-child {
  padding-right: 20px !important
}

.st-nav .st-nav-section.st-nav-primary .st-root-link {
  font-weight: 400
}

.st-nav .st-has-dropdown {
  cursor: default
}

.st-nav .st-dropdown-root {
  position: absolute;
  z-index: 1000;
  left: 0;
  right: 0;
  top: 70px;
  pointer-events: none;
  -webkit-transform: rotateX(-15deg);
  transform: rotateX(-15deg);
  -webkit-transform-origin: 50% -50px;
  transform-origin: 50% -50px;
  opacity: 0;
  will-change: transform, opacity;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transition-duration: .25s;
  transition-duration: .25s;
  display: none
}

.st-nav.dropdown-active .st-dropdown-root {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: none;
  transform: none
}

.st-nav .st-dropdown-bg {
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  -webkit-box-shadow: 0 50px 100px rgba(16, 20, 128, .1), 0 15px 35px rgba(16, 20, 128, .15), 0 5px 15px rgba(0, 0, 0, .1);
  box-shadow: 0 50px 100px rgba(16, 20, 128, .1), 0 15px 35px rgba(16, 20, 128, .15), 0 5px 15px rgba(0, 0, 0, .1);
  width: 520px;
  height: 400px;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0
}

.st-nav .st-alt-bg, .st-nav .st-dropdown-bg {
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: .25s;
  transition-duration: .25s
}

.st-nav .st-alt-bg {
  right: 0;
  height: 1000px;
  background: #f6f9fc
}

.st-nav .st-dropdown-arrow {
  top: -6px;
  margin: 0 0 0 -6px;
  width: 12px;
  height: 12px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 4px 0 0 0;
  background: #fff;
  -webkit-box-shadow: -3px -3px 5px rgba(82, 95, 127, .04);
  box-shadow: -3px -3px 5px rgba(82, 95, 127, .04);
  will-change: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  position: absolute;
  left: 0;
  -webkit-transition-duration: .25s;
  transition-duration: .25s
}

.st-nav .st-dropdown-container {
  position: absolute;
  left: 0;
  -webkit-transition-duration: .25s;
  transition-duration: .25s;
  overflow: hidden;
  top: 0;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  will-change: transform, width, height;
  -webkit-transition-property: width, height, -webkit-transform;
  transition-property: width, height, -webkit-transform;
  transition-property: transform, width, height;
  transition-property: transform, width, height, -webkit-transform
}

.st-nav .st-dropdown-section {
  opacity: 0;
  pointer-events: none;
  will-change: transform, opacity;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transition-duration: .25s;
  transition-duration: .25s;
  background: #fff
}

.st-nav .st-dropdown-section.active {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0)
}

.st-nav .st-dropdown-section.left {
  -webkit-transform: translateX(-150px);
  transform: translateX(-150px)
}

.st-nav .st-dropdown-section.right {
  -webkit-transform: translateX(150px);
  transform: translateX(150px)
}

.st-nav.dropdown-active .st-dropdown-section.active {
  pointer-events: auto
}

.st-nav.no-dropdown-transition .st-alt-bg, .st-nav.no-dropdown-transition .st-dropdown-arrow, .st-nav.no-dropdown-transition .st-dropdown-bg, .st-nav.no-dropdown-transition .st-dropdown-container, .st-nav.no-dropdown-transition .st-dropdown-section {
  -webkit-transition: none;
  transition: none
}

.st-nav .st-dropdown-content {
  position: absolute
}

.st-nav .st-dropdown-content-group {
  padding: 20px 35px
}

.st-nav .st-dropdown-content-group .row {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  white-space: nowrap
}

.st-nav .st-dropdown-content-group h4 {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: .025em;
  margin: 0 0 .5rem;
  color: #6c757d
}

.st-nav .link-title {
  margin: 0 0 .5rem;
  color: #101480;
  font-size: 1rem;
  line-height: 22px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: .025em
}

.st-nav .st-nav-section.st-nav-mobile .st-root-link {
  cursor: pointer;
  position: relative
}

.st-nav .st-nav-section.st-nav-mobile .st-root-link .icon-bar {
  background-color: #6800ee
}

.st-nav .st-popup {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  pointer-events: none;
  -webkit-perspective: 2000px;
  perspective: 2000px
}

.st-nav .st-popup-container {
  background: #fff;
  -webkit-box-shadow: 0 50px 100px rgba(16, 20, 128, .1), 0 15px 35px rgba(16, 20, 128, .15), 0 5px 15px rgba(0, 0, 0, .1);
  box-shadow: 0 50px 100px rgba(16, 20, 128, .1), 0 15px 35px rgba(16, 20, 128, .15), 0 5px 15px rgba(0, 0, 0, .1);
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  font-size: 17px;
  line-height: 40px;
  white-space: nowrap;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-transform: rotate3d(1, 1, 0, -15deg);
  transform: rotate3d(1, 1, 0, -15deg);
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
  opacity: 0;
  will-change: transform, opacity;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transition-duration: .25s;
  transition-duration: .25s
}

.st-nav .st-nav-section.st-nav-mobile.st-popup-active .st-popup-container {
  -webkit-transform: none;
  transform: none;
  opacity: 1;
  pointer-events: auto
}

.st-nav .st-popup a {
  display: block
}

.st-nav .st-popup-close-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 51px;
  height: 51px;
  font-size: 0;
  cursor: pointer
}

.st-nav .st-popup-close-button:after, .st-nav .st-popup-close-button:before {
  content: '';
  position: absolute;
  background: #6800ee;
  border-radius: 1px;
  left: 14px;
  right: 14px;
  top: 24px;
  height: 3px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: background .1s;
  transition: background .1s
}

.st-nav .st-popup-close-button:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

@media (min-width: 768px) {
  .st-nav .st-nav-section.st-nav-primary, .st-nav .st-nav-section.st-nav-secondary {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
  }
  .st-nav .st-nav-section.st-nav-mobile {
    display: none
  }
  .st-nav .st-dropdown-root {
    display: block
  }
}

.cc-theme-custom.cc-window {
  color: #fff
}

.cc-theme-custom.cc-banner .cc-message {
  width: auto;
  background: #028fff;
  padding: 20px 76px 20px 16px;
  border-radius: 10px;
  margin-right: -70px;
  margin-left: 10%
}

.cc-theme-custom .cc-link, .cc-theme-custom .cc-link:visited {
  color: red
}

.cc-theme-custom .cc-btn {
  background: #00f;
  background: radial-gradient(ellipse at center, #00f 0, #99007c 100%);
  padding: 58px 0;
  color: red;
  border: 4px dotted red;
  border-radius: 100px;
  -webkit-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
  height: 150px;
  width: 150px;
  padding: 0;
  line-height: 10
}

.cc-theme-custom .cc-btn:hover {
  background: #99007c;
  -webkit-animation: none;
  animation: none
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg)
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

.icon-shape {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.icon-shape .shape, .icon-shape .shape-lg {
  max-width: 3.25rem
}

.icon-shape .shape-xl {
  max-width: 4.5rem
}

.icon-shape .shape-xxl {
  max-width: 6.5rem
}

.icon-shape .icon {
  margin: 0
}

.mockup-wrapper {
  position: relative
}

.mockup {
  position: relative;
  margin: 0 auto;
  max-width: 255px
}

.mockup img {
  display: block;
  max-width: 100%;
  height: auto
}

.list-icon {
  list-style-type: none
}

.list-icon li > * {
  display: table-cell
}

.list-icon li i {
  padding-right: 15px
}

.icon-block {
  padding: 15px;
  margin-bottom: 15px
}

.icon-block p {
  margin-top: 10px
}

.advanced-automation-solution {
  background: linear-gradient(-135deg, #53f 25%, #05d5ff 100%)
}

.advanced-automation-solution:before {
  content: '';
  position: absolute;
  width: 150%;
  height: 600px;
  left: 50%;
  top: 65%;
  -webkit-transform: translate3d(-50%, 10%, 0);
  transform: translate3d(-50%, 10%, 0);
  background-color: #fff;
  z-index: 0;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, .5), 0 0 125px 15px rgba(255, 255, 255, .5);
  box-shadow: 0 0 0 10px rgba(255, 255, 255, .5), 0 0 125px 15px rgba(255, 255, 255, .5)
}

@media (min-width: 576px) {
  .advanced-automation-solution:before {
    top: 55%
  }
}

@media (min-width: 992px) {
  .advanced-automation-solution:before {
    top: 50%
  }
}

.card-blog .author-picture {
  position: absolute;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  background-color: #fff;
  padding: 3px
}

.blog-single-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-size: auto 315px;
  background-position: center 5rem
}

.blog-single-header .container {
  margin-top: 18rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column
}

.blog-quote {
  margin-top: 3em;
  margin-bottom: 3em;
  padding: 0 4rem;
  position: relative
}

.blog-quote:before {
  font-size: 3.25rem;
  opacity: .2;
  left: 0;
  top: 0
}

.blog-quote p {
  font-size: 2rem;
  line-height: 1.3em;
  font-weight: 300
}

.blog-post h4 {
  margin-top: 2.5rem
}

.blog-post p {
  letter-spacing: .5px;
  margin: 1.25rem 0
}

.bubbles-wrapper {
  position: relative
}

.bubbles-wrapper .icon {
  position: absolute;
  will-change: transform, opacity;
  margin: 0
}

.bubbles-wrapper .bubble {
  -webkit-animation: bubble-animate-v 8s linear infinite;
  animation: bubble-animate-v 8s linear infinite
}

.extending-core .shape-background.shape-left {
  left: -320px;
  -webkit-box-shadow: 0 0 60px 0 rgba(100, 111, 121, .05);
  box-shadow: 0 0 60px 0 rgba(100, 111, 121, .05);
  max-width: 100%
}

.extending-core .icons-wrapper {
  min-height: 460px;
  margin-top: 3rem
}

@media (min-width: 768px) {
  .extending-core .icons-wrapper {
    margin-top: 0
  }
}

.extending-core .floating:nth-child(1) {
  -webkit-animation-duration: 3.6s;
  animation-duration: 3.6s
}

.extending-core .floating:nth-child(2) {
  -webkit-animation-duration: 3.4s;
  animation-duration: 3.4s
}

.extending-core .floating:nth-child(3) {
  -webkit-animation-duration: 3.8s;
  animation-duration: 3.8s
}

.extending-core .floating:nth-child(4) {
  -webkit-animation-duration: 2.8s;
  animation-duration: 2.8s
}

.extending-core .floating:nth-child(5) {
  -webkit-animation-duration: 4.2s;
  animation-duration: 4.2s
}

.extending-core .floating:nth-child(6) {
  -webkit-animation-duration: 3.2s;
  animation-duration: 3.2s
}

.extending-core .floating:nth-child(7) {
  -webkit-animation-duration: 4s;
  animation-duration: 4s
}

.extending-core .floating:nth-child(8) {
  -webkit-animation-duration: 1.8s;
  animation-duration: 1.8s
}

@media (max-width: 767.98px) {
  .extending-core {
    overflow: hidden
  }
}

@media (min-width: 768px) {
  .get-started .floating-box {
    position: absolute;
    top: 75%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    left: 50%;
    max-width: 592px;
    width: 90%
  }
}

@media (min-width: 992px) {
  .get-started .floating-box {
    top: auto;
    -webkit-transform: none;
    transform: none;
    width: 60%;
    left: 0;
    bottom: 1.5%
  }
}

@media (min-width: 1200px) {
  .get-started .floating-box {
    width: 50%
  }
}

.get-started .play-video {
  display: none
}

@media (min-width: 992px) {
  .get-started .play-video {
    position: absolute;
    height: 10em;
    z-index: 1;
    bottom: 14.5%;
    right: 6.5%;
    width: 10em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
  }
}

.get-started .browser {
  display: none
}

@media (min-width: 768px) {
  .get-started .browser {
    display: block
  }
}

@media (min-width: 992px) {
  .get-started .browser {
    width: 80%
  }
}

.section, section {
  position: relative
}

.section [class^=container], section [class^=container] {
  padding-top: 45px;
  padding-bottom: 45px;
  position: relative
}

.section [class^=container].bring-to-front, section [class^=container].bring-to-front {
  z-index: 9
}

@media (min-width: 768px) {
  .section [class^=container], section [class^=container] {
    padding-top: 90px;
    padding-bottom: 90px
  }
}

.section .container-wide, section .container-wide {
  overflow-x: hidden
}

.section .container-wide .row, section .container-wide .row {
  margin-left: 0 !important;
  margin-right: 0 !important
}

.section.shadow, section.shadow {
  z-index: 1
}

.section-heading {
  margin-bottom: 60px;
  position: relative
}

.section-heading h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 2.875rem
}

.section-heading p {
  font-weight: 300
}

@media (min-width: 768px) {
  .partners img {
    opacity: .5;
    -webkit-transform: scale(.8);
    transform: scale(.8);
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
  }
  .partners img:hover {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

.centered-screen {
  position: relative
}

.centered-screen .screen-highlight {
  bottom: 45px
}

.centered-screen .message {
  bottom: 130px;
  z-index: 1
}

.send-and-receive .off-left-background {
  background-position: -30px 20px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.send-and-receive .off-left-background:hover {
  background-position: -30px 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.shape-testimonials .shape-wrapper {
  display: none
}

@media (min-width: 576px) {
  .shape-testimonials .shape-wrapper {
    display: block
  }
}

.shape-testimonials blockquote {
  margin-top: 10rem;
  color: #41494f
}

.shape-testimonials blockquote:before {
  font-size: 6.5rem;
  left: 2px;
  top: -60%
}

@media (min-width: 576px) {
  .shape-testimonials blockquote {
    margin-top: 15rem;
    color: #fff
  }
  .shape-testimonials blockquote:before {
    top: -80%
  }
}

@media (min-width: 768px) {
  .shape-testimonials blockquote {
    margin-top: 10rem
  }
}

@media (max-width: 575.98px) {
  .shape-testimonials blockquote {
    margin-top: 0;
    padding: 2rem;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid #edf4f8
  }
  .shape-testimonials blockquote:before {
    top: 0;
    left: 5px;
    opacity: .2 !important
  }
}

.shape-testimonials .shape-wrapper .shape {
  width: 100%
}

@media (min-width: 768px) {
  .shape-testimonials .shape-wrapper .shape {
    width: auto;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    left: 50%;
    position: absolute
  }
}

.user-reviews {
  min-height: 450px;
  position: relative
}

.user-reviews .user {
  width: 48px;
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease;
  cursor: pointer
}

.user-reviews .user.active {
  -webkit-transform: scale(1.5);
  transform: scale(1.5)
}

.features-carousel {
  background-color: #f9fbfd
}

.features-carousel .swiper-container {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem
}

@media (min-width: 576px) {
  .features-carousel .swiper-container:after {
    content: '';
    display: block;
    position: absolute;
    top: 2.5rem;
    width: 25%;
    z-index: 2;
    left: 75%;
    bottom: 0;
    background: -webkit-gradient(linear, right top, left top, color-stop(10%, #f9fbfd), to(rgba(249, 251, 253, .1)));
    background: linear-gradient(to left, #f9fbfd 10%, rgba(249, 251, 253, .1))
  }
}

.features-carousel .features-nav-next {
  position: absolute;
  top: 0;
  right: 5px;
  margin: 0;
  z-index: 3;
  cursor: pointer;
  padding-right: 1rem
}

.features-carousel .features-nav-next .features-nav-icon {
  display: inline-block;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: all .4s ease-out;
  transition: all .4s ease-out
}

.features-carousel .features-nav-next .features-nav-icon:after {
  font-family: $fa-style-family;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 900;
  content: "\f30b"
}

.features-carousel .features-nav-next:hover {
  color: #028fff
}

.features-carousel .features-nav-next:hover .features-nav-icon {
  -webkit-transform: translateX(1rem);
  transform: translateX(1rem)
}

.singl-testimonial .image-background + .text {
  margin-bottom: 3rem
}

@media (min-width: 768px) {
  .singl-testimonial .image-background + .text {
    margin-bottom: 0
  }
}

.singl-testimonial .image-background {
  min-height: 390px
}

.singl-testimonial .testimonial-img {
  max-width: 70%
}

.singl-testimonial .testimonial-img.decorated {
  position: relative
}

.singl-testimonial .testimonial-img.decorated:before {
  position: absolute;
  content: "";
  width: 50%;
  height: 50%;
  top: 0;
  left: 0;
  background-color: #9f55ff;
  -webkit-transform: translate3d(-25%, -25%, 0);
  transform: translate3d(-25%, -25%, 0);
  z-index: -1;
  border-radius: .25rem
}

.singl-testimonial .user-review {
  overflow: hidden;
  -webkit-transform: translateY(-20%);
  transform: translateY(-20%)
}

@media (min-width: 992px) {
  .singl-testimonial .user-review {
    -webkit-transform: translate(-25%, 25%);
    transform: translate(-25%, 25%)
  }
}

.singl-testimonial .user-review blockquote:before {
  -webkit-transform: translate(-70%, 0);
  transform: translate(-70%, 0);
  font-size: 6rem;
  opacity: .3
}

@media (min-width: 992px) {
  .singl-testimonial .user-review blockquote {
    font-size: 1.25rem
  }
}

.singl-testimonial .user-review .shape-wrapper {
  z-index: -1
}

.singl-testimonial .user-review .svg-review-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  -webkit-transform: translateX(-45%);
  transform: translateX(-45%)
}

.singl-testimonial .user-review .svg-review-bottom.back {
  -webkit-transform: translate(-49%, -1%) scale(1.1);
  transform: translate(-49%, -1%) scale(1.1);
  z-index: 0
}

.singl-testimonial .user-review .svg-review-bottom.left {
  -webkit-transform: rotate(-45deg) translate(-146%, -143%) scale(1.7);
  transform: rotate(-45deg) translate(-146%, -143%) scale(1.7);
  z-index: -1
}

.singl-testimonial .reviews-navigation {
  position: absolute;
  top: 90%;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  margin-top: 2rem
}

.singl-testimonial .reviews-navigation .reviews-nav {
  cursor: pointer;
  background-color: #9f55ff;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all .3s ease;
  transition: all .3s ease
}

@media (min-width: 992px) {
  .singl-testimonial .reviews-navigation .reviews-nav {
    background-color: #f4f8fb;
    color: #41494f
  }
  .singl-testimonial .reviews-navigation .reviews-nav:hover {
    background-color: #9f55ff;
    color: #fff
  }
}

.singl-testimonial .reviews-navigation .reviews-nav-prev {
  margin-right: 2rem
}

@media (max-width: 991.98px) {
  .singl-testimonial .reviews-navigation {
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
  }
}

@media (min-width: 992px) {
  .singl-testimonial .reviews-navigation {
    top: 10px;
    right: 13%
  }
}

.signature {
  max-width: 140px
}

.lightweight-template .learn-more {
  margin-bottom: 18rem
}

.perspective-mockups {
  pointer-events: none;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  left: 50%;
  margin-left: 85px;
  -webkit-transform: scale(.5) rotate(12deg) translateX(-50%);
  transform: scale(.5) rotate(12deg) translateX(-50%);
  -webkit-transform-origin: 0 20%;
  transform-origin: 0 20%;
  z-index: 3
}

.perspective-mockups > div {
  margin: 0 50px
}

.perspective-mockups .ipad {
  max-width: 100%
}

.perspective-mockups.hidden-preload {
  visibility: hidden
}

.perspective-mockups .phone-big {
  display: none
}

.perspective-mockups .phone-small {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
  width: 225px
}

.perspective-mockups .tablet.landscape {
  width: 512px;
  margin: 0
}

.perspective-mockups .tablet.portrait {
  width: 450px;
  margin-top: 0
}

@media (min-width: 576px) {
  .perspective-mockups {
    margin-left: 0
  }
}

@media (min-width: 768px) {
  .lightweight-template .learn-more {
    margin-bottom: 0
  }
  .perspective-mockups {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 512px;
    margin-left: -50px;
    top: 215px;
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%
  }
  .perspective-mockups.hidden-preload {
    visibility: visible
  }
  .perspective-mockups .tablet {
    margin: 0 !important;
    max-width: 100%
  }
  .perspective-mockups .tablet.portrait {
    display: none
  }
  .perspective-mockups .tablet.landscape {
    -webkit-transform: translateX(25%);
    transform: translateX(25%);
    width: 512px
  }
  .perspective-mockups .phone-small {
    margin: 0 0 50px 176px
  }
}

@media (min-width: 1200px) {
  .perspective-mockups {
    width: 829px;
    margin-left: -10px;
    top: 100px
  }
  .perspective-mockups > div {
    margin: 0 auto
  }
  .perspective-mockups .tablet.landscape {
    -ms-flex-item-align: end;
    align-self: flex-end;
    margin-right: 50px;
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
  .perspective-mockups .tablet.portrait {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 450px;
    margin-top: 50px !important
  }
  .perspective-mockups .phone-big {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 267px
  }
  .perspective-mockups .phone-small {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    margin: 50px 50px 0 0
  }
}

.screenshots .swiper-container {
  padding: 3.58rem 0 6rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.screenshots .swiper-container .mobile-device {
  max-width: 240px;
  position: absolute;
  left: 0;
  right: 0
}

.screenshots .swiper-container .mobile-device .screen {
  width: 212px;
  height: 460px
}

.screenshots .swiper-container .mobile-device.iphone-x .notch {
  z-index: 2
}

.screenshots .swiper-container .mobile-device.iphone-x:after, .screenshots .swiper-container .mobile-device.iphone-x:before {
  z-index: 3
}

.screenshots .swiper-container .swiper-slide {
  -webkit-transform: scale(.75);
  transform: scale(.75);
  opacity: .6;
  -webkit-transition: all .3s ease;
  transition: all .3s ease
}

.screenshots .swiper-container .swiper-slide img {
  border-radius: 13px;
  -webkit-box-shadow: 0 0 8px 5px rgba(0, 0, 0, .015);
  box-shadow: 0 0 8px 5px rgba(0, 0, 0, .015)
}

.screenshots .swiper-container .swiper-slide-next, .screenshots .swiper-container .swiper-slide-prev {
  -webkit-transform: scale(.85);
  transform: scale(.85);
  opacity: .85
}

.screenshots .swiper-container .swiper-slide-active {
  border-radius: 13px;
  -webkit-box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
  border: 1px solid #edf4f8;
  z-index: 100;
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1
}

.screenshots .swiper-container > .swiper-pagination-bullets {
  bottom: 4rem
}

.site-footer {
  font-size: .9em
}

.site-footer h4 {
  margin-bottom: 15px
}

.site-footer .logo {
  max-height: 56px
}

.why-us .shape-wrapper {
  overflow: visible
}

.why-us .shape-background.right {
  max-width: 70%;
  -webkit-transform: rotate(45deg) translate(50%, -50%);
  transform: rotate(45deg) translate(50%, -50%);
  background-color: #d9bbff
}

.why-icon-list:before {
  content: "";
  width: 1px;
  border: 1px dashed #028fff;
  position: absolute;
  bottom: 80px;
  top: 50px;
  left: 50px;
  z-index: -1
}

.why-icon-list .list-item {
  margin-bottom: 4rem
}

.why-icon-list .list-item:last-child {
  margin-bottom: 0
}

.pricing p {
  line-height: 1.5em
}

.pricing .pricing-value {
  font-family: Poppins, sans-serif;
  font-size: 3.25rem;
  display: flex;
  flex-direction: column;
}

.pricing .pricing-value .price {
  position: relative
}

.pricing .pricing-value .price:after, .pricing .pricing-value .price:before {
  font-size: .35em;
  font-weight: 300;
  font-style: italic
}

.pricing .pricing-value .price:before {
  position: absolute;
  content: '$';
  top: 10px;
  left: -15px
}

.pricing .pricing-value .price:after {
  position: relative;
}

.pricing .pricing-value .price.per-mo-per-user:after {
  content: '/mo/user'
}

.pricing .pricing-value .price.per-mo:after {
  content: '/mo'
}

.pricing .pricing-value .price.per-minute:after {
    content: '/min'
}

.pricing .pricing-value .price.per-k-mails:after {
  content: '/1k emails'
}

.odometer-inside {
  display: inline
}

.pricing.heading .pricing-plan {
  padding: 0
}

.pricing.heading .pricing-plan .pricing-details {
  padding: 15px
}

.navigation.fixed-top + main .header {
  padding-top: 90px
}

.header {
  position: relative
}

.header .container {
  position: relative;
  margin: 0 auto
}

.header.fullscreen .mockup {
  max-width: 70%
}

.header .mockup-half {
  max-height: 350px
}

.header.page .container {
  padding-top: 90px;
  padding-bottom: 90px
}

.header .big-circle {
  background-color: transparent !important;
  overflow: hidden
}

.header .big-circle.bg-1:after {
  background-color: #fff
}

.header .big-circle.bg-2:after {
  background-color: #646f79
}

.header .big-circle.bg-3:after {
  background-color: #9f55ff
}

.header .big-circle.bg-4:after {
  background-color: #028fff
}

.header .big-circle.bg-5:after {
  background-color: #101480
}

.header .big-circle.bg-6:after {
  background-color: #f4f8fb
}

.header .big-circle:after {
  content: '';
  z-index: -1;
  position: absolute;
  border-radius: 50%;
  width: 150%;
  height: 150%;
  -webkit-transform: translate3D(-25%, -50%, 0);
  transform: translate3D(-25%, -50%, 0)
}

@media (min-width: 768px) {
  .header .big-circle:after {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%)
  }
}

.header-link {
  -webkit-transition: color .3s ease;
  transition: color .3s ease
}

@media (min-width: 768px) {
  .mobile-device[class*=absolute] {
    margin-top: -45px !important
  }
}

.header .mobile-swiper-pagination {
  left: 0;
  bottom: 1.5rem;
  width: 100% !important
}

.header .mobile-swiper-pagination .swiper-pagination-bullet {
  margin: 0 4px
}

.particles {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  padding: 0 1.5rem
}

.divider-shape {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
  bottom: -1px
}

.divider-shape svg {
  overflow: hidden;
  display: block;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: calc(159% + 1.3px);
  height: 433px
}

.divider-shape .shape-fill {
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transform: rotateY(0);
  transform: rotateY(0)
}

.overlay .divider-shape {
  z-index: 1
}

#stripes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(150deg, #53f 15%, #05d5ff 70%, #a6ffcb 94%);
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr)
}

#stripes :nth-child(1) {
  grid-area: 1/1/span 4/span 2;
  background-color: #53f;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 60%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 60%, 0 100%)
}

#stripes :nth-child(2) {
  grid-area: 1/3/span 3/span 2;
  background-color: #4553ff;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 74%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 74%, 0 100%);
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px)
}

#stripes :nth-child(3) {
  grid-area: 1/5/span 2/span 2;
  background-color: #4f40ff;
  -webkit-clip-path: polygon(0 0, 100% 0, 99% 5%, 0 70%);
  clip-path: polygon(0 0, 100% 0, 99% 5%, 0 70%)
}

#stripes :nth-child(4) {
  grid-area: 3/11/span 3/span 2;
  -webkit-clip-path: polygon(0 23%, 100% 0, 100% 77%, 0 100%);
  clip-path: polygon(0 23%, 100% 0, 100% 77%, 0 100%);
  background-color: #0dcfff
}

#stripes :nth-child(5) {
  grid-area: 8/1/span 5/span 4;
  -webkit-clip-path: polygon(0 23%, 100% 0, 100% 80%, 0 100%);
  clip-path: polygon(0 23%, 100% 0, 100% 80%, 0 100%);
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  background-color: #1fa2ff
}

#stripes + .container {
  padding-bottom: 11rem
}

.header.social-media-heading {
  position: relative
}

.header.social-media-heading .social-bg-shape {
  bottom: 20%
}

@media (min-width: 576px) {
  .header.social-media-heading .social-bg-shape {
    bottom: 10%
  }
}

@media (min-width: 768px) {
  .header.social-media-heading .social-bg-shape {
    max-width: 70%;
    right: 0
  }
}

@media (min-width: 992px) {
  .header.social-media-heading .social-bg-shape {
    top: 0
  }
}

@media (min-width: 1200px) {
  .header.social-media-heading .social-bg-shape {
    top: -100px;
    right: -100px
  }
}

@media (min-width: 768px) {
  .header.social-media-heading .social-image {
    max-width: 80%
  }
}

@media (min-width: 992px) {
  .header.social-media-heading .social-image {
    max-width: 100%
  }
}

.header-v6 {
  background-color: #f9fbfd
}

.header-v6 .container {
  padding-top: 12rem;
  padding-bottom: 6rem
}

@media (min-width: 992px) {
  .header-v6 .container {
    padding-bottom: 12rem
  }
}

.header-v6 .shape-background {
  z-index: 0;
  opacity: .2
}

.header-v6 .shape-top {
  top: 0;
  background-image: linear-gradient(-57deg, #4a61d1 0, #5c52d5 100%);
  -webkit-transform: rotate(30deg) translate3D(-45%, -45%, 0);
  transform: rotate(30deg) translate3D(-45%, -45%, 0);
  max-height: 560px
}

.header-v6 .shape-right {
  background-image: linear-gradient(-57deg, #5634d0 0, #4a61d1 100%);
  left: 93%
}

.header-v6 .main-shape-wrapper {
  width: 60%
}

@media (max-width: 991.98px) {
  .header-v6 .main-shape-wrapper {
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
  }
}

@media (min-width: 992px) {
  .header-v6 .main-shape-wrapper {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    width: 40%
  }
}

@media (min-width: 1200px) {
  .header-v6 .main-shape-wrapper {
    -webkit-transform: translateY(-50%) scale(.75);
    transform: translateY(-50%) scale(.75)
  }
}

@media (min-width: 1601px) {
  .header-v6 .main-background {
    -webkit-transform: translate3d(0, -3%, 0);
    transform: translate3d(0, -3%, 0)
  }
}

.header-v6 .anim {
  position: absolute;
  width: 15%;
  max-width: 80px
}

.header-v6 .anim.anim-1 {
  top: 56%;
  right: 5%;
  -webkit-animation-duration: 3.4s;
  animation-duration: 3.4s
}

.header-v6 .anim.anim-2 {
  top: 10%;
  right: 58%
}

.header-v6 .anim.anim-3 {
  top: 33%;
  right: 22%;
  -webkit-animation-duration: 3.8s;
  animation-duration: 3.8s
}

.header-v8 {
  background-color: #f9fbfd
}

.header-v8 .container {
  padding-top: 12rem;
  padding-bottom: 6rem
}

@media (min-width: 992px) {
  .header-v8 .container {
    padding-bottom: 24rem
  }
}

.header-v8 .shape-background {
  z-index: 0;
  opacity: .2
}

.header-v8 .shape-main {
  bottom: 0;
  opacity: 1;
  width: 170%;
  height: 150%;
  background-color: #9f55ff;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: translate(20%, 0) rotate(-30deg);
  transform: translate(20%, 0) rotate(-30deg);
  position: absolute
}

.header-v8 .shape-main.shadow {
  background-color: rgba(100, 111, 121, .15);
  -webkit-transform: translate(19%, 10px) rotate(-30deg);
  transform: translate(19%, 10px) rotate(-30deg)
}

@media (min-width: 768px) {
  .header-v8 .shape-main {
    width: 140%
  }
}

@media (min-width: 992px) {
  .header-v8 .shape-main {
    bottom: -40px;
    width: 120%
  }
}

@media (min-width: 1200px) {
  .header-v8 .shape-main {
    width: 110%
  }
}

.header-v8 .rounded-stripe {
  width: 100%;
  background-color: #8abcfe;
  height: 20%;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  opacity: 1;
  -webkit-transform: translate(20%, 0) rotate(-30deg);
  transform: translate(20%, 0) rotate(-30deg);
  bottom: -40px;
  position: absolute
}

.header-v8 .shape-top {
  top: 0;
  background-image: linear-gradient(-57deg, #5634d0 0, #4a61d1 100%);
  -webkit-transform: rotate(30deg) translate3D(-45%, -45%, 0);
  transform: rotate(30deg) translate3D(-45%, -45%, 0);
  max-height: 560px;
  -webkit-box-shadow: 0 0 25px 50px rgba(74, 97, 209, .5);
  box-shadow: 0 0 25px 50px rgba(74, 97, 209, .5)
}

.header-v8 .shape-right {
  background-image: linear-gradient(-57deg, #5634d0 0, #4a61d1 100%);
  left: 93%
}

.header-v8 .main-shape-wrapper {
  width: 90%
}

@media (max-width: 991.98px) {
  .header-v8 .main-shape-wrapper {
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
  }
}

@media (min-width: 992px) {
  .header-v8 .main-shape-wrapper {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    width: 50%
  }
}

@media (min-width: 1200px) {
  .header-v8 .main-shape-wrapper {
    -webkit-transform: translateY(-50%) scale(.75);
    transform: translateY(-50%) scale(.75)
  }
}

@media (min-width: 1601px) {
  .header-v8 .main-background {
    -webkit-transform: translate3d(0, -3%, 0);
    transform: translate3d(0, -3%, 0)
  }
}

.header-v8 .bubbles-wrapper .bubble:nth-child(1) {
  -webkit-animation: floating 3.33333s ease-in-out infinite alternate;
  animation: floating 3.33333s ease-in-out infinite alternate;
  bottom: 45%;
  left: 2%
}

@media (min-width: 992px) {
  .header-v8 .bubbles-wrapper .bubble:nth-child(1) {
    -webkit-animation: bubble-left-v 10s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 10s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%
  }
}

.header-v8 .bubbles-wrapper .bubble:nth-child(2) {
  -webkit-animation: floating 2.23333s ease-in-out infinite alternate;
  animation: floating 2.23333s ease-in-out infinite alternate;
  bottom: 40%;
  left: 12%
}

@media (min-width: 992px) {
  .header-v8 .bubbles-wrapper .bubble:nth-child(2) {
    -webkit-animation: bubble-left-v 6.7s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 6.7s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%
  }
}

.header-v8 .bubbles-wrapper .bubble:nth-child(3) {
  -webkit-animation: floating 2.93333s ease-in-out infinite alternate;
  animation: floating 2.93333s ease-in-out infinite alternate;
  bottom: 56%;
  left: 14%
}

@media (min-width: 992px) {
  .header-v8 .bubbles-wrapper .bubble:nth-child(3) {
    -webkit-animation: bubble-left-v 8.8s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 8.8s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%
  }
}

.header-v8 .bubbles-wrapper .bubble:nth-child(4) {
  -webkit-animation: floating 2.26667s ease-in-out infinite alternate;
  animation: floating 2.26667s ease-in-out infinite alternate;
  bottom: 51%;
  left: 20%
}

@media (min-width: 992px) {
  .header-v8 .bubbles-wrapper .bubble:nth-child(4) {
    -webkit-animation: bubble-left-v 6.8s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 6.8s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%
  }
}

.header-v8 .bubbles-wrapper .bubble:nth-child(5) {
  -webkit-animation: floating 3.16667s ease-in-out infinite alternate;
  animation: floating 3.16667s ease-in-out infinite alternate;
  bottom: 65%;
  left: 30%
}

@media (min-width: 992px) {
  .header-v8 .bubbles-wrapper .bubble:nth-child(5) {
    -webkit-animation: bubble-left-v 9.5s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 9.5s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%
  }
}

.header-v8 .bubbles-wrapper .bubble:nth-child(6) {
  -webkit-animation: floating 2.9s ease-in-out infinite alternate;
  animation: floating 2.9s ease-in-out infinite alternate;
  bottom: 44%;
  left: 30%
}

@media (min-width: 992px) {
  .header-v8 .bubbles-wrapper .bubble:nth-child(6) {
    -webkit-animation: bubble-left-v 8.7s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 8.7s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%
  }
}

.header-v8 .bubbles-wrapper .bubble:nth-child(7) {
  -webkit-animation: floating 2.5s ease-in-out infinite alternate;
  animation: floating 2.5s ease-in-out infinite alternate;
  bottom: 80%;
  left: 40%
}

@media (min-width: 992px) {
  .header-v8 .bubbles-wrapper .bubble:nth-child(7) {
    -webkit-animation: bubble-left-v 7.5s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 7.5s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%
  }
}

.header-v8 .bubbles-wrapper .bubble:nth-child(8) {
  -webkit-animation: floating 3.33333s ease-in-out infinite alternate;
  animation: floating 3.33333s ease-in-out infinite alternate;
  bottom: 55%;
  left: 38%
}

@media (min-width: 992px) {
  .header-v8 .bubbles-wrapper .bubble:nth-child(8) {
    -webkit-animation: bubble-left-v 10s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 10s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%
  }
}

.header-v8 .bubbles-wrapper .bubble:nth-child(9) {
  -webkit-animation: floating 2.13333s ease-in-out infinite alternate;
  animation: floating 2.13333s ease-in-out infinite alternate;
  bottom: 50%;
  left: 1%
}

@media (min-width: 992px) {
  .header-v8 .bubbles-wrapper .bubble:nth-child(9) {
    -webkit-animation: bubble-left-v 6.4s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 6.4s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%
  }
}

.header-v8 .bubbles-wrapper .bubble:nth-child(10) {
  -webkit-animation: floating 2.73333s ease-in-out infinite alternate;
  animation: floating 2.73333s ease-in-out infinite alternate;
  bottom: 55%;
  left: 12%
}

@media (min-width: 992px) {
  .header-v8 .bubbles-wrapper .bubble:nth-child(10) {
    -webkit-animation: bubble-left-v 8.2s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 8.2s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%
  }
}

.header-v8 .bubbles-wrapper .bubble:nth-child(11) {
  -webkit-animation: floating 2.13333s ease-in-out infinite alternate;
  animation: floating 2.13333s ease-in-out infinite alternate;
  bottom: 60%;
  left: 14%
}

@media (min-width: 992px) {
  .header-v8 .bubbles-wrapper .bubble:nth-child(11) {
    -webkit-animation: bubble-left-v 6.4s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 6.4s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%
  }
}

.header-v8 .bubbles-wrapper .bubble:nth-child(12) {
  -webkit-animation: floating 2.6s ease-in-out infinite alternate;
  animation: floating 2.6s ease-in-out infinite alternate;
  bottom: 60%;
  left: 18%
}

@media (min-width: 992px) {
  .header-v8 .bubbles-wrapper .bubble:nth-child(12) {
    -webkit-animation: bubble-left-v 7.8s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 7.8s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%
  }
}

.header-v8 .bubbles-wrapper .bubble:nth-child(13) {
  -webkit-animation: floating 3.06667s ease-in-out infinite alternate;
  animation: floating 3.06667s ease-in-out infinite alternate;
  bottom: 70%;
  left: 20%
}

@media (min-width: 992px) {
  .header-v8 .bubbles-wrapper .bubble:nth-child(13) {
    -webkit-animation: bubble-left-v 9.2s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 9.2s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%
  }
}

.header-v8 .bubbles-wrapper .bubble:nth-child(14) {
  -webkit-animation: floating 4.06667s ease-in-out infinite alternate;
  animation: floating 4.06667s ease-in-out infinite alternate;
  bottom: 75%;
  left: 45%
}

@media (min-width: 992px) {
  .header-v8 .bubbles-wrapper .bubble:nth-child(14) {
    -webkit-animation: bubble-left-v 12.2s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 12.2s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%
  }
}

.header-v8 .bubbles-wrapper .bubble:nth-child(15) {
  -webkit-animation: floating 3.26667s ease-in-out infinite alternate;
  animation: floating 3.26667s ease-in-out infinite alternate;
  bottom: 50%;
  left: 50%
}

@media (min-width: 992px) {
  .header-v8 .bubbles-wrapper .bubble:nth-child(15) {
    -webkit-animation: bubble-left-v 9.8s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 9.8s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%
  }
}

.header-v8 .bubbles-wrapper .bubble:nth-child(16) {
  -webkit-animation: floating 3.53333s ease-in-out infinite alternate;
  animation: floating 3.53333s ease-in-out infinite alternate;
  bottom: 68%;
  left: 34%
}

@media (min-width: 992px) {
  .header-v8 .bubbles-wrapper .bubble:nth-child(16) {
    -webkit-animation: bubble-left-v 10.6s linear infinite, shake-v 2s ease-in-out infinite alternate;
    animation: bubble-left-v 10.6s linear infinite, shake-v 2s ease-in-out infinite alternate;
    bottom: 50%
  }
}

@media (min-width: 768px) {
  .header-v8 .form {
    width: 90%
  }
}

.header-v8 .input-group-register .form-control {
  padding: 1.5rem 0 1.5rem 1.5rem
}

.header-v8 .input-group-register .btn {
  right: 5px
}

.header-v8 .input-group-register label.error {
  color: #fff;
  background-color: #dc3545;
  border-radius: 15px;
  padding: 0 15px
}

@media (min-width: 576px) {
  .header-v8 + .features-carousel .container > .cards-wrapper {
    width: 510px
  }
}

@media (min-width: 768px) {
  .header-v8 + .features-carousel .container > .cards-wrapper {
    width: 690px
  }
}

@media (min-width: 992px) {
  .header-v8 + .features-carousel .container > .cards-wrapper {
    width: 930px
  }
}

@media (min-width: 1200px) {
  .header-v8 + .features-carousel .container > .cards-wrapper {
    width: 1110px
  }
}

@media (min-width: 992px) {
  .header-v8 + .features-carousel .container {
    padding-bottom: 16rem
  }
  .header-v8 + .features-carousel .container > .cards-wrapper {
    position: absolute;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
  }
}

.header-v8 + .features-carousel .logo {
  min-height: 48px
}

@media (min-width: 768px) {
  .app-landing-header .container {
    padding-bottom: 12rem
  }
}

.app-landing-header .shape-background {
  z-index: 0
}

.app-landing-header .shape-main {
  bottom: 0;
  width: 100%;
  height: 200%;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: translate(65%, -20%) rotate(-60deg);
  transform: translate(65%, -20%) rotate(-60deg);
  position: absolute
}

@media (min-width: 768px) {
  .app-landing-header .shape-main {
    -webkit-transform: translate(50%, 0) rotate(-60deg);
    transform: translate(50%, 0) rotate(-60deg)
  }
}

.app-landing-header .shape-top {
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: translate(80%, -60%) rotate(-60deg);
  transform: translate(80%, -60%) rotate(-60deg);
  left: 50%
}

@media (min-width: 1200px) {
  .app-landing-header .iphone-x {
    max-width: 318.75px;
    border-radius: 34px;
    padding: 12.75px
  }
  .app-landing-header .iphone-x .screen img {
    border-radius: 17px
  }
  .app-landing-header .iphone-x .notch {
    border-radius: 17px;
    width: 177.65px;
    height: 29.75px
  }
}

.app-landing-header .screen-highlight {
  right: 0;
  bottom: 21.75%
}

p {
  margin: 1rem 0
}

.logo {
  max-width: 140px
}

.swiper-container .swiper-pagination-top {
  bottom: auto;
  top: 0
}

hr[class*=bg] {
  border-top: 0;
  height: 1px
}

.off-left-background {
  background-position: -60px 20px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all .4s ease;
  transition: all .4s ease
}

.off-left-background:hover {
  background-position: -60px 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0)
}

.nav-process {
  min-height: 60px
}
